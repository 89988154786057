import { Card, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import techniqueChecker from '../utils/techniqueChecker';

const RecommendedMasseuse = ({ masseuse }) => {
  return (
    <Card
      variant="outlined"
      style={{
        border: '1px solid #c2872b66',
        borderRadius: '15px',
        padding: '20px',
        marginBottom: '0px',
      }}
      // className="profile-card-shadow"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          order={{ xs: 2, sm: 1 }}
          sx={{ textAlign: 'center' }}
        >
          <Link to={`/masseuse/${masseuse?.id}`}>
            <Typography
              variant="h3"
              component="div"
              className="masseuse-name"
              sx={{
                fontWeight: '800',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {masseuse?.name.toUpperCase()}
            </Typography>
          </Link>
          {techniqueChecker(masseuse.technique) ? (
            <Typography
              sx={{ typography: { xs: 'h5' }, color: 'text.primary' }}
              gutterBottom
            >
              {masseuse?.age} - {masseuse?.background}
            </Typography>
          ) : (
            <Typography
              sx={{ typography: { xs: 'h5' }, color: 'text.primary' }}
              gutterBottom
            >
              {masseuse?.background}
            </Typography>
          )}
          <Typography variant="body2" mt={2}>
            These masseuses are selected based on top customer reviews and
            ratings
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          order={{ xs: 1, sm: 2 }}
        >
          <Link to={`/masseuse/${masseuse?.id}`}>
            <CardMedia
              component="img"
              alt={masseuse?.name}
              image={
                !masseuse?.image
                  ? 'https://clubdynasty.ca/wp-content/uploads/2024/07/Female_Silouhette-square.jpg'
                  : masseuse?.image
              }
              sx={{
                borderRadius: '15px',
                width: {
                  xs: '100%',
                  sm: '300px',
                },
              }}
            />
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RecommendedMasseuse;
