import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { API_URI } from '../constants/appConstants';

function useSessions(token, noteChanged = 0) {
  const fetchSessions = async ({ pageParam = 1 }) => {
    const endpoint = `${API_URI}/members/sessions/?page=${pageParam}&page_size=10&order=desc`;
    const notesEndpoint = `${API_URI}/notes/?page=1&page_size=1000`;

    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });

      const notesResponse = await axios.get(notesEndpoint, { headers });

      const tempProcessedSessions = response.data.results.map((session) => {
        // Filter notes for the current session
        const sessionNotes = notesResponse.data.results.filter(
          (note) => note.session === session.id
        );

        // Two masseuse in a massage test
        // if (session.masseuses.length === 1 && session.masseuses[0] === 983) {
        //   session.masseuses.push(381);
        // }

        return {
          ...session,
          notes: sessionNotes,
        };
      });

      response.data.results = tempProcessedSessions;

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch session details');
    }
  };

  return useInfiniteQuery(['sessions', noteChanged], fetchSessions, {
    // Function to get the next page number
    getNextPageParam: (lastPage) => lastPage.next ?? false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });
}

export default useSessions;
