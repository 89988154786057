import {
  NOTIFICATION_LIST_FAIL,
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_RESET,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_READ_REQUEST,
  NOTIFICATION_READ_SUCCESS,
  NOTIFICATION_READ_FAIL,
  NOTIFICATION_READ_RESET,
  NOTIFICATION_LIST_MAKE_READ_REQUEST,
} from '../constants/notificationConstants';

export const notificationListReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_LIST_REQUEST:
      return { loading: true };
    case NOTIFICATION_LIST_SUCCESS:
      return { loading: false, notifications: action.payload };
    case NOTIFICATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case NOTIFICATION_LIST_RESET:
      return {};
    case NOTIFICATION_LIST_MAKE_READ_REQUEST:
      let results = state.notifications.results.map((notification) => {
        if (notification.id === action.payload) {
          notification.read = true;
        }
        return notification;
      });
      state.notifications = {
        ...state.notifications,
        results,
        unreadCount: state.notifications.unreadCount - 1,
      };
      return {
        ...state,
        notifications: state.notifications,
      };

    default:
      return state;
  }
};

export const notificationReadReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_READ_REQUEST:
      return { loading: true, id: action.payload };
    case NOTIFICATION_READ_SUCCESS:
      return { loading: false, notifications: action.payload };
    case NOTIFICATION_READ_FAIL:
      return { loading: false, error: action.payload };
    case NOTIFICATION_READ_RESET:
      return {};
    default:
      return state;
  }
};
