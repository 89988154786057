import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../actions/userActions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setAlert } from '../actions/alertActions';
import Message from '../components/Message';
import getErrorsForProperty from '../utils/getErrorsForProperty';

const ProfilePasswordEditScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [fresh_login, setFresh_login] = useState(null);

  const userPasswordChange = useSelector((state) => state.userPasswordChange);
  const { loading, success, error } = userPasswordChange;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    if (searchParams.get('fresh_login') === 'true') {
      setFresh_login(true);
    } else setFresh_login(false);
    if (success) {
      setTimeout(() => {
        navigate('/profile');
      }, 5000);
    }
  }, [success]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (values.password !== values.confirmPassword) {
      dispatch(setAlert('Passwords do not match', 'warning'));
    } else {
      dispatch(changePassword(values.password, values.confirmPassword));
    }
    // if (values.password.length === 0 || values.confirmPassword.length === 0) {
    //   dispatch(setAlert('Passwords cannot be empty', 'warning'));
    // } else {
    //   dispatch(changePassword(values.password, values.confirmPassword));
    // }
  };
  return (
    <Container sx={{ minHeight: '60vh' }}>
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2} mt={8}>
          <Grid item xs={12}>
            <Typography variant="h2">Update Password</Typography>
            {fresh_login && (
              <div
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  background: '#E1E1E1',
                  margin: '15px 0px 10px 0px',
                }}
              >
                <Typography variant="p">
                  New member onboarding <strong>Step 2 of 2</strong>
                </Typography>{' '}
                <br />
                <Typography variant="p">
                  Update your account's password if you wish, click exit if not.
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Type your desired password"
              error={
                error &&
                (error.hasOwnProperty('new_password1') ||
                  error.hasOwnProperty('new_password2'))
              }
            />{' '}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              type={values.showConfirmPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              onChange={handleChange('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label="Retype your desired password"
              error={error && error.hasOwnProperty('new_password2')}
              helperText={getErrorsForProperty('new_password2', error)}
            />
          </Grid>
          <Grid item xs={12}>
            <Link to={fresh_login ? '/' : '/profile'}>
              <Button
                variant="outlined"
                disableElevation
                style={{ marginRight: '5px' }}
              >
                {fresh_login ? 'Exit' : 'Back to profile'}
              </Button>{' '}
            </Link>
            <Button variant="contained" disableElevation type="submit">
              Update{' '}
              {loading && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default ProfilePasswordEditScreen;
