import {
  FEED_BLOCK_CREATE_FAIL,
  FEED_BLOCK_CREATE_REQUEST,
  FEED_BLOCK_CREATE_RESET,
  FEED_BLOCK_CREATE_SUCCESS,
  FEED_BLOCK_DELETE_FAIL,
  FEED_BLOCK_DELETE_REQUEST,
  FEED_BLOCK_DELETE_RESET,
  FEED_BLOCK_DELETE_SUCCESS,
  FEED_BLOCK_LIST_FAIL,
  FEED_BLOCK_LIST_REMOVE_ITEM,
  FEED_BLOCK_LIST_REQUEST,
  FEED_BLOCK_LIST_SUCCESS,
  FEED_FAVORITE_CREATE_FAIL,
  FEED_FAVORITE_CREATE_REQUEST,
  FEED_FAVORITE_CREATE_RESET,
  FEED_FAVORITE_CREATE_SUCCESS,
  FEED_FAVORITE_DELETE_FAIL,
  FEED_FAVORITE_DELETE_REQUEST,
  FEED_FAVORITE_DELETE_RESET,
  FEED_FAVORITE_DELETE_SUCCESS,
  FEED_FAVORITE_DETAILS_FAIL,
  FEED_FAVORITE_DETAILS_REQUEST,
  FEED_FAVORITE_DETAILS_SUCCESS,
  FEED_FAVORITE_LIST_FAIL,
  FEED_FAVORITE_LIST_REMOVE_ITEM,
  FEED_FAVORITE_LIST_REQUEST,
  FEED_FAVORITE_LIST_SUCCESS,
} from '../constants/feedConstants';

export const feedBlockListReducer = (state = {}, action) => {
  switch (action.type) {
    case FEED_BLOCK_LIST_REQUEST:
      return { loading: true };
    case FEED_BLOCK_LIST_SUCCESS:
      return { loading: false, feedBlocks: action.payload };
    case FEED_BLOCK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case FEED_BLOCK_LIST_REMOVE_ITEM:
      return {
        loading: false,
        feedBlocks: {
          first: state.feedBlocks.first,
          total: state.feedBlocks.total,
          last: state.feedBlocks.last,
          current: state.feedBlocks.current,
          results: state.feedBlocks.results.filter(
            (block) => block.id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
};

export const feedBlockCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FEED_BLOCK_CREATE_REQUEST:
      return { loading: true };
    case FEED_BLOCK_CREATE_SUCCESS:
      return { loading: false, feed: action.payload, success: true };
    case FEED_BLOCK_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case FEED_BLOCK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const feedBlockDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FEED_BLOCK_DELETE_REQUEST:
      return { loading: true };
    case FEED_BLOCK_DELETE_SUCCESS:
      return { loading: false, feedback: action.payload };
    case FEED_BLOCK_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case FEED_BLOCK_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const feedFavoriteListReducer = (state = {}, action) => {
  switch (action.type) {
    case FEED_FAVORITE_LIST_REQUEST:
      return { loading: true };
    case FEED_FAVORITE_LIST_SUCCESS:
      return { loading: false, feedFavorites: action.payload };
    case FEED_FAVORITE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case FEED_FAVORITE_LIST_REMOVE_ITEM:
      return {
        loading: false,
        feedFavorites: {
          first: state.feedFavorites.first,
          total: state.feedFavorites.total,
          last: state.feedFavorites.last,
          current: state.feedFavorites.current,
          results: state.feedFavorites.results.filter(
            (favorite) => favorite.id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
};

export const feedFavoriteDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FEED_FAVORITE_DETAILS_REQUEST:
      return { loading: true };
    case FEED_FAVORITE_DETAILS_SUCCESS:
      return { loading: false, feedFavorites: action.payload };
    case FEED_FAVORITE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    // case FEED_FAVORITE_DETAILS_REMOVE_ITEM:
    //   return {
    //     loading: false,
    //     feedFavorites: {
    //       first: state.feedFavorites.first,
    //       total: state.feedFavorites.total,
    //       last: state.feedFavorites.last,
    //       current: state.feedFavorites.current,
    //       results: state.feedFavorites.results.filter(
    //         (favorite) => favorite.id !== action.payload
    //       ),
    //     },
    //   };
    default:
      return state;
  }
};

export const feedFavoriteCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FEED_FAVORITE_CREATE_REQUEST:
      return { loading: true };
    case FEED_FAVORITE_CREATE_SUCCESS:
      return {
        loading: false,
        favoriteCreate: action.payload,
        success: true,
      };
    case FEED_FAVORITE_CREATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case FEED_FAVORITE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const feedFavoriteDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FEED_FAVORITE_DELETE_REQUEST:
      return { loading: true };
    case FEED_FAVORITE_DELETE_SUCCESS:
      return { loading: false, favoriteDelete: action.payload };
    case FEED_FAVORITE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case FEED_FAVORITE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
