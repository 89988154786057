const getErrorsForProperty = (propertyName, errors) => {
  if (errors && errors.hasOwnProperty(propertyName)) {
    const propertyErrors = errors[propertyName];

    if (propertyErrors instanceof Array) {
      return propertyErrors.join(', ');
    }
  }

  return '';
};

export default getErrorsForProperty;
