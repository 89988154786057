import { Card, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

const Packages = ({ packages }) => {
  return (
    <Card
      variant="outlined"
      style={{
        border: '1px solid #c2872b66',
        borderRadius: '15px',
        padding: '20px',
        marginTop: '20px',
      }}
      className="profile-card-shadow"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#C2872B',
              fontWeight: '500',
              fontFamily: 'Poppins',
            }}
          >
            Packages
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {packages.length === 0 ? (
            <Typography
              variant="h6"
              sx={{
                fontWeight: '500',
                fontFamily: 'Poppins',
              }}
            >
              Not Available
            </Typography>
          ) : (
            packages.map((pack, index) => (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: '500',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {pack.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: '500',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {`${pack.used === undefined ? 0 : pack.used} / ${
                      pack.total
                    }`}
                  </Typography>
                </div>
                {packages.length - 1 !== index && <Divider />}
              </div>
            ))
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Packages;
