import {
  POPUP_DETAILS_FAIL,
  POPUP_DETAILS_REQUEST,
  POPUP_DETAILS_SUCCESS,
} from '../constants/popupConstants';

export const popupDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case POPUP_DETAILS_REQUEST:
      return { loading: true };
    case POPUP_DETAILS_SUCCESS:
      return { loading: false, popup: action.payload };
    case POPUP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
