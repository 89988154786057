export const massageRewardPoints = [
  {
    massage_name: 'Foot Massage 15 Minutes',
    points_earned: 20,
    points_cost: 400,
  },
  {
    massage_name: 'Foot Massage 30 Minutes',
    points_earned: 40,
    points_cost: 800,
  },
  {
    massage_name: '45 Minutes Massage',
    points_earned: 100,
    points_cost: 2000,
  },
  {
    massage_name: '⭐ V.I.P. Massage (2 Girls)',
    points_earned: 200,
    points_cost: 4000,
  },
  {
    massage_name: '90 Minutes Shiatsu Massage',
    points_earned: 200,
    points_cost: 4000,
  },
  {
    massage_name: '4-Hand 45 Minutes (2 Girls) Shiatsu Massage',
    points_earned: 200,
    points_cost: 4000,
  },
  {
    massage_name: '90 Minutes Sensual Massage',
    points_earned: 200,
    points_cost: 4000,
  },
  {
    massage_name: '4-Hand 45 Minutes (2 Girls) Sensual Massage',
    points_earned: 200,
    points_cost: 4000,
  },
  {
    massage_name: 'Executive Massage (2 Girls)',
    points_earned: 200,
    points_cost: 4000,
  },
  {
    massage_name: 'Harem Massage 30 Minutes',
    massage_details:
      '3 reward points gets you one session of 30 Minutes Harem Massage performed by 3 Girls',
    masseuse: 3,
    points_earned: 300,
    points_cost: 6000,
  },
  {
    massage_name: '135 Minutes Shiatsu Massage',
    points_earned: 300,
    points_cost: 6000,
  },
  {
    massage_name: 'Dynasty Massage',
    points_earned: 300,
    points_cost: 6000,
  },
  {
    massage_name: '4-Hand Shiatsu Massage 90 Minutes',
    points_earned: 400,
    points_cost: 8000,
  },
  {
    massage_name: '180 Minutes Shiatsu Massage',
    points_earned: 400,
    points_cost: 8000,
  },
  {
    massage_name: '4-Hand Sensual Massage 90 Minutes',
    points_earned: 500,
    points_cost: 10000,
  },
  {
    massage_name: 'Harem Massage 60 Minutes',
    points_earned: 500,
    points_cost: 10000,
  },
  {
    massage_name: '4-Hand Shiatsu Massage 135 Minutes',
    points_earned: 600,
    points_cost: 12000,
  },
];
