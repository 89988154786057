import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import MuiPhoneNumber from 'material-ui-phone-number';

// import LoginIllustration from '../img/Login_Artwork_1.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { login } from '../actions/userActions';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';

import VIP_BG from '../img/VIP-BG.jpg';
import CD_LOGO from '../img/cd-logo.png';
import {
  USER_PASSWORD_RESET_CONFIRM_RESET,
  USER_PASSWORD_RESET_RESET,
  USER_REGISTER_RESET,
} from '../constants/userConstants';
import { REMOVE_ALERT } from '../constants/alertConstants';
import getErrorsForProperty from '../utils/getErrorsForProperty';

const LoginScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [redirect, setRedirect] = useState('/');
  const [loginMedium, setLoginMedium] = useState('email');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, userInfo, error } = userLogin;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    dispatch({
      type: USER_PASSWORD_RESET_RESET,
    });
    dispatch({
      type: USER_PASSWORD_RESET_CONFIRM_RESET,
    });
    dispatch({
      type: USER_REGISTER_RESET,
    });
    dispatch({
      type: REMOVE_ALERT,
    });

    const queryParams = new URLSearchParams(window.location.search);
    const redirectParam = queryParams.get('redirect');
    if (redirectParam) {
      setRedirect(redirectParam);
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.user.fresh_login) {
      navigate('/profile/update?fresh_login=' + userInfo.user.fresh_login);
    } else if (userInfo && !userInfo.user.fresh_login) navigate(redirect);
  }, [userInfo]);

  const handleLoginMediumChange = (event) => {
    setLoginMedium(event.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, phone, password, loginMedium));
  };

  return (
    <div
      style={{
        background: `url(${VIP_BG}) center center no-repeat`,
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Container
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={4} className="grid-center">
            <Card
              sx={{ minWidth: 275, background: 'rgba(255,255,255,0.9)' }}
              variant="outlined"
            >
              <CardContent style={{ padding: '30px' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={CD_LOGO} alt="Club Dynasty Spa" width="250px" />
                </div>

                <Typography variant="h2" gutterBottom textAlign={'center'}>
                  Login to VIP Members Area
                </Typography>
                <form onSubmit={(e) => onSubmit(e)}>
                  <FormControl style={{ width: '100%', marginBottom: '20px' }}>
                    <FormLabel id="login-medium-label">Login using:</FormLabel>
                    <RadioGroup
                      aria-labelledby="login-medium-label"
                      name="radio-buttons-group"
                      row
                      value={loginMedium}
                      onChange={handleLoginMediumChange}
                    >
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email"
                      />
                      <FormControlLabel
                        value="phone"
                        control={<Radio />}
                        label="Phone"
                      />
                    </RadioGroup>
                  </FormControl>
                  {loginMedium === 'email' ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      label="Email"
                      style={{ width: '100%', marginBottom: '20px' }}
                      error={error && error.hasOwnProperty('email')}
                      helperText={getErrorsForProperty('email', error)}
                    />
                  ) : (
                    <MuiPhoneNumber
                      variant="outlined"
                      size="small"
                      label="Phone"
                      style={{ width: '100%', marginBottom: '20px' }}
                      value={phone}
                      onChange={setPhone}
                      disableCountryCode={true}
                      disableAreaCodes={true}
                      disableDropdown={true}
                      defaultCountry="ca"
                      onlyCountries={['ca']}
                      error={error && error.hasOwnProperty('phone')}
                      helperText={getErrorsForProperty('phone', error)}
                    />
                  )}

                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Password"
                    error={error && error.hasOwnProperty('password')}
                    helperText={getErrorsForProperty('password', error)}
                  />

                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type="submit"
                  >
                    Login{' '}
                    {loading && (
                      <>
                        &nbsp;
                        <CircularProgress
                          color="white"
                          size={20}
                          thickness={5}
                        />
                      </>
                    )}
                  </Button>
                </form>
                <Typography variant="p">
                  Don't have an account?{' '}
                  <Link to="/register" style={{ textDecoration: 'underline' }}>
                    Register
                  </Link>{' '}
                  | Forgot password?{' '}
                  <Link to="/reset" style={{ textDecoration: 'underline' }}>
                    Reset
                  </Link>{' '}
                  {/* <br />
                  <Link
                    to="/admin-login"
                    style={{ textDecoration: 'underline' }}
                  >
                    Admin Login
                  </Link> */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {msg?.length > 0 && <Message />}
      </Container>
    </div>
  );
};

export default LoginScreen;
