export const NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST_REQUEST';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_FAIL = 'NOTIFICATION_LIST_FAIL';
export const NOTIFICATION_LIST_RESET = 'NOTIFICATION_LIST_RESET';
export const NOTIFICATION_LIST_MAKE_READ_REQUEST =
  'NOTIFICATION_LIST_MAKE_READ_REQUEST';

export const NOTIFICATION_READ_REQUEST = 'NOTIFICATION_READ_REQUEST';
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS';
export const NOTIFICATION_READ_FAIL = 'NOTIFICATION_READ_FAIL';
export const NOTIFICATION_READ_RESET = 'NOTIFICATION_READ_RESET';
