import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserDetails,
  getUserPreferences,
  updateUserPreferences,
  updateUserProfilePreferences,
} from '../actions/userActions';
import {
  LOCKER_CHOICES,
  ROBE_CHOICES,
  SHOE_CHOICES,
  SHORTS_CHOICES,
} from '../constants/appConstants';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Message from '../components/Message';

const PreferencesScreen = () => {
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userPreference = useSelector((state) => state.userPreference);
  const { preference, loading: loadingPreference } = userPreference;

  const [locker, setLocker] = useState('');
  const [shoe_size, setShoe_size] = useState('');
  const [robe_size, setRobe_size] = useState('');
  const [shorts_size, setShorts_size] = useState('');

  const [promotions_alert_sms, set_promotions_alert_sms] = useState(false);
  const [availability_alert_sms, set_availability_alert_sms] = useState(false);
  const [news_alert_sms, set_news_alert_sms] = useState(false);
  const [reminders_alert_sms, set_reminders_alert_sms] = useState(false);
  const [promotions_alert_email, set_promotions_alert_email] = useState(false);
  const [availability_alert_email, set_availability_alert_email] =
    useState(false);
  const [news_alert_email, set_news_alert_email] = useState(false);
  const [reminders_alert_email, set_reminders_alert_email] = useState(false);

  const [smsAlertAccordionOpen, setSmsAlertAccordionOpen] = useState(false);
  const [emailAlertAccordionOpen, setEmailAlertAccordionOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      dispatch(getUserDetails());
    } else {
      setLocker(user.locker ?? '');
      setShoe_size(user.shoe_size ?? '');
      setRobe_size(user.robe_size ?? '');
      setShorts_size(user.shorts_size ?? '');
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (!preference) {
      dispatch(getUserPreferences());
    } else {
      set_promotions_alert_sms(preference.promotions_alert_sms ?? false);
      set_availability_alert_sms(preference.availability_alert_sms ?? false);
      set_news_alert_sms(preference.news_alert_sms ?? false);
      set_reminders_alert_sms(preference.reminders_alert_sms ?? false);
      set_promotions_alert_email(preference.promotions_alert_email ?? false);
      set_availability_alert_email(
        preference.availability_alert_email ?? false
      );
      set_news_alert_email(preference.news_alert_email ?? false);
      set_reminders_alert_email(preference.reminders_alert_email ?? false);
    }
  }, [preference]);

  const onSpaSubmit = () => {
    dispatch(
      updateUserProfilePreferences({
        name: user.name,
        locker: locker,
        shoe_size: shoe_size,
        shorts_size: shorts_size,
        robe_size: robe_size,
      })
    );
  };

  const updateAlertPreference = (preferenceType, e) => {
    // Determine if the preference is for SMS or Email
    const isSms = preferenceType.endsWith('sms');

    // Based on the preferenceType, update the corresponding state
    if (preferenceType === 'promotions_alert_sms')
      set_promotions_alert_sms(e.target.checked);
    else if (preferenceType === 'availability_alert_sms')
      set_availability_alert_sms(e.target.checked);
    else if (preferenceType === 'news_alert_sms')
      set_news_alert_sms(e.target.checked);
    else if (preferenceType === 'reminders_alert_sms')
      set_reminders_alert_sms(e.target.checked);
    else if (preferenceType === 'promotions_alert_email')
      set_promotions_alert_email(e.target.checked);
    else if (preferenceType === 'availability_alert_email')
      set_availability_alert_email(e.target.checked);
    else if (preferenceType === 'news_alert_email')
      set_news_alert_email(e.target.checked);
    else if (preferenceType === 'reminders_alert_email')
      set_reminders_alert_email(e.target.checked);

    // Dispatch the updateUserPreferences action with the updated preference
    dispatch(
      updateUserPreferences({
        promotions_alert_sms:
          isSms && preferenceType === 'promotions_alert_sms'
            ? e.target.checked
            : promotions_alert_sms,
        availability_alert_sms:
          isSms && preferenceType === 'availability_alert_sms'
            ? e.target.checked
            : availability_alert_sms,
        news_alert_sms:
          isSms && preferenceType === 'news_alert_sms'
            ? e.target.checked
            : news_alert_sms,
        reminders_alert_sms:
          isSms && preferenceType === 'reminders_alert_sms'
            ? e.target.checked
            : reminders_alert_sms,
        promotions_alert_email:
          !isSms && preferenceType === 'promotions_alert_email'
            ? e.target.checked
            : promotions_alert_email,
        availability_alert_email:
          !isSms && preferenceType === 'availability_alert_email'
            ? e.target.checked
            : availability_alert_email,
        news_alert_email:
          !isSms && preferenceType === 'news_alert_email'
            ? e.target.checked
            : news_alert_email,
        reminders_alert_email:
          !isSms && preferenceType === 'reminders_alert_email'
            ? e.target.checked
            : reminders_alert_email,
      })
    );
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{ padding: '20px', marginTop: '2rem', marginBottom: '2rem' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Spa Preferences
            </Typography>
            <Typography variant="p" gutterBottom display={'block'} mb={2}>
              Let us know your check-in preferences so we can have your locker
              ready and waiting for you :)
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2} mb={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-locker-type">Locker</InputLabel>
              <Select
                labelId="select-locker-type"
                value={locker.toString()}
                label="Locker"
                onChange={(e) => setLocker(e.target.value)}
              >
                <MenuItem size="small" value={''}>
                  Locker Choices
                </MenuItem>
                {Object.keys(LOCKER_CHOICES).map((l) => (
                  <MenuItem size="small" value={l} key={l}>
                    {LOCKER_CHOICES[l]}
                  </MenuItem>
                ))}
              </Select>{' '}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2} mb={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-shoe-size">Shoe Size</InputLabel>
              <Select
                labelId="select-shoe-size"
                value={shoe_size.toString()}
                label="Shoe Size"
                onChange={(e) => setShoe_size(e.target.value)}
              >
                <MenuItem size="small" value={''}>
                  Shoe Choices
                </MenuItem>
                {Object.keys(SHOE_CHOICES).map((l) => (
                  <MenuItem size="small" value={l} key={l}>
                    {SHOE_CHOICES[l]}
                  </MenuItem>
                ))}
              </Select>{' '}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2} mb={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-shorts-size">Shorts Size</InputLabel>
              <Select
                labelId="select-shorts-size"
                value={shorts_size.toString()}
                label="Shorts Size"
                onChange={(e) => setShorts_size(e.target.value)}
              >
                <MenuItem size="small" value={''}>
                  Shorts Choices
                </MenuItem>
                {Object.keys(SHORTS_CHOICES).map((l) => (
                  <MenuItem size="small" value={l} key={l}>
                    {SHORTS_CHOICES[l]}
                  </MenuItem>
                ))}
              </Select>{' '}
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2} mb={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-robe-size">Robe Size</InputLabel>
              <Select
                labelId="select-robe-size"
                value={robe_size.toString()}
                label="Robe Size"
                onChange={(e) => setRobe_size(e.target.value)}
              >
                <MenuItem size="small" value={''}>
                  Robe Choices
                </MenuItem>
                {Object.keys(ROBE_CHOICES).map((l) => (
                  <MenuItem size="small" value={l} key={l}>
                    {ROBE_CHOICES[l]}
                  </MenuItem>
                ))}
              </Select>{' '}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '5px' }}>
            <Button
              onClick={(e) => onSpaSubmit()}
              variant="contained"
              disableElevation
            >
              Update
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Divider />
          </Grid>
        </Grid>
      </Card>
      <Card variant="outlined" sx={{ padding: '20px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} mt={1}>
            <Typography variant="h2" gutterBottom>
              Notification Preferences
            </Typography>
            <Accordion
              variant="outlined"
              sx={{
                marginTop: '10px',
                '&:before': {
                  display: 'none',
                },
              }}
              expanded={smsAlertAccordionOpen}
              onChange={() => setSmsAlertAccordionOpen(!smsAlertAccordionOpen)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>SMS Alerts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  label={
                    <Typography variant="body2">Spa Promotions</Typography>
                  }
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={loadingPreference ? false : promotions_alert_sms}
                      onChange={(e) =>
                        updateAlertPreference('promotions_alert_sms', e)
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="body2">
                      Masseuse Availability
                    </Typography>
                  }
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        loadingPreference ? false : availability_alert_sms
                      }
                      onChange={(e) =>
                        updateAlertPreference('availability_alert_sms', e)
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={<Typography variant="body2">Spa News</Typography>}
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={loadingPreference ? false : news_alert_sms}
                      onChange={(e) =>
                        updateAlertPreference('news_alert_sms', e)
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="body2">
                      Appointment Reminders
                    </Typography>
                  }
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={loadingPreference ? false : reminders_alert_sms}
                      onChange={(e) =>
                        updateAlertPreference('reminders_alert_sms', e)
                      }
                    />
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              variant="outlined"
              sx={{
                marginTop: '10px',
                '&:before': {
                  display: 'none',
                },
              }}
              expanded={emailAlertAccordionOpen}
              onChange={() =>
                setEmailAlertAccordionOpen(!emailAlertAccordionOpen)
              }
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Email Alerts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  label={
                    <Typography variant="body2">Spa Promotions</Typography>
                  }
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        loadingPreference ? false : promotions_alert_email
                      }
                      onChange={(e) =>
                        updateAlertPreference('promotions_alert_email', e)
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="body2">
                      Masseuse Availability
                    </Typography>
                  }
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        loadingPreference ? false : availability_alert_email
                      }
                      onChange={(e) =>
                        updateAlertPreference('availability_alert_email', e)
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={<Typography variant="body2">Spa News</Typography>}
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={loadingPreference ? false : news_alert_email}
                      onChange={(e) =>
                        updateAlertPreference('news_alert_email', e)
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="body2">
                      Appointment Reminders
                    </Typography>
                  }
                  disabled={loadingPreference}
                  control={
                    <Checkbox
                      size="small"
                      checked={
                        loadingPreference ? false : reminders_alert_email
                      }
                      onChange={(e) =>
                        updateAlertPreference('reminders_alert_email', e)
                      }
                    />
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Card>
      {msg?.length > 0 && <Message />}
    </>
  );
};

export default PreferencesScreen;
