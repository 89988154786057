// src/constants/appConstants.js

export const API_URI = 'https://mydynasty.ca/cd/api/v1';
export const CD_API_URI = 'https://mydynasty.ca/api/v1';
// export const API_URI = 'http://127.0.0.1:8000/cd/api/v1';
export const GA_TRACKING_ID = 'G-BGDLEP2KMB';

export const LOCKER_CHOICES = {
  0: 'None',
  1: '1-5',
  6: '6-19',
  20: '20-27',
  28: '28-38',
};

export const ROBE_CHOICES = {
  0: 'None',
  1: 'M',
  2: 'L',
  3: 'XL',
  4: 'XXL',
  5: 'XXXL',
};

export const SHOE_CHOICES = {
  0: 'None',
  1: 'Small (black)',
  2: 'Large (beige)',
};

export const SHORTS_CHOICES = {
  0: 'None',
  1: 'M',
  2: 'L',
  3: 'XL',
  4: 'XXL',
  5: 'XXXL',
};

// Constants for music settings
const MUSIC_LOW = 1;
const MUSIC_MEDIUM = 2;
const MUSIC_HIGH = 3;

export const MUSIC_CHOICES = [
  { value: MUSIC_LOW, label: 'Low' },
  { value: MUSIC_MEDIUM, label: 'Medium' },
  { value: MUSIC_HIGH, label: 'High' },
];

// Constants for lighting settings
const LIGHTING_OFF = 1;
const LIGHTING_LOW = 2;
const LIGHTING_MEDIUM = 3;
const LIGHTING_HIGH = 4;

export const LIGHTING_CHOICES = [
  { value: LIGHTING_OFF, label: 'Off' },
  { value: LIGHTING_LOW, label: 'Low' },
  { value: LIGHTING_MEDIUM, label: 'Medium' },
  { value: LIGHTING_HIGH, label: 'High' },
];

// Constants for pressure settings
const PRESSURE_LIGHT = 1;
const PRESSURE_MEDIUM = 2;
const PRESSURE_KILL_ME = 3;

export const PRESSURE_CHOICES = [
  { value: PRESSURE_LIGHT, label: 'Light' },
  { value: PRESSURE_MEDIUM, label: 'Medium' },
  { value: PRESSURE_KILL_ME, label: 'Kill Me' },
];

export const PORNSTAR_COACHING_AFFILIATE_URL =
  'https://pornstarcoaching.com/?uid=209&oid=12&affid=1677';
export const ENDURA_NATURALS_AFFILIATE_URL =
  'https://enduranaturals.com/tsl/tsl.php?uid=64&oid=4&affid=1677';
export const PHALOGENICS_AFFILIATE_URL =
  'https://www.phalogenics.com/?uid=192&oid=3&affid=1677';
export const SHIATSU_IMAGE =
  'https://clubdynasty.ca/wp-content/uploads/2024/07/Female_Silouhette-square.jpg';
