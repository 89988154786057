import { Avatar, Card, Grid, Typography } from '@mui/material';
import sessions_img from '../img/sessions.svg';

const Sessions = ({ sessions }) => {
  return (
    <Card
      variant="outlined"
      style={{
        border: '1px solid #c2872b66',
        borderRadius: '15px',
        padding: '20px',
        marginTop: '20px',
      }}
      className="profile-card-shadow"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#C2872B',
              fontWeight: '500',
              fontFamily: 'Poppins',
            }}
          >
            Sessions
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontFamily: 'Poppins',
            }}
          >
            {sessions}
          </Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <Avatar src={sessions_img} sx={{ width: 76, height: 76 }} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Sessions;
