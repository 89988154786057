import React, { Suspense, lazy, useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  CardHeader,
  Avatar,
  IconButton,
  Badge,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
import { format } from 'date-fns';
import { masseuseAvailabilityChecker } from '../utils/masseuseAvailabilityChecker';
import 'bootstrap/dist/css/bootstrap.css';
import ImageAndVideoCarousel from './ImageAndVideoCarousel';
import { isVideo } from '../utils/isVideo';
import { incrementFeedMediaVisit } from '../actions/feedActions';
import { useDispatch, useSelector } from 'react-redux';
import FeedImage from './FeedImage';

const VideoJsPlayer = lazy(() => import('../components/VideoJsPlayer'));

const MasseuseFeedItem = ({
  feed,
  masseusesAvailability,
  handleAvatarDialogOpen,
}) => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const dispatch = useDispatch();

  // Ref for storing the array of image elements
  const imageRefs = useRef([]);


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    // Ensure Intersection Observer is supported
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Handle intersecting entry
              const lazyImage = entry.target;

              // Debugging: Check if the target is an Element before unobserving
              if (lazyImage instanceof Element) {
                feedMediaVisit(lazyImage.alt);
                observer.unobserve(lazyImage);
              } else {
                console.error(
                  'Attempted to unobserve a non-Element:',
                  lazyImage
                );
              }
            }
          });
        },
        {
          rootMargin: '0px',
          threshold: 0.1,
        }
      );

      // Ensure imageRefs.current contains elements and observe them
      const validElements = imageRefs.current.filter(
        (img) => img instanceof Element
      );
      validElements.forEach((img) => observer.observe(img));

      // Cleanup function to disconnect observer on component unmount
      return () => {
        validElements.forEach((element) => {
          // Safety check in case elements have been removed
          if (observer && element instanceof Element) {
            observer.unobserve(element);
          }
        });
        observer.disconnect();
      };
    }
  }, []); // Empty dependency array means this effect runs once on mount

  const feedMediaVisit = (id) => {
    dispatch(incrementFeedMediaVisit(id));
  };

  return (
    <Grid item xs={12} sm={12} md={12} key={feed.id} mb={4}>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Tooltip
              title={
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  <li style={{ marginBottom: '10px' }}>
                    <img
                      src={feed.owner.image}
                      alt={feed.owner.name}
                      width={'280px'}
                    />
                  </li>
                  <li style={{ marginBottom: '-5px' }}>
                    <Typography
                      variant="h3"
                      component="div"
                      className="masseuse-name"
                      style={{
                        fontWeight: '800',
                      }}
                    >
                      {feed.owner.age.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{ typography: { xs: 'h5' }, textAlign: 'center' }}
                    >
                      {feed.owner.background}
                    </Typography>
                  </li>
                </ul>
              }
            >
              {masseuseAvailabilityChecker(
                masseusesAvailability,
                feed.owner.id
              ) ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar
                    aria-label="masseuse-picture"
                    src={feed.owner.image}
                    sx={{ width: 60, height: 60 }}
                  >
                    {feed.owner.name.charAt(0)}
                  </Avatar>
                </StyledBadge>
              ) : (
                <Avatar
                  aria-label="masseuse-picture"
                  src={feed.owner.image}
                  sx={{ width: 60, height: 60 }}
                >
                  {feed.owner.name.charAt(0)}
                </Avatar>
              )}
            </Tooltip>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVert />
            </IconButton>
          }
          title={feed.owner.name}
          subheader={format(new Date(feed.created), "MMMM d 'at' h:mm a")}
        />

        <CardContent style={{ paddingTop: '0px' }}>
          <Typography paragraph>{feed.content}</Typography>
          {feed.media.length > 1 && (
            <ImageAndVideoCarousel
              media={feed.media}
              handleAvatarDialogOpen={handleAvatarDialogOpen}
              imageRefs={imageRefs}
              token={userInfo?.token}
            />
          )}
          {feed.media.length === 1 &&
            (isVideo(
              feed.media[0].hls ? feed.media[0].hls : feed.media[0].file
            ) ? (
              <Suspense
                fallback={
                  <Skeleton
                    sx={{ height: 200, marginTop: '15px' }}
                    animation="wave"
                    variant="rectangular"
                  />
                }
              >
                <VideoJsPlayer media={feed.media[0]} />
              </Suspense>
            ) : (
              <FeedImage
                imageRefs={imageRefs}
                file={feed.media[0].file}
                id={feed.media[0].id}
                handleAvatarDialogOpen={handleAvatarDialogOpen}
                token={userInfo?.token}
                marginTop={'15px'}
              />
            ))}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default MasseuseFeedItem;
