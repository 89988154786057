import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import {
  APPOINTMENT_CREATE_FAIL,
  APPOINTMENT_CREATE_REQUEST,
  APPOINTMENT_CREATE_SUCCESS,
  APPOINTMENT_DELETE_FAIL,
  APPOINTMENT_DELETE_REQUEST,
  APPOINTMENT_DELETE_SUCCESS,
  APPOINTMENT_LIST_FAIL,
  APPOINTMENT_LIST_REMOVE_ITEM,
  APPOINTMENT_LIST_REQUEST,
  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_UPDATE_FAIL,
  APPOINTMENT_UPDATE_REQUEST,
  APPOINTMENT_UPDATE_SUCCESS,
} from '../constants/appointmentConstants';
import formatError from '../utils/errors';
import { setAlert } from './alertActions';
import * as Sentry from '@sentry/react';

export const getAppointmentList =
  (page = '1', page_size = '10') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: APPOINTMENT_LIST_REQUEST,
      });

      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      let { data } = await axios.get(
        `${API_URI}/appointments/?page=${page}&page_size=${page_size}`,
        config
      );

      dispatch({
        type: APPOINTMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setAlert(error.response.data.detail, 'error'));

      dispatch({
        type: APPOINTMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data,
      });
    }
  };

export const createAppointment =
  (content, handleClose, setContent) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPOINTMENT_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
        appointmentList: { appointments },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/appointments/`,
        {
          content,
        },
        config
      );

      let newAppointmentResults = [data, ...appointments.results];

      appointments.results = newAppointmentResults;

      dispatch({
        type: APPOINTMENT_CREATE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: APPOINTMENT_LIST_SUCCESS,
        payload: appointments,
      });

      handleClose();
      setContent('');
      dispatch(setAlert('Appointment added', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: APPOINTMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createAppointmentBooking =
  (appointmentSessionType, appointmentWorkingDate, masseuseId, time) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: APPOINTMENT_CREATE_REQUEST,
      });

      const requestBody = {
        session_type: appointmentSessionType,
        working_date: appointmentWorkingDate,
        masseuse: masseuseId,
        time,
      };

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `https://mydynasty.ca/cd/api/v1/appointments/`,

        requestBody,

        config
      );

      // let newAppointmentResults = [data, ...appointments.results];

      // appointments.results = newAppointmentResults;

      dispatch({
        type: APPOINTMENT_CREATE_SUCCESS,
        payload: data,
      });

      // dispatch({
      //   type: APPOINTMENT_LIST_SUCCESS,
      //   payload: appointments,
      // });

      dispatch(setAlert('Appointment added', 'success'));
    } catch (error) {
      console.log(error.response);
      // Sentry.captureException(error);
      // const errorText = formatError(error.response.data);
      // dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: APPOINTMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateAppointment =
  (id, content, setEdit) => async (dispatch, getState) => {
    try {
      dispatch({
        type: APPOINTMENT_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.put(
        `${API_URI}/appointments/${id}/`,
        {
          content,
        },
        config
      );

      dispatch({
        type: APPOINTMENT_UPDATE_SUCCESS,
        payload: data,
      });

      setEdit(false);

      dispatch(setAlert('Appointment updated', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: APPOINTMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteAppointment = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPOINTMENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
      appointmentList: { appointments },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.delete(
      `${API_URI}/appointments/${id}/`,
      config
    );

    dispatch({
      type: APPOINTMENT_LIST_REMOVE_ITEM,
      payload: id,
    });

    dispatch({
      type: APPOINTMENT_DELETE_SUCCESS,
      payload: data,
    });

    dispatch(setAlert('Appointment deleted', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errorText = formatError(error.response.data);
    dispatch(setAlert(errorText, 'error'));

    dispatch({
      type: APPOINTMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
