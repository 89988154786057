import React from 'react';
import useAvailableMasseuses from '../hooks/useAvailableMasseuses';
import { Container, Divider, Grid, Typography } from '@mui/material';

import AppointmentBookingMasseuse from '../components/AppointmentBookingMasseuse';
import { useSelector } from 'react-redux';
import Message from '../components/Message';

const AppointmentBookingScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const {
    availableMasseuses,
    isLoading: availableMasseusesLoading,
    error: availableMasseusesError,
  } = useAvailableMasseuses();

  return (
    <Container>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h2">Book Appointment</Typography>
          <Typography variant="p">
            Masseuses available on{' '}
            <span style={{ fontWeight: 'bold' }}>
              {new Date().toDateString()}
            </span>
          </Typography>

          <Divider
            sx={{
              display: 'block',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          />
        </Grid>
        {availableMasseusesLoading ? (
          <p>Loading...</p>
        ) : availableMasseusesError ? (
          <p>Error</p>
        ) : (
          availableMasseuses?.map((masseuse) => (
            <AppointmentBookingMasseuse
              key={masseuse.id}
              masseuse={masseuse}
              token={userInfo?.token}
            />
          ))
        )}{' '}
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default AppointmentBookingScreen;
