import { Box, Card, Skeleton } from '@mui/material';

const ProfileInfoCardLoader = () => {
  return (
    <Card
      variant="outlined"
      style={{
        padding: '25px',
        borderRadius: '15px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Skeleton
          variant="circular"
          width={145}
          height={145}
          style={{ marginBottom: '10px' }}
        />
        <Skeleton
          variant="rectangular"
          height={100}
          width="100%"
          style={{ marginBottom: '10px' }}
        />
        <Skeleton width="100%" />
        <Skeleton width="100%" style={{ marginBottom: '10px' }} />
        <Skeleton
          variant="rectangular"
          height={100}
          width="100%"
          style={{ marginBottom: '10px' }}
        />
        <Skeleton width="100%" />
        <Skeleton width="80%" />
        <Skeleton width="60%" />
        <Skeleton width="40%" />
      </Box>
    </Card>
  );
};

export default ProfileInfoCardLoader;
