import {
  SESSION_LIST_FAIL,
  SESSION_LIST_NOTE_UPDATE,
  SESSION_LIST_REQUEST,
  SESSION_LIST_RESET,
  SESSION_LIST_SUCCESS,
  SESSION_NOTES_CREATE_FAIL,
  SESSION_NOTES_CREATE_REQUEST,
  SESSION_NOTES_CREATE_RESET,
  SESSION_NOTES_CREATE_SUCCESS,
  SESSION_NOTES_DELETE_FAIL,
  SESSION_NOTES_DELETE_REQUEST,
  SESSION_NOTES_DELETE_RESET,
  SESSION_NOTES_DELETE_SUCCESS,
  SESSION_NOTES_UPDATE_FAIL,
  SESSION_NOTES_UPDATE_REQUEST,
  SESSION_NOTES_UPDATE_RESET,
  SESSION_NOTES_UPDATE_SUCCESS,
} from '../constants/sessionConstants';

export const sessionListReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSION_LIST_REQUEST:
      return { loading: true };
    case SESSION_LIST_SUCCESS:
      return { loading: false, sessions: action.payload };
    case SESSION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case SESSION_LIST_RESET:
      return {};
    case SESSION_LIST_NOTE_UPDATE:
      return { loading: false, sessions: action.payload };
    default:
      return state;
  }
};

export const sessionNoteCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSION_NOTES_CREATE_REQUEST:
      return { loading: true, session: action.payload };
    case SESSION_NOTES_CREATE_SUCCESS:
      return { loading: false, note: action.payload };
    case SESSION_NOTES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SESSION_NOTES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sessionNoteUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSION_NOTES_UPDATE_REQUEST:
      return { loading: true };
    case SESSION_NOTES_UPDATE_SUCCESS:
      return { loading: false, note: action.payload };
    case SESSION_NOTES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case SESSION_NOTES_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const sessionNoteDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SESSION_NOTES_DELETE_REQUEST:
      return { loading: true };
    case SESSION_NOTES_DELETE_SUCCESS:
      return { loading: false, note: action.payload };
    case SESSION_NOTES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case SESSION_NOTES_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
