export default function convertUrl(url) {
  if (!url) {
    return '';
  }

  if (process.env.NODE_ENV === 'development') {
    return url;
  }

  // Check if URL starts with http:// or https://
  if (url.startsWith('http://') || url.startsWith('https://')) {
    // Replace any domain with 'https://members.clubdynasty.ca'
    return url.replace(/https?:\/\/[^\/]*/, 'https://members.clubdynasty.ca');
  } else if (url.startsWith('/')) {
    // If URL is a relative path, prepend the members domain
    return 'https://members.clubdynasty.ca' + url;
  } else {
    // If URL does not start with expected patterns, return as is or handle differently
    console.log('URL format is not recognized:', url);
    return url; // or handle differently
  }
}
