import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import {
  userLoginReducer,
  userRegisterReducer,
  userPasswordResetReducer,
  userDetailsReducer,
  userPasswordChangeReducer,
  userPasswordResetVerifyReducer,
  userPasswordResetConfirmReducer,
  userUpdateProfileReducer,
  userVerifyEmailReducer,
  userVerifyEmailConfirmReducer,
  userVerifyPhoneReducer,
  userVerifyPhoneConfirmReducer,
  userPreferenceReducer,
  userPreferenceUpdateReducer,
  userTransactionsReducer,
} from './reducers/userReducers';

import {
  availableMasseusesListReducer,
  allMasseusesListReducer,
  masseuseRatingReducer,
  allMasseusesPublicListReducer,
  masseuseDetailsReducer,
} from './reducers/masseuseReducers';

import {
  sessionListReducer,
  sessionNoteCreateReducer,
  sessionNoteDeleteReducer,
  sessionNoteUpdateReducer,
} from './reducers/sessionReducers';

import alertReducer from './reducers/alertReducers';
import {
  notificationListReducer,
  notificationReadReducer,
} from './reducers/notificationReducers';
import { transactionListReducer } from './reducers/transactionReducers';
import {
  feedbackCreateReducer,
  feedbackDeleteReducer,
  feedbackListReducer,
  feedbackUpdateReducer,
} from './reducers/feedbackReducers';
import { popupDetailsReducer } from './reducers/popupReducers';
import { surveySubmitReducer } from './reducers/surveyReducers';
import isTokenValid from './utils/isTokenValid';
import {
  feedBlockCreateReducer,
  feedBlockDeleteReducer,
  feedBlockListReducer,
  feedFavoriteCreateReducer,
  feedFavoriteDeleteReducer,
  feedFavoriteListReducer,
} from './reducers/feedReducers';
import { appointmentCreateReducer } from './reducers/appointmentReducers';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userPasswordReset: userPasswordResetReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userPasswordChange: userPasswordChangeReducer,
  userPasswordResetVerify: userPasswordResetVerifyReducer,
  userPasswordResetConfirm: userPasswordResetConfirmReducer,
  userVerifyEmail: userVerifyEmailReducer,
  userVerifyEmailConfirm: userVerifyEmailConfirmReducer,
  userVerifyPhone: userVerifyPhoneReducer,
  userVerifyPhoneConfirm: userVerifyPhoneConfirmReducer,
  userPreference: userPreferenceReducer,
  userPreferenceUpdate: userPreferenceUpdateReducer,
  userTransactions: userTransactionsReducer,

  notificationList: notificationListReducer,
  notificationRead: notificationReadReducer,

  transactionList: transactionListReducer,

  availableMasseusesList: availableMasseusesListReducer,
  allMasseusesList: allMasseusesListReducer,
  allMasseusesPublicList: allMasseusesPublicListReducer,
  masseuseRating: masseuseRatingReducer,
  masseuseDetails: masseuseDetailsReducer,

  sessionList: sessionListReducer,
  sessionNoteCreate: sessionNoteCreateReducer,
  sessionNoteUpdate: sessionNoteUpdateReducer,
  sessionNoteDelete: sessionNoteDeleteReducer,

  feedbackList: feedbackListReducer,
  feedbackCreate: feedbackCreateReducer,
  feedbackUpdate: feedbackUpdateReducer,
  feedbackDelete: feedbackDeleteReducer,

  feedBlockList: feedBlockListReducer,
  feedBlockCreate: feedBlockCreateReducer,
  feedBlockDelete: feedBlockDeleteReducer,

  feedFavoriteList: feedFavoriteListReducer,
  feedFavoriteCreate: feedFavoriteCreateReducer,
  feedFavoriteDelete: feedFavoriteDeleteReducer,

  appointmentCreate: appointmentCreateReducer,

  popupDetails: popupDetailsReducer,

  alert: alertReducer,

  surveySubmit: surveySubmitReducer,
});

const userInfoFromStorage =
  localStorage.getItem('userInfo') && isTokenValid()
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
