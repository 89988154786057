import React from 'react';
import {
  Avatar,
  Tooltip,
  Typography,
  Button,
  TableRow,
  TableCell,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { format } from 'date-fns';
import CancelIcon from '@mui/icons-material/Cancel';

const FavoritedMasseusesTable = ({ favorite, handleUnfavoritePopupOpen }) => {
  const handleDelete = (masseuse_detail, favorite_id) => {
    handleUnfavoritePopupOpen(masseuse_detail, favorite_id);
  };

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        <Tooltip
          leaveDelay={300}
          title={
            <ul
              style={{
                listStyleType: 'none',
                padding: 0,
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <li style={{ marginBottom: '10px' }}>
                <img
                  src={favorite.masseuse_detail?.image}
                  alt={favorite.masseuse_detail?.name}
                  width={'280px'}
                />
              </li>
              <li>
                <Typography
                  variant="h3"
                  component="div"
                  className="masseuse-name"
                  style={{
                    fontWeight: '800',
                  }}
                >
                  {favorite.masseuse_detail?.age}
                </Typography>
                <Typography
                  sx={{ typography: { xs: 'h5' }, textAlign: 'center' }}
                >
                  {favorite.masseuse_detail?.background.toUpperCase()}
                </Typography>
              </li>
              <li style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() =>
                    handleDelete(favorite.masseuse_detail, favorite.id)
                  }
                >
                  Unhide
                </Button>
              </li>
            </ul>
          }
        >
          <div>
            <Avatar
              alt={favorite.masseuse_detail?.name}
              src={favorite.masseuse_detail?.image}
            />
            {favorite.masseuse_detail?.name}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        {format(new Date(favorite.created), "MMMM d 'at' h:mm a")}
      </TableCell>
      <TableCell align="right">
        <IconButton
          onClick={() => handleDelete(favorite.masseuse_detail, favorite.id)}
        >
          <CancelIcon color={'primary'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default FavoritedMasseusesTable;
