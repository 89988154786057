import { Button, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

const CookieConsent = ({ cookieConsent }) => {
  const [open, setOpen] = useState(cookieConsent ? false : true);

  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const giveCookieConsent = () => {
    setCookie('cookieConsent', true, { path: '/' });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return; // Don't close the Snackbar if the user clicks away
    }
    setOpen(false); // Close the Snackbar
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={
        <span>
          We use cookies, including third-party cookies, to enhance your user
          experience. By using our website, you agree to our use of cookies.{' '}
          <Link to="https://clubdynasty.ca/privacy-policy/">Learn More.</Link>
        </span>
      }
      action={
        <>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClose}
            disableElevation
          >
            Reject
          </Button>
          &nbsp; &nbsp;
          <Button
            color="primary"
            variant="contained"
            onClick={giveCookieConsent}
            disableElevation
          >
            Accept
          </Button>
          {/* <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton> */}
        </>
      }
    />
  );
};

export default CookieConsent;
