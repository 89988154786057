export const SESSION_LIST_REQUEST = 'SESSION_LIST_REQUEST';
export const SESSION_LIST_SUCCESS = 'SESSION_LIST_SUCCESS';
export const SESSION_LIST_FAIL = 'SESSION_LIST_FAIL';
export const SESSION_LIST_RESET = 'SESSION_LIST_RESET';
export const SESSION_LIST_NOTE_UPDATE = 'SESSION_LIST_NOTE_UPDATE';

export const SESSION_NOTES_CREATE_REQUEST = 'SESSION_NOTES_CREATE_REQUEST';
export const SESSION_NOTES_CREATE_SUCCESS = 'SESSION_NOTES_CREATE_SUCCESS';
export const SESSION_NOTES_CREATE_FAIL = 'SESSION_NOTES_CREATE_FAIL';
export const SESSION_NOTES_CREATE_RESET = 'SESSION_NOTES_CREATE_RESET';

export const SESSION_NOTES_UPDATE_REQUEST = 'SESSION_NOTES_UPDATE_REQUEST';
export const SESSION_NOTES_UPDATE_SUCCESS = 'SESSION_NOTES_UPDATE_SUCCESS';
export const SESSION_NOTES_UPDATE_FAIL = 'SESSION_NOTES_UPDATE_FAIL';
export const SESSION_NOTES_UPDATE_RESET = 'SESSION_NOTES_UPDATE_RESET';

export const SESSION_NOTES_DELETE_REQUEST = 'SESSION_NOTES_DELETE_REQUEST';
export const SESSION_NOTES_DELETE_SUCCESS = 'SESSION_NOTES_DELETE_SUCCESS';
export const SESSION_NOTES_DELETE_FAIL = 'SESSION_NOTES_DELETE_FAIL';
export const SESSION_NOTES_DELETE_RESET = 'SESSION_NOTES_DELETE_RESET';
