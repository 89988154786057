import React from 'react';
import useMasseuseDetails from '../hooks/useMasseuseDetails';
import {
  Chip,
  Avatar,
  Tooltip,
  Typography,
  Button,
  Skeleton,
} from '@mui/material';
import { format } from 'date-fns';
import CancelIcon from '@mui/icons-material/Cancel';

const BlockedMasseuse = ({ block, token, handleUnblockPopupOpen }) => {
  const { masseuseDetails, isLoading, error } = useMasseuseDetails(
    block.masseuse,
    token
  );

  const handleDelete = (masseuseDetails, block_id) => {
    handleUnblockPopupOpen(masseuseDetails, block_id);
  };

  return isLoading ? (
    <Chip
      label={<Skeleton variant="text" width={300} sx={{ fontSize: '1rem' }} />}
      variant="outlined"
      style={{ margin: '10px 10px 0px 0px' }}
    />
  ) : error ? (
    <p>Error</p>
  ) : (
    <Chip
      avatar={
        <Tooltip
          leaveDelay={300}
          title={
            <ul
              style={{
                listStyleType: 'none',
                padding: 0,
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <li style={{ marginBottom: '10px' }}>
                <img
                  src={masseuseDetails?.image}
                  alt={masseuseDetails?.name}
                  width={'280px'}
                />
              </li>
              <li>
                <Typography
                  variant="h3"
                  component="div"
                  className="masseuse-name"
                  style={{
                    fontWeight: '800',
                  }}
                >
                  {masseuseDetails?.age}
                </Typography>
                <Typography
                  sx={{ typography: { xs: 'h5' }, textAlign: 'center' }}
                >
                  {masseuseDetails?.background.toUpperCase()}
                </Typography>
              </li>
              <li style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleDelete(masseuseDetails, block.id)}
                >
                  Unhide
                </Button>
              </li>
            </ul>
          }
        >
          <Avatar alt={masseuseDetails?.name} src={masseuseDetails?.image} />
        </Tooltip>
      }
      label={`${masseuseDetails?.name}: Blocked on ${format(
        new Date(block.created),
        "MMMM d 'at' h:mm a"
      )}`}
      style={{ margin: '10px 10px 0px 0px' }}
      onDelete={() => handleDelete(masseuseDetails, block.id)}
      deleteIcon={<CancelIcon color={'primary'} />}
      variant="outlined"
    />
  );
};

export default BlockedMasseuse;
