import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import {
  SESSION_LIST_FAIL,
  SESSION_LIST_NOTE_UPDATE,
  SESSION_LIST_REQUEST,
  SESSION_LIST_SUCCESS,
  SESSION_NOTES_CREATE_FAIL,
  SESSION_NOTES_CREATE_REQUEST,
  SESSION_NOTES_CREATE_SUCCESS,
  SESSION_NOTES_DELETE_FAIL,
  SESSION_NOTES_DELETE_REQUEST,
  SESSION_NOTES_DELETE_SUCCESS,
  SESSION_NOTES_UPDATE_FAIL,
  SESSION_NOTES_UPDATE_REQUEST,
  SESSION_NOTES_UPDATE_SUCCESS,
} from '../constants/sessionConstants';
import formatError from '../utils/errors';
import { setAlert } from './alertActions';
import * as Sentry from '@sentry/react';

export const getSessionList =
  (page = '', page_size = '', rated = false) =>
  async (dispatch, getState) => {
    try {
      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      if (!rated) {
        dispatch({
          type: SESSION_LIST_REQUEST,
        });
      }

      let { data } = await axios.get(
        `${API_URI}/members/sessions/?page=${page}&page_size=${page_size}&order=desc`,
        config
      );

      let masseuses = await axios.get(
        `${API_URI}/masseuses/?page=1&page_size=10000`,
        config
      );

      let ratings = await axios.get(`${API_URI}/ratings/`, config);

      let ownRatings = await axios.get(`${API_URI}/members/ratings/`, config);

      let notes = await axios.get(
        `${API_URI}/notes/?page=1&page_size=1000`,
        config
      );

      data.results.forEach((session) => {
        let masseusesDetails = [];
        session.masseuses.forEach((m) => {
          masseusesDetails = [
            ...masseusesDetails,
            ...masseuses.data.results.filter((masseuse) => masseuse.id === m),
          ];

          masseusesDetails.forEach((m) => {
            ratings.data.forEach((r) => {
              if (m.id == r.masseuse) {
                m.rating = r;
              }
            });

            ownRatings.data.forEach((r) => {
              if (m.id == r.masseuse) {
                m.ownRating = r;
              }
            });
          });
        });

        let newNotes = notes.data.results.filter(
          (note) => session.id === note.session
        );

        session.masseuses = masseusesDetails;
        session.notes = newNotes;
      });

      dispatch({
        type: SESSION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: SESSION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createSessionNote =
  (session, content, noteChanged, setNoteChanged) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SESSION_NOTES_CREATE_REQUEST,
        payload: session,
      });

      const {
        userLogin: { userInfo },
        sessionList: { sessions },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/notes/`,
        {
          session,
          content,
        },
        config
      );

      setNoteChanged(noteChanged + 1);

      dispatch({
        type: SESSION_NOTES_CREATE_SUCCESS,
        payload: data,
      });

      dispatch(setAlert('Note added', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: SESSION_NOTES_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateSessionNote =
  (session, content, setEdit, noteChanged, setNoteChanged) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SESSION_NOTES_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
        // sessionList: { sessions },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.put(
        `${API_URI}/notes/${session.notes[0].id}/`,
        {
          session: session.id,
          content,
        },
        config
      );

      // for (let i = 0; i < sessions.results.length; i++) {
      //   if (
      //     sessions.results[i].notes.length > 0 &&
      //     sessions.results[i].notes[0].id === data.id
      //   ) {
      //     sessions.results[i].notes[0] = data;
      //     break;
      //   }
      // }

      dispatch({
        type: SESSION_NOTES_UPDATE_SUCCESS,
        payload: data,
      });

      setNoteChanged(noteChanged + 1);

      // dispatch({
      //   type: SESSION_LIST_NOTE_UPDATE,
      //   payload: sessions,
      // });

      setEdit(false);

      dispatch(setAlert('Note updated', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: SESSION_NOTES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSessionNote =
  (session, noteChanged, setNoteChanged) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SESSION_NOTES_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.delete(
        `${API_URI}/notes/${session.notes[0].id}/`,
        config
      );

      // for (let i = 0; i < sessions.results.length; i++) {
      //   if (sessions.results[i].id === session.id) {
      //     sessions.results[i].notes = [];
      //     break;
      //   }
      // }

      dispatch({
        type: SESSION_NOTES_DELETE_SUCCESS,
        payload: data,
      });

      setNoteChanged(noteChanged + 1);

      // dispatch({
      //   type: SESSION_LIST_NOTE_UPDATE,
      //   payload: sessions,
      // });

      dispatch(setAlert('Note deleted', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      // console.log(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: SESSION_NOTES_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
