import Carousel from 'react-bootstrap/Carousel';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import axios from 'axios';
import { useQuery } from 'react-query';
import 'bootstrap/dist/css/bootstrap.css';
import { Typography } from '@mui/material';

function FeedbackSlider({ masseuseId }) {
  const {
    isLoading: isFeedbacksLoading,
    error: feedbacksError,
    data: feedbacks,
  } = useQuery(
    ['masseuseFeedbacksForSlider', masseuseId],
    fetchMasseuseFeedbacks,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  async function fetchMasseuseFeedbacks() {
    const response = await axios.get(
      `https://mydynasty.ca/cd/api/v1/masseuses/${masseuseId}/testimonials/`
    );
    return response.data;
  }

  if (feedbacks && feedbacks.length > 0) {
    return (
      <>
        <Carousel variant="dark" interval={null}>
          {feedbacks.map((feedback) => (
            <Carousel.Item
              key={feedback.id}
              style={{
                padding: '20px 40px 30px 40px',
                border: '1px solid grey',
                borderRadius: '5px',
                textAlign: 'justify',
                borderColor: 'rgba(0, 0, 0, 0.125)',
              }}
              className="text-container"
            >
              <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>
                <FormatQuoteIcon
                  style={{ transform: 'scaleX(-1)', color: '#c3892b' }}
                />
                &nbsp;
                {feedback.content}&nbsp;
                <FormatQuoteIcon style={{ color: '#c3892b' }} />
              </h4>
            </Carousel.Item>
          ))}
        </Carousel>{' '}
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{
            textAlign: 'center',
            marginTop: '5px',
            color: '#c3892b',
            fontWeight: '600',
          }}
        >
          These comments are left by real customers at the reception desk upon
          checkout.
        </Typography>
      </>
    );
  } else
    return (
      <h4 className="masseuse-details">
        <span className="masseuse-details-span">Feedback Not Available</span>
      </h4>
    );
}

export default FeedbackSlider;
