const errorHandler = (error) => {
  let errorText = ``;

  if (typeof error === 'object' && !Array.isArray(error) && error !== null) {
    for (const [key, value] of Object.entries(error)) {
      errorText += `|${key.toUpperCase()}| `;

      if (value instanceof Array) {
        for (let i = 0; i < value.length; i++) {
          errorText += `[${i + 1}] ${value[i]}, `;
        }
        // Remove trailing comma and space
        errorText = errorText.substring(0, errorText.length - 2);
      } else {
        errorText += value;
      }

      errorText += ` `;
    }
  } else if (error !== null && Array.isArray(error)) {
    errorText = error.join(', ');
  }

  return errorText.trim();
};

export default errorHandler;
