import { Skeleton } from '@mui/material';
import React from 'react';
import styles from '../styles/ScheduleScreen.module.css';

const ScheduleTableLoader = () => {
  return (
    <table className={styles.tableOuter}>
      <thead>
        <tr className={styles.tableRow}>
          {Array.from({ length: 7 }).map((_, i) => (
            <th className={styles.tableColumn} key={i}>
              <Skeleton width={'100%'} height={30}></Skeleton>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className={styles.tableRow}>
          {Array.from({ length: 7 }).map((_, i) => (
            <td className={styles.tableColumn} key={i}>
              {Array.from({ length: 20 }).map((_, i) => (
                <table style={{ width: '100%' }} key={i}>
                  <tbody>
                    <tr>
                      <td>
                        <Skeleton width={'100%'} height={30}></Skeleton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default ScheduleTableLoader;
