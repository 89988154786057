import { Container, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import CD_LOGO from '../img/cd_logo_no_shadow.png';

const GuestNotificationScreen = () => {
  const [masseuses, setMasseuses] = useState([
    // {
    //   id: 1,
    //   locker: 23,
    //   start_time: new Date(),
    // },
    // {
    //   locker: 99,
    //   start_time: '2023-04-01T16:45:00Z',
    //   id: 63160,
    //   customer: 'Alex',
    // },
    // {
    //   id: 2,
    //   locker: 3,
    //   start_time: new Date(),
    // },
    // {
    //   locker: 4,
    //   start_time: '2023-04-01T16:45:00Z',
    //   id: 63161,
    //   customer: 'Alex',
    // },
    // {
    //   id: 3,
    //   locker: 99,
    //   start_time: new Date(),
    // },
    // {
    //   locker: 6,
    //   start_time: '2023-04-01T16:45:00Z',
    //   id: 63163,
    //   customer: 'Alex',
    // },
    // {
    //   id: 4,
    //   locker: 5,
    //   start_time: new Date(),
    // },
    // {
    //   locker: 6,
    //   start_time: '2023-04-01T16:45:00Z',
    //   id: 63164,
    //   customer: 'Alex',
    // },
  ]);
  const [refreshInterval, setRefreshInterval] = useState(10000);

  const fetchData = () => {
    if (masseuses.length === 0) {
      axios
        .get('https://mydynasty.ca/cd/api/v1/sessions/active/')
        .then((res) => {
          setMasseuses(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(fetchData, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  useEffect(() => {
    if (masseuses.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'black',
        backgroundImage:
          'url("https://clubdynasty.ca/wp-content/uploads/2023/07/spatrack-bg.jpg")',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100vw',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        padding: '50px 50px 50px 50px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid container spacing={7} display="flex" justifyContent="center">
        <Grid
          item
          xs={12}
          // style={{ display: 'flex', alignItems: 'center' }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <div className="it-is-time">
              <Typography variant="h1" color="black">
                {`If your number is flashing, please head into the lounge now`.toUpperCase()}
              </Typography>
            </div>
          </div>
        </Grid>
        {masseuses.length > 0 ? (
          masseuses.map((m) => (
            <Grid item xs={2} key={m.id} mb={1}>
              <div className={`locker-and-time-card-glow`}>
                <div>
                  <Typography variant="h1">{m.locker}</Typography>
                </div>
                <div>
                  <h2 variant="h2">
                    {format(new Date(m.start_time), 'p').toUpperCase()}
                  </h2>
                </div>
              </div>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} mt={0}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="it-is-time glow-effect">
                <Typography variant="h1" color="black">
                  YOUR NUMBER WILL APPEAR HERE
                </Typography>
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={12} mt={0}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <div className="it-is-time">
              <Typography variant="h1" color="black">
                {masseuses.length > 0
                  ? `IT'S TIME FOR YOUR MASSAGE`
                  : 'KEEP AN EYE ON THIS SCREEN FOR YOUR NUMBER'}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default GuestNotificationScreen;
