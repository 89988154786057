import { Skeleton } from '@mui/material';

const RecommendedMasseusesLoader = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={370}
      sx={{
        borderRadius: '15px',
      }}
    />
  );
};

export default RecommendedMasseusesLoader;
