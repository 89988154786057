import { Card, CardContent, Container, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroll-component';
import Message from '../components/Message';
import Meta from '../components/Meta';
import Notes from '../components/Notes';
import SessionCard from '../components/SessionCard';
import SessionLoaderSkeleton from '../components/SessionLoaderSkeleton';
import useOwnRatings from '../hooks/useOwnRatings';
import useSessions from '../hooks/useSessions';

function SessionScreen() {
  const dispatch = useDispatch();
  const [noteChanged, setNoteChanged] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const masseuseRating = useSelector((state) => state.masseuseRating);
  const { success } = masseuseRating;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const {
    ownRatings,
    isLoading: isOwnRatingsLoading,
    error: ownRatingsError,
  } = useOwnRatings(userInfo.token);

  const { data, isLoading, error, fetchNextPage } = useSessions(
    userInfo.token,
    noteChanged
  );

  useEffect(() => {
    if (success) {
      dispatch({ type: 'MASSEUSE_RATE_RESET' });
    }
  }, []);

  return (
    <Container sx={{ minHeight: '60vh' }}>
      <Meta
        title={'Sessions'}
        description={
          'Your sessions at Club Dynasty Executive Health Spa & Exotic Massage'
        }
        keywords={
          'sessions, club dynasty sessions, club dynasty members area, vip members'
        }
      />
      <Grid container spacing={1} mt={10}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Sessions
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!isLoading ? (
            <InfiniteScroll
              dataLength={data?.pages?.slice(-1)[0].last ?? 5}
              next={fetchNextPage}
              hasMore={
                data?.pages.slice(-1)[0].total ===
                data?.pages.slice(-1)[0].current
                  ? false
                  : true
              }
              loader={<SessionLoaderSkeleton />}
              endMessage={
                <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                  You have reached the end of the session list
                </p>
              }
              style={{ width: '100%' }}
            >
              <Grid container spacing={3} mb={3}>
                {data?.pages.map((group) =>
                  group.results.map((session, i) => (
                    <Grid item xs={12} sm={4} key={i}>
                      <Card
                        sx={{ borderRadius: '15px', height: '100%' }}
                        variant="outlined"
                      >
                        <SessionCard
                          session={session}
                          token={userInfo.token}
                          ownRatings={ownRatings}
                        />
                        <CardContent>
                          <Notes
                            note={
                              session.notes[0] && session.notes[0].content
                                ? session.notes[0].content
                                : ''
                            }
                            session={session}
                            noteChanged={noteChanged}
                            setNoteChanged={setNoteChanged}
                            isNotesLoading={isLoading}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </InfiniteScroll>
          ) : (
            <SessionLoaderSkeleton />
          )}
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
}

export default SessionScreen;
