import {
  Button,
  CardContent,
  CardMedia,
  Grid,
  Rating,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';
import useMasseuseDetails from '../hooks/useMasseuseDetails';
import { useNavigate } from 'react-router';
import StarRateIcon from '@mui/icons-material/StarRate';
import { Link } from 'react-router-dom';

const SessionMasseuses = ({ masseuse, token, ownRating, session_id }) => {
  const navigate = useNavigate();

  const {
    masseuseDetails,
    isLoading: isMasseuseDetailsLoading,
    error,
  } = useMasseuseDetails(masseuse, token);

  const handleSurveyNavigate = (session) => {
    navigate(
      `/sessions/survey/${session}?masseuse=${masseuseDetails.name}&masseuse_id=${masseuseDetails.id}`
    );
  };

  if (isMasseuseDetailsLoading)
    return <Skeleton variant="rounded" width={'100%'} height={300} />;

  return (
    <CardContent>
      <Grid container>
        <Grid item xs={6}>
          <Link to={`/masseuse/${masseuseDetails?.id}`}>
            <CardMedia
              component="img"
              alt={masseuseDetails?.name}
              image={
                error || !masseuseDetails?.image
                  ? 'https://clubdynasty.ca/wp-content/uploads/2024/07/Female_Silouhette-square.jpg'
                  : masseuseDetails?.image
              }
              sx={{
                borderRadius: '15px',
              }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={6}
          p={1}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography variant="body2">
            {masseuseDetails
              ? 'Your session with'
              : 'Masseuse is no longer with club dynasty'}
          </Typography>
          <Typography variant="h2" component="div" gutterBottom>
            {masseuseDetails && (
              <Link to={`/masseuse/${masseuseDetails.id}`}>
                {masseuseDetails.name.toUpperCase()}
              </Link>
            )}
          </Typography>
          {!ownRating ? null : (
            <div>
              <div>
                <h4
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    marginBottom: '0px',
                    textAlign: 'left',
                  }}
                >
                  Overall:{' '}
                  {ownRating && masseuseDetails
                    ? `${masseuseDetails?.survey_average}/10 (${masseuseDetails?.survey_count})`
                    : 'N/A'}
                </h4>{' '}
                <Rating
                  readOnly
                  max={10}
                  precision={0.1}
                  value={masseuseDetails?.survey_average}
                  size="small"
                  sx={{
                    fontSize: '14px',
                    marginBottom: '5px',
                  }}
                />{' '}
              </div>
              <div>
                <h4
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    marginBottom: '0px',
                  }}
                >
                  You: {ownRating ? `${ownRating.survey_average}/10` : 'N/A'}
                </h4>{' '}
                <Rating
                  readOnly
                  max={10}
                  precision={0.1}
                  value={ownRating?.survey_average}
                  size="small"
                  sx={{
                    fontSize: '14px',
                  }}
                />
              </div>
            </div>
          )}
          {masseuseDetails
            ? (!ownRating || (ownRating && !ownRating.survey_average)) && (
                <Button
                  onClick={(e) => handleSurveyNavigate(session_id)}
                  variant="contained"
                  disableElevation
                  size="small"
                  endIcon={<StarRateIcon />}
                >
                  Rate
                </Button>
              )
            : null}
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default SessionMasseuses;
