export const masseuseAvailabilityChecker = (
  masseusesAvailability,
  masseuseId
) => {
  if (masseusesAvailability && masseusesAvailability.isLoading === false)
    return masseusesAvailability.availableMasseuses.some(
      (item) => item.id === masseuseId
    );
  else return false;
};
