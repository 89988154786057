import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import { register } from '../actions/userActions';
import { setAlert } from '../actions/alertActions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MuiPhoneNumber from 'material-ui-phone-number';
import getErrorsForProperty from '../utils/getErrorsForProperty';

const RegisterScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const userLogin = useSelector((state) => state.userLogin);
  // const { loading, error, userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, success, error } = userRegister;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  }, [success]);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const [values, setValues] = React.useState({
    name: '',
    email: '',
    phone: null,
    dob: null,
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleDobChange = (newValue) => {
    setValues({ ...values, dob: newValue });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (values.password !== values.confirmPassword) {
      dispatch(setAlert('Passwords do not match', 'warning'));
    } else {
      dispatch(
        register(
          values.name,
          values.email,
          values.phone,
          values.dob,
          values.password
        )
      );
    }
  };

  const handlePhoneChange = (phone) => {
    setValues({ ...values, phone });
  };

  return (
    <div
      style={{
        background:
          'url(https://clubdynasty.ca/wp-content/uploads/2020/07/VIP-min-scaled-e1595532639186.jpg) center center no-repeat',
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Container
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} className="grid-center">
            <Card
              sx={{ minWidth: 275, background: 'rgba(255,255,255,0.9)' }}
              variant="outlined"
            >
              <CardContent style={{ textAlign: 'center', padding: '30px' }}>
                <Typography variant="h2" gutterBottom>
                  Signup Below To See Our Gorgeous Masseuses
                </Typography>
                <form onSubmit={(e) => onSubmit(e)}>
                  <TextField
                    variant="outlined"
                    type="text"
                    value={values.name}
                    onChange={handleChange('name')}
                    label="Name"
                    style={{ width: '100%', marginBottom: '20px' }}
                    size="small"
                    required
                    error={error && error.hasOwnProperty('name')}
                    helperText={getErrorsForProperty('name', error)}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    value={values.email}
                    onChange={handleChange('email')}
                    label="Email"
                    style={{ width: '100%', marginBottom: '20px' }}
                    size="small"
                    required
                    error={error && error.hasOwnProperty('email')}
                    helperText={getErrorsForProperty('email', error)}
                  />

                  <MuiPhoneNumber
                    variant="outlined"
                    size="small"
                    label="Phone"
                    style={{ width: '100%', marginBottom: '20px' }}
                    value={values.phone}
                    onChange={handlePhoneChange}
                    disableCountryCode={true}
                    disableAreaCodes={true}
                    disableDropdown={true}
                    defaultCountry="ca"
                    onlyCountries={['ca']}
                    error={error && error.hasOwnProperty('phone')}
                    helperText={getErrorsForProperty('phone', error)}
                  />

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="MM/dd/yyyy"
                      label="Date of Birth"
                      value={values.dob}
                      onChange={handleDobChange}
                      disableFuture
                      error
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          sx: {
                            marginBottom: '20px',
                          },
                          error: error && error.hasOwnProperty('dob'),
                          helperText: getErrorsForProperty('dob', error),
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Password"
                    error={error && error.hasOwnProperty('password')}
                    helperText={getErrorsForProperty('password', error)}
                    required
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginBottom: '10px' }}
                    type={values.showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Confirm Password"
                    error={error && error.hasOwnProperty('password')}
                    helperText={getErrorsForProperty('password', error)}
                    required
                  />

                  <small style={{ marginBottom: '10px', display: 'block' }}>
                    * marked fields are required
                  </small>

                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type="submit"
                  >
                    Register
                    {loading && (
                      <>
                        &nbsp;
                        <CircularProgress
                          color="white"
                          size={20}
                          thickness={5}
                        />
                      </>
                    )}
                  </Button>
                  {/* {error && Array.isArray(error) && (
                    <Alert
                      severity="error"
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      {error.join(', ')}
                    </Alert>
                  )} */}
                </form>
                <Typography variant="p">
                  Already have an account?{' '}
                  <Link to="/login" style={{ textDecoration: 'underline' }}>
                    Login
                  </Link>{' '}
                  | Forgot password?{' '}
                  <Link to="/reset" style={{ textDecoration: 'underline' }}>
                    Reset
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {msg?.length > 0 && <Message />}
      </Container>{' '}
    </div>
  );
};

export default RegisterScreen;
