import { Avatar } from '@mui/material';
import React, { useState, useEffect } from 'react';

function ImageComponent({ token, imageUrl, alt, width, height }) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    fetch(imageUrl, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (isMounted) {
          // Only proceed if component is still mounted
          const objectURL = URL.createObjectURL(blob);
          setImageSrc(objectURL);

          // Cleanup function for revoking the object URL
          return () => {
            URL.revokeObjectURL(objectURL);
          };
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // Cleanup function for the useEffect hook
    return () => {
      isMounted = false; // Indicate the component has been unmounted
    };
  }, [imageUrl, token]); // Include dependencies here

  return (
    <div>
      {imageSrc && (
        <Avatar
          src={imageSrc}
          alt={alt}
          sx={{ width: width, height: height }}
        />
      )}
    </div>
  );
}

export default ImageComponent;
