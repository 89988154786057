import {
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_EMAIL_VERIFY_FAIL,
  USER_EMAIL_VERIFY_REQUEST,
  USER_EMAIL_VERIFY_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_RESET,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_RESET_CONFIRM_REQUEST,
  USER_PASSWORD_RESET_CONFIRM_RESET,
  USER_PASSWORD_RESET_CONFIRM_SUCCESS,
  USER_PASSWORD_RESET_CONFIRM_FAIL,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_CODE_FAIL,
  USER_PASSWORD_RESET_VERIFY_CODE_REQUEST,
  USER_PASSWORD_RESET_VERIFY_CODE_RESET,
  USER_PASSWORD_RESET_VERIFY_CODE_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL,
  USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST,
  USER_PASSWORD_RESET_VERIFY_EMAIL_RESET,
  USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
  USER_ADD_POINT,
  USER_EMAIL_VERIFY_CONFIRM_REQUEST,
  USER_EMAIL_VERIFY_CONFIRM_SUCCESS,
  USER_EMAIL_VERIFY_CONFIRM_FAIL,
  USER_PHONE_VERIFY_REQUEST,
  USER_PHONE_VERIFY_FAIL,
  USER_PHONE_VERIFY_SUCCESS,
  USER_PHONE_VERIFY_CONFIRM_REQUEST,
  USER_PHONE_VERIFY_CONFIRM_SUCCESS,
  USER_PHONE_VERIFY_CONFIRM_FAIL,
  USER_EMAIL_VERIFY_RESET,
  USER_EMAIL_VERIFY_CONFIRM_RESET,
  USER_PHONE_VERIFY_RESET,
  USER_PHONE_VERIFY_CONFIRM_RESET,
  USER_PREFERENCE_GET_REQUEST,
  USER_PREFERENCE_GET_SUCCESS,
  USER_PREFERENCE_GET_FAIL,
  USER_PREFERENCE_GET_RESET,
  USER_PREFERENCE_UPDATE_REQUEST,
  USER_PREFERENCE_UPDATE_SUCCESS,
  USER_PREFERENCE_UPDATE_FAIL,
  USER_PREFERENCE_UPDATE_RESET,
  USER_TRANSACTION_LIST_REQUEST,
  USER_TRANSACTION_LIST_SUCCESS,
  USER_TRANSACTION_LIST_FAIL,
  USER_TRANSACTION_LIST_RESET,
  USER_PASSWORD_RESET_VERIFY_REQUEST,
  USER_PASSWORD_RESET_VERIFY_SUCCESS,
  USER_PASSWORD_RESET_VERIFY_FAIL,
  USER_PASSWORD_RESET_VERIFY_RESET,
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EMAIL_VERIFY_REQUEST:
      return { loading: true };
    case USER_EMAIL_VERIFY_SUCCESS:
      return {
        loading: false,
        token: action.payload.token,
        message: action.payload.message,
        success: true,
      };
    case USER_EMAIL_VERIFY_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_EMAIL_VERIFY_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyEmailConfirmReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_EMAIL_VERIFY_CONFIRM_REQUEST:
      return { loading: true };
    case USER_EMAIL_VERIFY_CONFIRM_SUCCESS:
      return { loading: false, message: action.payload.message, success: true };
    case USER_EMAIL_VERIFY_CONFIRM_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_EMAIL_VERIFY_CONFIRM_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyPhoneReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PHONE_VERIFY_REQUEST:
      return { loading: true };
    case USER_PHONE_VERIFY_SUCCESS:
      return {
        loading: false,
        token: action.payload.token,
        message: action.payload.message,
        success: true,
      };
    case USER_PHONE_VERIFY_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PHONE_VERIFY_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyPhoneConfirmReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PHONE_VERIFY_CONFIRM_REQUEST:
      return { loading: true };
    case USER_PHONE_VERIFY_CONFIRM_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
        success: true,
      };
    case USER_PHONE_VERIFY_CONFIRM_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PHONE_VERIFY_CONFIRM_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetVerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_VERIFY_EMAIL_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_RESET_VERIFY_EMAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetVerifyCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_VERIFY_CODE_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_VERIFY_CODE_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_PASSWORD_RESET_VERIFY_CODE_FAIL:
      return { loading: false, error: action.payload };
    case USER_PASSWORD_RESET_VERIFY_CODE_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PASSWORD_RESET_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_VERIFY_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_VERIFY_SUCCESS:
      return { loading: false, response: action.payload, success: true };
    case USER_PASSWORD_RESET_VERIFY_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PASSWORD_RESET_VERIFY_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordResetConfirmReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_CONFIRM_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_CONFIRM_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case USER_PASSWORD_RESET_CONFIRM_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PASSWORD_RESET_CONFIRM_RESET:
      return {};
    default:
      return state;
  }
};

export const userPasswordChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_CHANGE_REQUEST:
      return { loading: true };
    case USER_PASSWORD_CHANGE_SUCCESS:
      return { loading: false, detail: action.payload, success: true };
    case USER_PASSWORD_CHANGE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_PASSWORD_CHANGE_RESET:
      return {};
    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_ADD_POINT:
      return { loading: false, user: action.payload };
    case USER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userPreferenceReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PREFERENCE_GET_REQUEST:
      return { loading: true };
    case USER_PREFERENCE_GET_SUCCESS:
      return { loading: false, success: true, preference: action.payload };
    case USER_PREFERENCE_GET_FAIL:
      return { loading: false, error: action.payload };
    case USER_PREFERENCE_GET_RESET:
      return {};
    default:
      return state;
  }
};

export const userPreferenceUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PREFERENCE_UPDATE_REQUEST:
      return { loading: true };
    case USER_PREFERENCE_UPDATE_SUCCESS:
      return { loading: false, success: true, preference: action.payload };
    case USER_PREFERENCE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_PREFERENCE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const userTransactionsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_TRANSACTION_LIST_REQUEST:
      return { ...state, loading: true };
    case USER_TRANSACTION_LIST_SUCCESS:
      return { loading: false, transactions: action.payload };
    case USER_TRANSACTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_TRANSACTION_LIST_RESET:
      return {};
    default:
      return state;
  }
};
