import {
  FEEDBACK_CREATE_FAIL,
  FEEDBACK_CREATE_REQUEST,
  FEEDBACK_CREATE_RESET,
  FEEDBACK_CREATE_SUCCESS,
  FEEDBACK_DELETE_FAIL,
  FEEDBACK_DELETE_REQUEST,
  FEEDBACK_DELETE_RESET,
  FEEDBACK_DELETE_SUCCESS,
  FEEDBACK_LIST_FAIL,
  FEEDBACK_LIST_REMOVE_ITEM,
  FEEDBACK_LIST_REQUEST,
  FEEDBACK_LIST_SUCCESS,
  FEEDBACK_UPDATE_FAIL,
  FEEDBACK_UPDATE_REQUEST,
  FEEDBACK_UPDATE_RESET,
  FEEDBACK_UPDATE_SUCCESS,
} from '../constants/feedbackConstants';

export const feedbackListReducer = (state = {}, action) => {
  switch (action.type) {
    case FEEDBACK_LIST_REQUEST:
      return { loading: true };
    case FEEDBACK_LIST_SUCCESS:
      return { loading: false, feedbacks: action.payload };
    case FEEDBACK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_LIST_REMOVE_ITEM:
      return {
        loading: false,
        feedbacks: {
          first: state.feedbacks.first,
          total: state.feedbacks.total,
          last: state.feedbacks.last,
          current: state.feedbacks.current,
          results: state.feedbacks.results.filter(
            (feedback) => feedback.id !== action.payload
          ),
          next: state.feedbacks.next,
        },
      };
    default:
      return state;
  }
};

export const feedbackCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FEEDBACK_CREATE_REQUEST:
      return { loading: true };
    case FEEDBACK_CREATE_SUCCESS:
      return { loading: false, feedback: action.payload };
    case FEEDBACK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const feedbackUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case FEEDBACK_UPDATE_REQUEST:
      return { loading: true };
    case FEEDBACK_UPDATE_SUCCESS:
      return { loading: false, feedback: action.payload };
    case FEEDBACK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const feedbackDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FEEDBACK_DELETE_REQUEST:
      return { loading: true };
    case FEEDBACK_DELETE_SUCCESS:
      return { loading: false, feedback: action.payload };
    case FEEDBACK_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
