export const isVideo = (url) => {
  const videoExtensions = [
    '.mp4',
    '.webm',
    '.avi',
    '.mov',
    '.flv',
    '.wmv',
    '.mkv',
    '.m3u8',
  ];

  // Check if the url is a string and not falsy (like null, undefined, etc.)
  if (typeof url === 'string' && url) {
    return videoExtensions.some((extension) => url.endsWith(extension));
  }

  return false; // Return false if url is not a string or is falsy
};
