import { Card, CardContent, CardMedia, Grid, Skeleton } from '@mui/material';
import React from 'react';

const SessionLoaderSkeleton = () => {
  return (
    <Grid container spacing={3}>
      {[...Array(Number(6)).keys()].map((l, i) => (
        <Grid item xs={12} sm={4} key={i}>
          <Card sx={{ borderRadius: '15px' }} variant="outlined">
            <CardMedia>
              <Skeleton variant="rectangular" width="100%" height={300} />
            </CardMedia>
            <CardContent>
              <Skeleton height={50} width="100%" />
              <Skeleton height={50} width="100%" />
              <Skeleton height={50} width="100%" />
              <Skeleton height={50} width="100%" />
              <Skeleton height={50} width="100%" />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default SessionLoaderSkeleton;
