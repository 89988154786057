import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

function useAppointmentSessionTypes(token, masseuseId) {
  const fetchAppointmentSessionTypes = async () => {
    const endpoint = `${API_URI}/appointments/session_types/${masseuseId}`;
    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch masseuse appointment slots');
    }
  };

  const queryKey = ['appointmentSessionTypes', masseuseId];

  const { data, error, isLoading } = useQuery(
    queryKey,
    fetchAppointmentSessionTypes,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    appointmentSessionTypes: data,
    isLoading,
    error,
  };
}

export default useAppointmentSessionTypes;
