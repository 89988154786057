import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

function usePinnedNotifications(token) {
  const fetchPinnedNotifications = async () => {
    const endpoint = `${API_URI}/notifications/?page=1&page_size=100000000000&order=desc`;
    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });

      //   only return pinned notifications, pinned are the ones with response.data.results[i].pinned === true
      response.data.results = response.data.results.filter(
        (notification) => notification.pinned === true
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch pinned notifications');
    }
  };

  const queryKey = ['pinnedNotifications'];

  const { data, error, isLoading } = useQuery(
    queryKey,
    fetchPinnedNotifications,
    {
      cacheTime: 0,
      staleTime: 0,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    pinnedNotifications: data,
    isLoading,
    error,
  };
}

export default usePinnedNotifications;
