import { useEffect, useState } from 'react';
import { Button, CircularProgress, Skeleton, TextField } from '@mui/material';
import {
  createSessionNote,
  deleteSessionNote,
  updateSessionNote,
} from '../actions/sessionActions';
import { useDispatch, useSelector } from 'react-redux';
import Message from './Message';

const Notes = ({
  note = '',
  session,
  noteChanged,
  setNoteChanged,
  isNotesLoading,
}) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState('');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setContent(note);
  }, [note]);

  const sessionNoteUpdate = useSelector((state) => state.sessionNoteUpdate);
  const { loading } = sessionNoteUpdate;

  const sessionNoteCreate = useSelector((state) => state.sessionNoteCreate);
  const { loading: loadingCreate, session: noteCreatedForThisSession } =
    sessionNoteCreate;

  const sessionNoteDelete = useSelector((state) => state.sessionNoteDelete);
  const { loading: loadingDelete } = sessionNoteDelete;

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (content.length > 0) {
      dispatch(
        createSessionNote(session.id, content, noteChanged, setNoteChanged)
      );
    } else window.alert('Note cannot be empty!');
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (content.length > 0) {
      dispatch(
        updateSessionNote(
          session,
          content,
          setEdit,
          noteChanged,
          setNoteChanged
        )
      );
      //   sessionListRefresh();
    } else window.alert('Note cannot be empty!');
  };

  const onDelete = () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      dispatch(deleteSessionNote(session, noteChanged, setNoteChanged));
    }
  };

  return (
    <>
      {note.length > 0 ? (
        <>
          {edit ? (
            <form onSubmit={(e) => onUpdate(e)}>
              <TextField
                label="Add note"
                multiline
                rows={3}
                fullWidth
                inputProps={{ style: { fontSize: 14 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                size="small"
                style={{ marginBottom: '10px' }}
                value={content}
                onChange={handleChange}
                required
              />

              <Button
                size="small"
                variant="outlined"
                disableElevation
                onClick={(e) => setEdit(false)}
                style={{ marginRight: '5px' }}
              >
                Cancel
              </Button>

              <Button
                size="small"
                variant="contained"
                disableElevation
                type="submit"
              >
                Update{' '}
                {loading && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </form>
          ) : (
            <>
              <small style={{ marginBottom: '10px', display: 'block' }}>
                {isNotesLoading ? <Skeleton height={50} width="60%" /> : note}
              </small>
              <Button
                size="small"
                variant="outlined"
                disableElevation
                onClick={(e) => setEdit(true)}
              >
                Edit
              </Button>{' '}
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={() => onDelete()}
              >
                Delete{' '}
                {loadingDelete && (
                  <>
                    &nbsp;
                    <CircularProgress color="white" size={20} thickness={5} />
                  </>
                )}
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          <form onSubmit={(e) => onSubmit(e)}>
            <TextField
              // label={'note'}
              label="Leave a note about this session"
              helperText="Only you can see and edit your notes"
              multiline
              rows={3}
              fullWidth
              inputProps={{ style: { fontSize: 14 } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
              size="small"
              style={{ marginBottom: '10px' }}
              value={content}
              onChange={handleChange}
              required
            />
            <Button
              size="small"
              variant="contained"
              disableElevation
              type="submit"
            >
              Add{' '}
              {loadingCreate && noteCreatedForThisSession === session.id && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </form>
        </>
      )}
    </>
  );
};

export default Notes;
