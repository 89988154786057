import React from 'react';

import { Typography } from '@mui/material';

const RewardTerms = () => {
  return (
    <>
      <Typography variant="h2" component="div" gutterBottom>
        Club Dynasty Rewards Program
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        This page sets out important information about the Club Dynasty Rewards
        program (the Program) – the program that rewards you with free or
        discounted services at Club DYnasty Executive Health Spa
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
        Participating in the Program
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        In order to collect and redeem points, you must become a “V.I.P. member”
        (a Member). Membership is open to you if you are an individual and you
        are 18+ years of age.
        <br />
        You can become a Member by completing the registration process online at
        https://clubdynasty.ca/vip-program/ (the Program Website).
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
        Collecting Club Dynasty Rewards Points
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        Rewards points can be collected when you purchase any massage service at
        our spa. To collect points, you must attach your member account to your
        booking, at the time of the booking. Rewards points can only be
        collected on one member account for each purchase transaction.
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
        Expiration of Rewards Points
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        Rewards points expire after one year. We may expire the rewards points
        in your account in the event that there has been a period of inactivity
        of 18 months or more. For the purposes of this section, “inactivity”
        means that there has been neither a transaction in which you have
        collected points, nor a transaction in which you have redeemed rewards
        points during the period in question.
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
        Termination of Membership and Cancellation of the Program
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        Membership may be terminated by Club Dynasty if any of the following
        events occur: (A) the Member has failed to comply with any of these
        terms and conditions, or Club Dynasty determines that the Member has
        abused the Program or has made any misrepresentation or false statement
        to Club Dynasty; (B) the Member dies; (C) Club Dynasty suspects the
        Member of any fraudulent activity in connection with the Program.
        <br />
        <br />
        A Member may choose to cancel his or her membership by emailing or
        calling the spa at any time during operational hours.
        <br />
        <br />
        Termination or cancellation of membership in the Program will result in
        the immediate closing of the Member's Club Dynasty Account and the
        cancellation of all rewards points in such V.I.P. Member’s Account
        without any compensation or other liability to the Member. Termination
        of a Member's account will also result in the Member no longer being
        able to use the Rewards Program. <br />
        <br />
        Club Dynasty may, in its sole discretion and at any time without prior
        notice either (i) cancel the Program or (ii) establish a date upon which
        rewards points will expire and may no longer be used.
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
        Merging Rewards Accounts
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        At Club Dynasty's discretion, Members may be able to merge their Rewards
        Accounts into a single account. That merged account will bear the
        Rewards Account number of one of the accounts being merged and one
        Member will be designated as the holder of that account with full power
        and authority to deal with the account, including closing it, and will
        become the “Member” hereunder. All Members wishing to merge their
        Rewards Accounts will need to consent to the merger and agree on which
        account number will be designated as the number for the newly merged
        Rewards Account and who the account holder will be. All remaining
        Rewards Account numbers will be canceled and the persons who are not
        designated as the account holder will no longer be Members in the
        Program.
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
        Non-Cash Utility of Rewards Points Clause
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        Rewards points have a specific utility and cannot be used as cash, nor
        can they be utilized to cover tips or upgrade fees.
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        style={{ display: 'block', width: '100%' }}
      >
        Exclusions from Rewards Points Accrual Clause
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        Please note that rewards points cannot be earned on the following:
        <ul>
          <li>Sessions that have been provided free of charge.</li>
          <li>
            Sessions that have been discounted or subject to promotional
            pricing.
          </li>
          <li>Sessions that are part of a prepaid package.</li>
          <li>
            Sessions that are already included in any ongoing promotional
            offers.
          </li>
          <li>
            Sessions by members who have not verified either their email address
            or their phone number on the "my profile" page of the member's area.
          </li>
        </ul>
      </Typography>

      <Typography
        variant="h6"
        component="div"
        gutterBottom
        style={{ display: 'block', width: '100%' }}
      >
        Single Guest Rewards Limitation
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        Each account is eligible to earn rewards points for only one guest
        during a 24-hour period. If two guests check in using the same phone
        number or email address, only one of them will receive points credited
        to the member account.
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        style={{ display: 'block', width: '100%' }}
      >
        General
      </Typography>
      <Typography
        variant="body2"
        component="div"
        gutterBottom
        style={{ marginBottom: '20px' }}
      >
        Rewards points are not exchangeable and cannot be redeemed for cash or
        made subject to any security interest. Rewards points cannot be
        transferred from a Member to any other Member or other person without
        the consent of Club Dynasty.
        <br />
        <br />
        These terms and conditions, as amended by Club Dynasty from time to
        time, constitute the entire agreement between the Member and Club
        Dynasty.
        <br />
        <br />
        On occasion, Club Dynasty, our various businesses within the Club
        Dynasty family of companies and any Partners may communicate special
        offers, information and services to Members by email, text message
        (standard text messaging and data rates may apply) or other
        communication preferences selected by you using the contact information
        you have provided. Any Member who does not wish to receive these offers
        may so indicate by emailing or calling the spa directly. Please note
        that even if you have opted out of receiving marketing communications,
        we may still contact you for the purposes of administering your account
        and sending you transactional or operational messages. <br />
        <br />
        Any waiver by Club Dynasty of any of these terms and conditions at any
        time does not mean that Club Dynasty cannot rely on these terms and
        conditions at any subsequent time. No delay or omission by Club Dynasty
        in exercising any right or remedy hereunder shall operate as waiver
        thereof or of any other right or remedy.
        <br />
        <br />
        The Member is responsible for informing Club Dynasty of any change to
        his or her personal information (e.g. name, phone number, email, etc.),
        by visiting the Program Website or by calling the spa directly. Club
        Dynasty reserves the right to terminate a Member's participation in the
        Program or to block a Member's ability to use the Member's rewards
        points if Club Dynasty has inaccurate or incomplete information
        respecting the Member.
        <br />
        <br />
        Enrolment in the Program constitutes acceptance of these terms and
        conditions and each applicant for membership in the Program consents to
        the collection and use of personal information in accordance with the
        Club Dynasty Privacy Charter, as amended from time to time. If any
        provision of these terms and conditions is invalid or unenforceable,
        such provision will not affect the validity and enforceability of any of
        the remaining terms and conditions.
        <br />
        <br />
        We may cancel any rewards points that have not been properly issued or
        that we believe have been issued due to fraudulent conduct or conduct
        that is inconsistent with these terms and conditions or the spirit of
        the Program.
        <br />
        <br />
        These terms and conditions are governed by the laws of Ontario and the
        federal laws of Canada applicable in Ontario.
      </Typography>
    </>
  );
};

export default RewardTerms;
