import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  styled,
} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SHIATSU_IMAGE } from '../constants/appConstants';
import techniqueChecker from '../utils/techniqueChecker';
import AppointmentBookingAccordion from './AppointmentBookingAccordion';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AppointmentBookingMasseuse = ({ masseuse, token }) => {
  return (
    <Grid key={masseuse.id} item xs={12} sm={6}>
      <Card variant="outlined">
        <CardHeader
          avatar={
            <Link to={`/masseuse/${masseuse.id}?source=appointment`}>
              <Avatar
                aria-label={masseuse.name}
                src={
                  techniqueChecker(masseuse.technique)
                    ? masseuse.images[0]
                    : SHIATSU_IMAGE
                }
              >
                {masseuse.name.toUpperCase()[0]}
              </Avatar>
            </Link>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Link to={`/masseuse/${masseuse.id}?source=appointment`}>
              {masseuse.name}
            </Link>
          }
          subheader={`${masseuse.technique} - ${masseuse.background}`}
        />
        {techniqueChecker(masseuse.technique) ? (
          <Carousel>
            {masseuse.images?.map((m, i) => (
              <Carousel.Item key={i} interval={null}>
                <CardMedia
                  component="img"
                  height="400"
                  sx={{ objectFit: 'contain' }}
                  image={m}
                  alt={masseuse.name}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <CardMedia
            component="img"
            height="400"
            sx={{ objectFit: 'contain' }}
            image={SHIATSU_IMAGE}
            alt={masseuse.name}
          />
        )}
        <AppointmentBookingAccordion masseuseId={masseuse.id} token={token} />
      </Card>
    </Grid>
  );
};

export default AppointmentBookingMasseuse;
