import React from 'react';
import { Stack, Skeleton } from '@mui/material';

const GalleryLoaderSkeleton = () => {
  return (
    <Stack direction={'row'} spacing={1}>
      <Skeleton variant="rectangular" width={300} height={300} />
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={95} height={95} />
        <Skeleton variant="rectangular" width={95} height={95} />
        <Skeleton variant="rectangular" width={95} height={95} />
      </Stack>
    </Stack>
  );
};

export default GalleryLoaderSkeleton;
