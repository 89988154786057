import {
  Avatar,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { format } from 'date-fns';
import SessionMasseuse from './SessionMasseuse';
import SessionMasseuses from './SessionMasseuses';

const SessionCard = ({ session, token, ownRatings }) => {
  return (
    <>
      {session.masseuses.length === 1 && (
        <SessionMasseuse
          masseuse={session.masseuses[0]}
          token={token}
          ownRating={ownRatings?.find(
            (rating) => rating.id === session.masseuses[0]
          )}
          session_id={session.id}
        />
      )}
      {session.masseuses.length > 1 &&
        session.masseuses.map((masseuse, index) => (
          <div key={index}>
            <SessionMasseuses
              masseuse={masseuse}
              token={token}
              ownRating={ownRatings?.find((rating) => rating.id === masseuse)}
              session_id={session.id}
            />
            {index < session.masseuses.length - 1 && <Divider />}
          </div>
        ))}

      <CardContent
        sx={{
          paddingTop: '0px !important',
        }}
      >
        <Divider />
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            color: '#C48A2D',
            fontFamily: 'Barlow',
            fontWeight: '600',
            fontSize: '1.5rem',
            marginBottom: '0px',
            marginTop: '10px',
          }}
        >
          Session: {session.session_type.name}
        </Typography>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            marginBottom: '10px',
          }}
        >
          <ListItem
            sx={{
              padding: '0px',
              marginBottom: '10px',
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: '#F3E8D7',
                }}
              >
                <EmojiEventsIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={session.points} secondary="Points" />
          </ListItem>
          <ListItem
            sx={{
              padding: '0px',
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: '#F3E8D7',
                }}
              >
                <AccessTimeIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={format(new Date(session.start_time), 'PP')}
              secondary={`${format(new Date(session.start_time), 'p')} - 
                    ${format(new Date(session.end_time), 'p')}`}
            />
          </ListItem>
        </List>

        <Divider />
      </CardContent>
    </>
  );
};

export default SessionCard;
