import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Pagination,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionList } from '../actions/transactionActions';
import { v4 as uuid } from 'uuid';
import Meta from '../components/Meta';

const TransactionListScreen = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const transactionList = useSelector((state) => state.transactionList);
  const { loading, transactions } = transactionList;

  useEffect(() => {
    if (!transactions) {
      dispatch(getTransactionList(page, pageSize));
    }
  }, [page, pageSize]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container>
      <Meta
        title={'Transactions'}
        description={'List of your transactions'}
        keywords={
          'transactions, club dynasty transactions, club dynasty members area, vip members'
        }
      />
      <Grid container spacing={1} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Transactions
          </Typography>
        </Grid>
        {loading ? (
          <Grid item className="transaction-container" xs={12}>
            Loading... <br /> <br />
            <LinearProgress />
          </Grid>
        ) : transactions?.results?.length > 0 ? (
          <>
            {transactions?.results?.map((transaction) => (
              <Grid item className="transaction-container" xs={12} key={uuid()}>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {format(new Date(transaction.created), 'MMMM dd, yyyy')}
                </Typography>
                <Typography variant="h5" component="div">
                  {transaction.points} points
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Owner: {transaction.owner} &nbsp; Session:{' '}
                  {transaction.session}
                </Typography>
                <Typography variant="button">
                  {transaction.credit && 'Credit'}
                </Typography>
              </Grid>
            ))}
            <div style={{ marginTop: '16px' }}>
              <Pagination
                count={transactions.total}
                page={page}
                color="primary"
                onChange={handleChange}
              />
            </div>
          </>
        ) : (
          <Grid
            item
            className="transaction-container"
            xs={12}
            style={{ width: '100%' }}
          >
            No transactions available
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default TransactionListScreen;
