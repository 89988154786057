import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { API_URI } from '../constants/appConstants';

function useNotifications(token, notificationRead) {
  const fetchNotifications = async ({ pageParam = 1 }) => {
    const endpoint = `${API_URI}/notifications/?page=${pageParam}&page_size=10&order=desc`;

    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch masseuse details');
    }
  };

  return useInfiniteQuery(
    ['notifications', notificationRead],
    fetchNotifications,
    {
      // Function to get the next page number
      getNextPageParam: (lastPage) => lastPage.next ?? false,
      cacheTime: 0,
      staleTime: 0,
    }
  );
}

export default useNotifications;
