import './VideoCarousel.css';
import React from 'react';
import { Carousel } from 'react-bootstrap';

import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.css';

const VideoCarousel = ({ videos }) => {
  return (
    <div>
      <Carousel style={{ marginBottom: '-15px' }}>
        {videos.map((video, i) => {
          return (
            <Carousel.Item key={i}>
              <ReactPlayer
                url={video}
                controls={true}
                playing={false}
                volume={0.5}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
                width="100%"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default VideoCarousel;
