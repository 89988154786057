import axios from 'axios';
import { useQuery } from 'react-query';

function useSurveyQuestions(token) {
  const fetchSurveyQuestions = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/questions/`;
    try {
      const response = await axios.get(
        endpoint,

        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch masseuse details');
    }
  };

  const queryKey = ['surveyQuestions'];

  const { data, error, isLoading } = useQuery(queryKey, fetchSurveyQuestions, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    surveyQuestions: data,
    isLoading,
    error,
  };
}

export default useSurveyQuestions;
