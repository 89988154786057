import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyUserEmailConfirm } from '../actions/userActions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Message from '../components/Message';
import { USER_EMAIL_VERIFY_RESET } from '../constants/userConstants';

const EmailVerifyScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [code, setCode] = useState('');

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails, error: errorDetails } = userDetails;

  const userVerifyEmailConfirm = useSelector(
    (state) => state.userVerifyEmailConfirm
  );
  const {
    loading: loadingVerifyEmailConfirm,
    error: errorVerifyEmailConfirm,
    success,
  } = userVerifyEmailConfirm;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate('/profile');
      }, 2000);
    }
  }, [success]);

  useEffect(() => {
    dispatch({ type: USER_EMAIL_VERIFY_RESET });
    if (searchParams.get('code') && searchParams.get('token')) {
      setCode(searchParams.get('code'));
      dispatch(
        verifyUserEmailConfirm(
          searchParams.get('code'),
          searchParams.get('token')
        )
      );
    }
    if (!searchParams.get('token')) {
      navigate('/profile');
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyUserEmailConfirm(code, searchParams.get('token')));
  };

  return (
    <Container style={{ minHeight: '50vh' }}>
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={2} mt={8}>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>
              Verify Email
            </Typography>
            <Typography variant="p">
              An email with a verification code was just sent to your email:{' '}
              {user?.email}. Please submit the code here to verify your email
              address.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              type={'text'}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              label="Code"
            />
          </Grid>

          <Grid item xs={12}>
            <Link to="/profile">
              <Button
                variant="outlined"
                disableElevation
                style={{ marginRight: '5px' }}
              >
                Back
              </Button>{' '}
            </Link>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              disabled={loadingVerifyEmailConfirm}
            >
              Submit{' '}
              {loadingVerifyEmailConfirm && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default EmailVerifyScreen;
