import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Message from './Message';
import { format } from 'date-fns';
import { deleteFeedback, updateFeedback } from '../actions/feedbackActions';

const Feedback = ({ feedback }) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState('');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setContent(feedback.content);
  }, [feedback]);

  const feedbackUpdate = useSelector((state) => state.feedbackUpdate);
  const { loading } = feedbackUpdate;

  const sessionNoteCreate = useSelector((state) => state.sessionNoteCreate);
  const { loading: loadingCreate } = sessionNoteCreate;

  const sessionNoteDelete = useSelector((state) => state.sessionNoteDelete);
  const { loading: loadingDelete } = sessionNoteDelete;

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (content.length > 0) {
      //   dispatch(createSessionNote(session.id, content));
    } else window.alert('Feedback cannot be empty!');
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (content.length > 0) {
      dispatch(updateFeedback(feedback.id, content, setEdit));
      // sessionListRefresh();
    } else window.alert('Feedback cannot be empty!');
  };

  const onDelete = () => {
    if (
      window.confirm(
        `${feedback.id} Are you sure you want to delete this feedback?`
      )
    ) {
      dispatch(deleteFeedback(feedback.id));
    }
  };

  return (
    <Grid item className="transaction-container" xs={12}>
      {edit ? (
        <form onSubmit={(e) => onUpdate(e)}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {format(new Date(feedback?.created), 'MMMM dd, yyyy')}
          </Typography>
          <TextField
            label="Add note"
            multiline
            rows={3}
            fullWidth
            inputProps={{ style: { fontSize: 14 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
            size="small"
            style={{ marginBottom: '20px', marginTop: '10px' }}
            value={content}
            onChange={handleChange}
            required
          />
          <Button
            size="small"
            variant="outlined"
            disableElevation
            onClick={(e) => setEdit(false)}
            style={{ marginRight: '5px' }}
          >
            Cancel
          </Button>
          &nbsp; &nbsp;
          <Button
            size="small"
            variant="contained"
            disableElevation
            type="submit"
          >
            Update{' '}
            {loading && (
              <>
                &nbsp;
                <CircularProgress color="white" size={20} thickness={5} />
              </>
            )}
          </Button>
        </form>
      ) : (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {format(new Date(feedback?.created), 'MMMM dd, yyyy')}
          </Typography>
          <Typography
            variant="p"
            component="div"
            sx={{ marginTop: '10px', marginBottom: '20px' }}
          >
            {content}
          </Typography>

          <div style={{ marginTop: '10px' }}>
            <Button
              size="small"
              variant="outlined"
              disableElevation
              onClick={(e) => setEdit(true)}
            >
              Edit
            </Button>
            &nbsp; &nbsp;
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={() => onDelete()}
            >
              Delete{' '}
              {/* {loadingDelete && (
                    <>
                      &nbsp;
                      <CircularProgress color="white" size={20} thickness={5} />
                    </>
                  )} */}
            </Button>
          </div>
        </>
      )}
    </Grid>
  );
};

export default Feedback;
