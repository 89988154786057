import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

export default function NotificationPopup({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiPaper-root': {
          // Targeting the Paper component within the Dialog
          backgroundColor: 'black', // Make the background transparent
          backgroundImage: `url('https://clubdynasty.ca/wp-content/uploads/2024/09/schedule_-slider.jpg')`, // Background image URL
          backgroundSize: 'cover', // Cover the entire content area
          backgroundRepeat: 'no-repeat', // Prevent repeating the background
          backgroundPosition: 'center', // Center the background image
        },
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: '#FFF',
          }}
        >
          <Typography
            variant="h3"
            component="div"
            className="masseuse-name"
            style={{
              fontWeight: '800',
            }}
          >
            {"DON'T MISS OUT"}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: '#FFF',
              textAlign: 'center',
            }}
          >
            Stay in the loop with all things Club Dynasty! Get notified about
            exclusive promotions, be the first to know when your favorite
            masseuse is available, and never miss the latest spa news. Plus, get
            reminders for your appointments and updates on our VIP feed filled
            with insider content. Turn on notifications now and stay one step
            closer to relaxation paradise!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Miss out</Button>
          <Link to={'/profile?notification=true'}>
            <Button onClick={handleClose} variant="contained">
              Let's Go!
            </Button>
          </Link>
        </DialogActions>
      </div>
    </Dialog>
  );
}
