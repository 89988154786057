import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails, logout } from '../actions/userActions';

import UserAvatar from './UserAvatar';

import { LiveChatWidget } from '@livechat/widget-react';
import { Badge } from '@mui/material';
import CD_LOGO_130 from '../img/cd-logo-130.png';
import { getNotificationList } from '../actions/notificationActions';

const ResponsiveAppBar = ({ theme, setLogOutClicked }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const notificationList = useSelector((state) => state.notificationList);
  const { loading, error, notifications } = notificationList;

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);

  React.useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getNotificationList());
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    setLogOutClicked(true);
    dispatch(logout());
  };

  const headerButtonStyle = {
    my: 2,
    color: theme.palette.primary.main,
    display: 'block',
    '&:hover': {
      backgroundColor: '#FFF4E5',
    },
  };
  const headerButtonActiveStyle = {
    my: 2,
    color: '#FFF',
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFF4E5',
    },
  };

  const headerMobileLinkStyle = {
    color: theme.palette.primary.main,
  };
  const headerMobileLinkActiveStyle = {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  };

  const [visibility, setVisibility] = React.useState('minimized');

  const chatVisibilityHandler = () => {
    if (visibility === 'minimized') {
      setVisibility('maximized');
    } else {
      setVisibility('minimized');
    }
  };

  const authLinks = (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <Link to={'/masseuses'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/masseuses'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          Masseuses
        </Button>
      </Link>
      <Link to={'/feed'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/feed'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          Live Feed
        </Button>
      </Link>
      <Link to={'/sessions'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/sessions'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          My Sessions
        </Button>
      </Link>
      <Link to={'/'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          My Reward Points
        </Button>
      </Link>
      <Link to={'/feedbacks'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/feedbacks'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          Give Us Feedback
        </Button>
      </Link>
      <Link to={'/profile'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/profile'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          My Profile
        </Button>
      </Link>
      <Link to={'/schedule'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/schedule'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          Schedule
        </Button>
      </Link>
      {/* <Link to={'/partner-offers'}>
        <Button
          onClick={handleCloseNavMenu}
          sx={
            location.pathname === '/partner-offers'
              ? headerButtonActiveStyle
              : headerButtonStyle
          }
        >
          Partner Offers
        </Button>
      </Link> */}
      <Button onClick={() => chatVisibilityHandler()}>
        {visibility === 'maximized' ? 'Close Chat Window' : 'Book Appointment'}
      </Button>
    </Box>
  );

  return (
    <AppBar position="fixed" color="white">
      <LiveChatWidget
        license="11109027"
        visibility={visibility}
        customerName={user ? user.name : ''}
        customerEmail={user ? user.email : ''}
        sessionVariables={{
          name: user ? user.name : '',
          email: user ? user.email : '',
          phone: user ? user.phone : '',
        }}
      />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={'/'}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img
                src="https://clubdynasty.ca/wp-content/uploads/2019/07/logo_dynasty-130x63.png"
                alt="logo"
              />
            </Typography>{' '}
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Link to={'/masseuses'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/masseuses'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">Masseuses</Typography>
                </MenuItem>
              </Link>
              <Link to={'/feed'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/feed'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">Live Feed</Typography>
                </MenuItem>
              </Link>

              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  chatVisibilityHandler();
                }}
                sx={headerMobileLinkStyle}
              >
                Book Appointment
              </MenuItem>
              <Link to={'/sessions'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/sessions'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">My Sessions</Typography>
                </MenuItem>
              </Link>
              <Link to={'/'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">My Reward Points</Typography>
                </MenuItem>
              </Link>
              <Link to={'/feedbacks'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/feedbacks'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">Feedbacks</Typography>
                </MenuItem>
              </Link>

              <Link to={'/profile'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/profile'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">My Profile</Typography>
                </MenuItem>
              </Link>
              <Link to={'/schedule'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/schedule'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">Schedule</Typography>
                </MenuItem>
              </Link>
              {/* <Link to={'/partner-offers'}>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={
                    location.pathname === '/partner-offers'
                      ? headerMobileLinkActiveStyle
                      : headerMobileLinkStyle
                  }
                >
                  <Typography textAlign="center">Partner Offers</Typography>
                </MenuItem>
              </Link> */}
            </Menu>
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <Link to={'/'}>
              <img src={CD_LOGO_130} alt="logo" />{' '}
            </Link>
          </Typography>

          {authLinks}

          <Box sx={{ flexGrow: 0, marginRight: '10px' }}>
            <Link to={'/notifications'}>
              <Tooltip title="Open notifications">
                <IconButton onClick={handleOpenNotificationMenu} sx={{ p: 0 }}>
                  <Badge
                    badgeContent={notifications?.unreadCount}
                    color="primary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Logout">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {user && user.avatar && user.avatar.length > 0 ? (
                  <UserAvatar
                    token={userInfo.token}
                    imageUrl={user.avatar}
                    alt={user.name}
                    width={30}
                    height={30}
                  />
                ) : (
                  <AccountCircleIcon />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
