import CakeIcon from '@mui/icons-material/Cake';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import EmailIcon from '@mui/icons-material/Email';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import PasswordIcon from '@mui/icons-material/Password';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  deleteFeedBlock,
  deleteFeedFavorite,
  getFeedBlockList,
  getFeedFavoriteList,
} from '../actions/feedActions';
import {
  getUserDetails,
  getUserPreferences,
  verifyUserEmail,
  verifyUserPhone,
} from '../actions/userActions';
import BlockedMasseusesTable from '../components/BlockedMasseusesTable';
import FavoritedMasseusesTable from '../components/FavoritedMasseusesTable';
import LastVisit from '../components/LastVisit';
import Message from '../components/Message';
import Meta from '../components/Meta';
import NotificationPreferences from '../components/NotificationPreferences';
import Packages from '../components/Packages';
import ProfileInfoCardLoader from '../components/ProfileScreen/ProfileInfoCardLoader';
import RecommendedMasseusesLoader from '../components/ProfileScreen/RecommendedMasseusesLoader';
import RecommendedMasseuses from '../components/RecommendedMasseuses';
import Sessions from '../components/Sessions';
import SpaPreferences from '../components/SpaPreferences';
import UserAvatar from '../components/UserAvatar';
import { REMOVE_ALERT } from '../constants/alertConstants';
import { API_URI } from '../constants/appConstants';
import {
  USER_EMAIL_VERIFY_CONFIRM_RESET,
  USER_EMAIL_VERIFY_RESET,
  USER_PASSWORD_CHANGE_RESET,
  USER_PHONE_VERIFY_CONFIRM_RESET,
  USER_PHONE_VERIFY_RESET,
  USER_UPDATE_PROFILE_RESET,
} from '../constants/userConstants';
import useImageSrc from '../hooks/useImageSrc';
import useMasseuses from '../hooks/useMasseuses';
import useSessionTypes from '../hooks/useSessionTypes';
import { convertPackageData } from '../utils/convertPackageData';
import { hasActivePackages } from '../utils/hasActivePackages';

const ProfileScreenNew = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);

  const [unblockPopupOpen, setUnblockPopupOpen] = useState(false);
  const [masseuseToUnblock, setMasseuseToUnblock] = useState(null);

  const [unfavoritePopupOpen, setUnfavoritePopupOpen] = useState(false);
  const [masseuseToUnfavorite, setMasseuseToUnfavorite] = useState(null);

  const [blockId, setBlockId] = useState(null);
  const [favoriteId, setFavoriteId] = useState(null);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { data: masseuses, isLoading: isMasseusesLoading } = useMasseuses({
    page: 1,
    page_size: 1000,
    profile_visible: true,
    active: true,
  });

  const config = {
    headers: {
      Authorization: userInfo.token,
    },
  };

  const {
    isLoading: isSessionsLoading,
    error: sessionsError,
    data: sessions,
  } = useQuery(['sessionsForProfileStat'], fetchSessions, {
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });

  async function fetchSessions() {
    const response = await axios.get(
      `${API_URI}/members/sessions/?page=1&page_size=10000&order=desc`,
      config
    );
    return response.data;
  }

  const userVerifyEmail = useSelector((state) => state.userVerifyEmail);
  const {
    loading: loadingVerifyEmail,
    success: successVerifyEmail,
    token: tokenVerifyEmail,
  } = userVerifyEmail;

  const userPasswordChange = useSelector((state) => state.userPasswordChange);
  const { success: successPasswordChange } = userPasswordChange;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success: successUpdateProfile } = userUpdateProfile;

  const userVerifyEmailConfirm = useSelector(
    (state) => state.userVerifyEmailConfirm
  );
  const { success: successVerifyEmailConfirm } = userVerifyEmailConfirm;

  const userVerifyPhone = useSelector((state) => state.userVerifyPhone);
  const {
    loading: loadingVerifyPhone,
    success: successVerifyPhone,
    token: tokenVerifyPhone,
  } = userVerifyPhone;

  const userVerifyPhoneConfirm = useSelector(
    (state) => state.userVerifyPhoneConfirm
  );
  const { success: successVerifyPhoneConfirm } = userVerifyPhoneConfirm;

  const userPreference = useSelector((state) => state.userPreference);
  const { preference, loading: loadingPreference } = userPreference;

  const feedBlockList = useSelector((state) => state.feedBlockList);
  const { feedBlocks, loading: loadingFeedBlocks } = feedBlockList;

  const feedFavoriteList = useSelector((state) => state.feedFavoriteList);
  const { feedFavorites, loading: loadingFeedFavorites } = feedFavoriteList;

  const imageSrc = useImageSrc(userInfo?.token ?? '', user?.avatar ?? '');

  const {
    sessionTypes,
    isLoading: isSessionTypesLoading,
    error: sessionTypesError,
  } = useSessionTypes(userInfo.token);

  useEffect(() => {
    dispatch(getUserPreferences());

    if (successPasswordChange) {
      dispatch({ type: USER_PASSWORD_CHANGE_RESET });
    }

    if (successUpdateProfile) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
    }

    if (successVerifyEmailConfirm) {
      dispatch({ type: USER_EMAIL_VERIFY_CONFIRM_RESET });
    }

    if (successVerifyPhoneConfirm) {
      dispatch({ type: USER_PHONE_VERIFY_CONFIRM_RESET });
    }

    if (msg) {
      dispatch({ type: REMOVE_ALERT });
    }

    dispatch({ type: USER_EMAIL_VERIFY_RESET });
    dispatch({ type: USER_PHONE_VERIFY_RESET });

    // if (!user) {
    dispatch(getUserDetails());
    dispatch(getFeedBlockList());
    dispatch(getFeedFavoriteList());
    // }
  }, []);

  useEffect(() => {
    if (successVerifyEmail) {
      navigate(`/profile/email_confirm?token=${tokenVerifyEmail}`);
    }
  }, [successVerifyEmail]);

  useEffect(() => {
    if (successVerifyPhone) {
      navigate(`/profile/phone_confirm?token=${tokenVerifyPhone}`);
    }
  }, [successVerifyPhone]);

  const onEmailVerify = () => {
    dispatch(verifyUserEmail());
  };

  const onPhoneVerify = () => {
    dispatch(verifyUserPhone());
  };

  const formatPhoneNumber = (phoneNumber) => {
    const parsedPhoneNumber = new parsePhoneNumber(phoneNumber, 'CA');
    return parsedPhoneNumber.formatNational();
  };

  const handleAvatarDialogOpen = () => {
    setAvatarDialogOpen(true);
  };

  const handleAvatarDialogClose = (value) => {
    setAvatarDialogOpen(false);
  };

  const unblockMasseuseFromFeed = (masseuse, block_id) => {
    dispatch(deleteFeedBlock(masseuse, block_id, handleUnblockPopupClose));
  };

  const unfavoriteMasseuseFromFeed = (masseuse, favorite_id) => {
    dispatch(
      deleteFeedFavorite(
        favorite_id,
        setUnfavoritePopupOpen,
        masseuse,
        unfavoritePopupOpen
      )
    );
  };

  const handleUnblockPopupOpen = (masseuse, block_id) => {
    setUnblockPopupOpen(true);
    setMasseuseToUnblock(masseuse);
    setBlockId(block_id);
  };

  const handleUnblockPopupClose = () => {
    setUnblockPopupOpen(false);
    setMasseuseToUnblock(null);
    setBlockId(null);
  };

  const handleUnfavoritePopupOpen = (masseuse, favorite_id) => {
    setUnfavoritePopupOpen(true);
    setMasseuseToUnfavorite(masseuse);
    setFavoriteId(favorite_id);
  };

  const handleUnfavoritePopupClose = () => {
    setUnfavoritePopupOpen(false);
    setMasseuseToUnfavorite(null);
    setFavoriteId(null);
  };

  return (
    <Container>
      <Meta
        title={'Profile'}
        description={
          'VIP member profile Club Dynasty Executive Health Spa & Exotic Massage'
        }
        keywords={
          'profile, club dynasty member profile, club dynasty members area, vip members'
        }
      />
      <Dialog
        open={unblockPopupOpen}
        onClose={handleUnblockPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Unhide ${masseuseToUnblock?.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`If you unhide ${masseuseToUnblock?.name}, you will be able to see posts from her in the feed.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnblockPopupClose}>Cancel</Button>
          <Button
            onClick={() => unblockMasseuseFromFeed(masseuseToUnblock, blockId)}
            autoFocus
          >
            Unhide
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={unfavoritePopupOpen}
        onClose={handleUnfavoritePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Unfavorite ${masseuseToUnfavorite?.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`If you unfavorite ${masseuseToUnfavorite?.name}, you will no longer receive notifications when she posts on feeds or becomes available.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnfavoritePopupClose}>Cancel</Button>
          <Button
            onClick={() =>
              unfavoriteMasseuseFromFeed(masseuseToUnfavorite, favoriteId)
            }
            autoFocus
          >
            Unfavorite
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={4} mt={8}>
        <Grid item xs={12} sm={12} md={4}>
          <Box sx={{ minWidth: '100%' }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: 'poppins',
                fontWeight: 500,
                color: '#1c1c1c',
              }}
            >
              My Profile
            </Typography>

            {loadingDetails ? (
              <ProfileInfoCardLoader />
            ) : (
              user && (
                <>
                  <Card
                    variant="outlined"
                    style={{
                      border: '1px solid #c2872b66',
                      borderRadius: '15px',
                      paddingTop: '20px',
                    }}
                    className="profile-card-shadow"
                  >
                    <CardContent>
                      <Grid container spacing={1} mb={1}>
                        <Grid
                          item
                          xs={12}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <div
                            onClick={user.avatar && handleAvatarDialogOpen}
                            style={{
                              cursor: 'pointer',
                              backgroundColor: '#C48A2D',
                              padding: '5px',
                              width: '155px',
                              height: '155px',
                              borderRadius: '50%',
                            }}
                          >
                            <UserAvatar
                              token={userInfo.token}
                              imageUrl={user.avatar}
                              alt={user.name}
                              width={145}
                              height={145}
                              onClick={user.avatar && handleAvatarDialogOpen}
                            />
                          </div>

                          <Dialog
                            onClose={handleAvatarDialogClose}
                            open={avatarDialogOpen}
                          >
                            <IconButton
                              aria-label="close"
                              onClick={handleAvatarDialogClose}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                            <img src={imageSrc} alt="" />
                          </Dialog>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <Typography
                            variant="h5"
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            {user.name}
                          </Typography>
                          <Link to="/rewards">
                            <Typography
                              sx={{
                                fontSize: 14,
                                marginTop: '5px',
                              }}
                              // color="primary"
                              gutterBottom
                            >
                              <EmojiEventsIcon /> Points {user.points}
                            </Typography>
                          </Link>

                          <Typography
                            sx={{ mb: 1.5, marginTop: '5px' }}
                            color="text.secondary"
                          >
                            VIP Member
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <List
                            dense={true}
                            sx={{
                              width: '100%',
                            }}
                          >
                            <ListItem
                              secondaryAction={
                                user.email !== null &&
                                (!user.email_verified ? (
                                  <Tooltip title="Email not verified, click to verify">
                                    <IconButton
                                      edge="end"
                                      aria-label="verifyEmail"
                                      onClick={() => onEmailVerify()}
                                    >
                                      <WarningIcon color="warning" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Email verified">
                                    <IconButton
                                      edge="end"
                                      aria-label="verifyEmail"
                                    >
                                      <CheckCircleIcon color="success" />
                                    </IconButton>
                                  </Tooltip>
                                ))
                              }
                            >
                              <ListItemAvatar
                                sx={{
                                  minWidth: '2rem',
                                }}
                              >
                                <EmailIcon color="primary" />
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  user.email === null
                                    ? 'Email not provided'
                                    : user.email
                                }
                              />
                            </ListItem>
                            <Divider />
                            <ListItem
                              secondaryAction={
                                !user.phone_verified ? (
                                  <Tooltip
                                    title={
                                      !user.phone
                                        ? 'Update your profile and provide a phone number to receive exciting offers right to your phone.'
                                        : 'Phone not verified, click to verify'
                                    }
                                  >
                                    <IconButton
                                      edge="end"
                                      aria-label="verifyPhone"
                                      onClick={() =>
                                        user.phone && onPhoneVerify()
                                      }
                                    >
                                      <WarningIcon color="warning" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Phone verified">
                                    <IconButton
                                      edge="end"
                                      aria-label="verifyPhone"
                                    >
                                      <CheckCircleIcon color="success" />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            >
                              <ListItemAvatar
                                sx={{
                                  minWidth: '2rem',
                                }}
                              >
                                <PhoneIcon color="primary" />
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${
                                  user.phone === null
                                    ? 'Phone not provided'
                                    : formatPhoneNumber(user.phone)
                                }`}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemAvatar
                                sx={{
                                  minWidth: '2rem',
                                }}
                              >
                                <CakeIcon color="primary" />
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${
                                  user.dob === null
                                    ? 'Date of birth not provided'
                                    : user.dob
                                }`}
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px',
                        marginBottom: '1.5rem',
                      }}
                    >
                      <Link to="/profile/update">
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: '10px',
                          }}
                        >
                          Update Info &nbsp; <CreateIcon />
                        </Button>
                      </Link>
                      <Link to="/profile/change-password">
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                        >
                          Change Password &nbsp; <PasswordIcon />
                        </Button>
                      </Link>
                    </CardActions>
                  </Card>
                  {sessionTypes &&
                    user &&
                    user.packages &&
                    hasActivePackages(user.packages) && (
                      <Packages
                        packages={convertPackageData(
                          user.packages,
                          sessionTypes
                        )}
                      />
                    )}

                  {!isSessionsLoading && sessions?.results?.length > 0 && (
                    <LastVisit lastSession={sessions.results[0]} />
                  )}
                  {!isSessionsLoading && sessions?.results?.length > 0 && (
                    <Sessions sessions={sessions.results.length} />
                  )}
                </>
              )
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Grid container spacing={4}>
            <Grid item xs={12} id="notificationPreferences">
              <NotificationPreferences />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SpaPreferences />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontFamily: 'poppins',
                  fontWeight: 500,
                  color: '#1c1c1c',
                }}
              >
                Masseuses
              </Typography>
              <Card
                variant="outlined"
                style={{
                  border: '1px solid #c2872b66',
                  borderRadius: '15px',
                  padding: '20px',
                }}
                className="profile-card-shadow"
              >
                {feedFavorites?.results?.length === 0 &&
                  feedBlocks?.results?.length === 0 && (
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: '#c2872b',
                        fontFamily: 'barlow',
                        fontWeight: 500,
                        fontSize: '1.25rem',
                      }}
                    >
                      Hidden/Favorited Masseuses will appear here
                    </Typography>
                  )}
                {feedBlocks?.results?.length > 0 && (
                  <>
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: '#c2872b',
                        fontFamily: 'barlow',
                        fontWeight: 500,
                        fontSize: '1.25rem',
                      }}
                    >
                      Hidden from feed
                    </Typography>
                    <Divider color="#c2872b" />

                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Masseuse</TableCell>
                            <TableCell align="left">Hidden on</TableCell>
                            <TableCell align="center">Unhide</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loadingFeedBlocks ? (
                            <Box sx={{ width: '100%' }}>
                              <LinearProgress />
                            </Box>
                          ) : (
                            feedBlocks.results.map((block, i) => (
                              <BlockedMasseusesTable
                                key={i}
                                block={block}
                                token={userInfo.token}
                                handleUnblockPopupOpen={handleUnblockPopupOpen}
                              />
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                  </>
                )}

                {feedFavorites?.results?.length > 0 && (
                  <>
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: '#c2872b',
                        fontFamily: 'barlow',
                        fontWeight: 500,
                        fontSize: '1.25rem',
                      }}
                    >
                      Favorited
                    </Typography>
                    <Divider color="#c2872b" />

                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Masseuse</TableCell>
                            <TableCell align="left">Favorited on</TableCell>
                            <TableCell align="center">Unfavorite</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loadingFeedFavorites ? (
                            <Box sx={{ width: '100%' }}>
                              <LinearProgress />
                            </Box>
                          ) : (
                            feedFavorites.results.map((favorite, i) => (
                              <FavoritedMasseusesTable
                                key={i}
                                favorite={favorite}
                                handleUnfavoritePopupOpen={
                                  handleUnfavoritePopupOpen
                                }
                              />
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="h5"
                sx={{
                  fontFamily: 'poppins',
                  fontWeight: 500,
                  color: '#1c1c1c',
                }}
              >
                Recommended Masseuses
              </Typography>

              {isSessionsLoading || isMasseusesLoading ? (
                <RecommendedMasseusesLoader />
              ) : (
                <RecommendedMasseuses
                  sessions={sessions.results}
                  masseuses={masseuses}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default ProfileScreenNew;
