export const APPOINTMENT_LIST_REQUEST = 'APPOINTMENT_LIST_REQUEST';
export const APPOINTMENT_LIST_SUCCESS = 'APPOINTMENT_LIST_SUCCESS';
export const APPOINTMENT_LIST_FAIL = 'APPOINTMENT_LIST_FAIL';
export const APPOINTMENT_LIST_REMOVE_ITEM = 'APPOINTMENT_LIST_REMOVE_ITEM';

export const APPOINTMENT_CREATE_REQUEST = 'APPOINTMENT_CREATE_REQUEST';
export const APPOINTMENT_CREATE_SUCCESS = 'APPOINTMENT_CREATE_SUCCESS';
export const APPOINTMENT_CREATE_FAIL = 'APPOINTMENT_CREATE_FAIL';
export const APPOINTMENT_CREATE_RESET = 'APPOINTMENT_CREATE_RESET';

export const APPOINTMENT_UPDATE_REQUEST = 'APPOINTMENT_UPDATE_REQUEST';
export const APPOINTMENT_UPDATE_SUCCESS = 'APPOINTMENT_UPDATE_SUCCESS';
export const APPOINTMENT_UPDATE_FAIL = 'APPOINTMENT_UPDATE_FAIL';
export const APPOINTMENT_UPDATE_RESET = 'APPOINTMENT_UPDATE_RESET';

export const APPOINTMENT_DELETE_REQUEST = 'APPOINTMENT_DELETE_REQUEST';
export const APPOINTMENT_DELETE_SUCCESS = 'APPOINTMENT_DELETE_SUCCESS';
export const APPOINTMENT_DELETE_FAIL = 'APPOINTMENT_DELETE_FAIL';
export const APPOINTMENT_DELETE_RESET = 'APPOINTMENT_DELETE_RESET';

export const APPOINTMENT_DETAILS_REQUEST = 'APPOINTMENT_DETAILS_REQUEST';
export const APPOINTMENT_DETAILS_SUCCESS = 'APPOINTMENT_DETAILS_SUCCESS';
export const APPOINTMENT_DETAILS_FAIL = 'APPOINTMENT_DETAILS_FAIL';
export const APPOINTMENT_DETAILS_RESET = 'APPOINTMENT_DETAILS_RESET';
