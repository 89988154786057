import axios from 'axios';
import { useQuery } from 'react-query';

function useSessionTypes(token) {
  const fetchSessionTypes = async () => {
    const endpoint = `https://mydynasty.ca/ext/api/v1/surveys/session_types/`;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch session types');
    }
  };

  const queryKey = ['sessionTypes'];

  const { data, error, isLoading } = useQuery(queryKey, fetchSessionTypes, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    sessionTypes: data,
    isLoading,
    error,
  };
}

export default useSessionTypes;
