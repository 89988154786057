import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';

const ReviewUsScreen = () => {
  return (
    <div
      style={{
        marginTop: '1.5rem',
      }}
    >
      <div
        style={{
          backgroundColor: '#F5F6F6',
        }}
      >
        <Container>
          <Grid container className="review-us-hero-container" spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              // alignContent={'center'}
              // className="review-us-hero-container-text"
            >
              <Typography
                variant="h1"
                gutterBottom
                style={{ color: '#000', fontWeight: 'bold' }}
              >
                SUPER FAST, SUPER EASY
              </Typography>
              <Divider />
              <Typography variant="h2" gutterBottom>
                Review Us On Google and Earn a free{' '}
                <span style={{ color: '#000' }}>200 Rewards Points</span> Every
                Month
              </Typography>
              <Typography variant="p">
                At Club Dynasty, your feedback not only helps us grow and
                improve but also offers you great benefits. Join our exciting
                review rewards program designed exclusively for our members!
              </Typography>{' '}
              <br />
              <br />
              <a
                href={'https://g.page/r/CdHj7wMTNB-yEB0/review'}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="contained" disableElevation>
                  Write a review
                </Button>
              </a>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundImage: `url('https://clubdynasty.ca/wp-content/uploads/2024/05/Google_Shape.png')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                padding: '20px',
              }}
            >
              <img
                src="https://clubdynasty.ca/wp-content/uploads/2024/05/review-us-members-area-scaled.jpg"
                alt="Review us"
                width="300"
                style={{ borderRadius: '10px' }}
              />
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.0623590147698!2d-79.35766822263923!3d43.83380494072943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d53e2b8abbf5%3A0xb21f341303efe3d1!2sClub%20Dynasty%20Executive%20Health%20Spa%20%26%20Exotic%20Massage!5e0!3m2!1sen!2sde!4v1715885934547!5m2!1sen!2sde"
                width="400"
                height="280"
                style={{ border: 0, borderRadius: '10px' }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Club Dynasty Executive Health Spa & Exotic Massage"
              ></iframe> */}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            alignContent={'center'}
            justifyContent={'center'}
            style={{
              paddingTop: '50px',
              paddingBottom: '50px',
            }}
          >
            <Typography variant="h1" gutterBottom textAlign={'center'}>
              How It Works
            </Typography>
            <Typography variant="h6" gutterBottom textAlign={'center'}>
              Earning bonus rewards is straightforward and rewarding. Here’s how
              you can participate:
            </Typography>

            <List sx={{ width: '100%' }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>1</Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary="Leave a Review"
                  secondary={
                    <span>
                      <Link
                        href="https://g.page/r/CdHj7wMTNB-yEB0/review"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visit our Google Business Page
                      </Link>{' '}
                      and leave an honest review. Describe your experience and
                      let everyone know what makes Club Dynasty stand out!
                    </span>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>2</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Name on Review"
                  secondary="Email reception@clubdynasty.ca after writing your review with the name used on the review, the date it was posted, and a screenshot of the review."
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>3</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Keep It Live"
                  secondary="Make sure your review stays live on our Google
                  page to keep earning monthly points."
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>4</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Reward Points to Your Account"
                  secondary="Every month that your review stays live, we will automatically credit your account with 200 rewards points."
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          backgroundColor: '#F5F6F6',
          marginBottom: '-50px',
        }}
      >
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              alignContent={'center'}
              justifyContent={'center'}
              style={{
                paddingTop: '50px',
                paddingBottom: '50px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                <img
                  src="https://clubdynasty.ca/wp-content/uploads/2023/11/Banner.png"
                  alt=""
                  style={{
                    maxWidth: isMobile ? '90%' : '50%',
                    height: 'auto',
                    padding: 0,
                    margin: 0,
                  }}
                />
              </div>

              <Typography variant="h1" gutterBottom textAlign={'center'}>
                Why Participate?
              </Typography>

              <List sx={{ width: '100%' }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>1</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Direct Rewards"
                    secondary="Receive points credited directly to your account every month as long as your review stays active."
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>2</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Improve Services"
                    secondary="Your reviews help us enhance our offerings and ensure we meet your needs."
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>3</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Community Impact"
                    secondary=" Assist fellow customers by providing honest insights about their potential experiences with our Spa."
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ReviewUsScreen;
