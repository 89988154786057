import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getUserDetails,
  getUserTransactions,
  userTosRead,
} from '../actions/userActions';
import Message from '../components/Message';
import PointsEarningHistoryTable from '../components/RewardsScreen/PointsEarningHistoryTable';
import RewardTerms from '../components/RewardTerms';
import { massageRewardPoints } from '../data/massageRewardsData';

const RewardsScreen = () => {
  const dispatch = useDispatch();

  const [transactionPageSize, setTransactionPageSize] = useState(5);

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails } = userDetails;

  const userTransactions = useSelector((state) => state.userTransactions);
  const { transactions, loading: loadingTransactions } = userTransactions;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (!user) {
      dispatch(getUserDetails());
    }

    dispatch(getUserTransactions(transactionPageSize));

    // Check if 'rewardTermsOpened' exists and is set to 'true' in localStorage
    // if (localStorage.getItem('rewardTermsOpened') === 'true') {
    //   setOpen(false);
    // } else {
    //   setOpen(true);
    //   // Set 'rewardTermsOpened' in localStorage as 'true' (as a string)
    //   localStorage.setItem('rewardTermsOpened', 'true');
    // }
  }, []);

  useEffect(() => {
    dispatch(getUserTransactions(transactionPageSize));
  }, [transactionPageSize]);

  const handleClose = () => {
    // setOpen(false);
    dispatch(userTosRead());
  };

  return (
    <Container sx={{ minHeight: '60vh' }}>
      <React.Fragment>
        <Dialog open={user ? !user.tos_accepted : false} scroll={'body'}>
          <DialogTitle>
            <Typography>
              Reward Points Terms & Conditions | Scroll Down to Read & Confirm
            </Typography>
          </DialogTitle>
          <DialogContent dividers={true}>
            <RewardTerms />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" disableElevation>
              Agree & Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Grid container spacing={1} mt={9}>
        <Grid item xs={12}>
          {loadingDetails ? (
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={200}
              sx={{ borderRadius: '10px' }}
            />
          ) : (
            <Card
              sx={{
                minWidth: 275,
                background:
                  'url(https://clubdynasty.ca/wp-content/uploads/2024/09/ma_rewards_bg_2.png) no-repeat center center',
                backgroundSize: {
                  xs: 'cover',
                  sm: 'contain',
                },
                textAlign: 'center',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                border: 'none',
              }}
              variant="outlined"
            >
              <CardContent>
                <Typography
                  variant="h1"
                  component="div"
                  style={{
                    fontWeight: '700',
                    fontFamily: 'Barlow',
                    color: '#FFF',
                  }}
                  gutterBottom
                >
                  HI, {user?.name.split(' ')[0].toUpperCase()}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 14,
                    background: '#c3892b',
                    color: '#FFF',
                    padding: '0.2rem 2rem',
                    borderRadius: '3px',
                    fontWeight: '600',
                    fontFamily: 'Barlow',
                  }}
                  as="span"
                  color="primary"
                  gutterBottom
                >
                  Available Points
                </Typography>

                <Typography
                  variant="h2"
                  component="div"
                  className="rewards-points"
                >
                  {user && user.points}
                </Typography>

                <Typography
                  sx={{ mb: 1.5, fontSize: 12, color: '#FFF' }}
                  color="text.secondary"
                >
                  Points are earned with the purchase of each service at our spa
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
        {user &&
          !(user.email_verified === true || user.phone_verified === true) && (
            <Grid item xs={12}>
              <Alert
                severity="warning"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Important Notice: Please{' '}
                <Link
                  to={'/profile'}
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  verify
                </Link>{' '}
                your email or phone number as soon as possible. Effective in the
                near future, members with a non-verified status may lose the
                ability to earn points on future sessions.
              </Alert>
            </Grid>
          )}

        <Grid item xs={12}>
          <Typography
            variant="h6"
            textAlign={'center'}
            sx={{
              color: 'primary.main',
              textTransform: 'uppercase',
            }}
          >
            Points earning history
          </Typography>
          <PointsEarningHistoryTable
            transactions={transactions}
            totalPoints={user?.points}
          />
        </Grid>
        <Grid item xs={12}>
          <Accordion variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How to earn reward points?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} variant="outlined">
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: '#c3892b' }}>
                    <TableRow>
                      <TableCell sx={{ color: '#FFF' }}>Massage</TableCell>
                      <TableCell align="right" sx={{ color: '#FFF' }}>
                        Points Earned
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {massageRewardPoints.map((m) => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                        key={m.massage_name}
                      >
                        <TableCell component="th" scope="row">
                          {m.massage_name}
                        </TableCell>
                        <TableCell align="right">{m.points_earned}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Redeem reward points</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" gutterBottom>
                To redeem your points, inform the receptionist at check-in that
                you would like to use your rewards points for payment.
              </Typography>
              <br />
              <Grid container spacing={2}>
                {massageRewardPoints.map((m) => (
                  <Grid item xs={12} sm={3} key={m.massage_name}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Reward Points
                        </Typography>
                        <Typography variant="h2" component="div">
                          {m.points_cost}
                        </Typography>
                        <Typography
                          sx={{ mb: 1.5, fontSize: 20 }}
                          color="text.secondary"
                        >
                          {m.massage_name}
                        </Typography>
                        <Typography variant="body2">
                          {m.massage_details}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Tooltip
                          title="To redeem your points, inform the receptionist at check-in that you would like to use your rewards points for payment."
                          sx={{
                            cursor: 'pointer',
                            padding: 1,
                          }}
                        >
                          <Typography variant="button" color={'primary'}>
                            Redeem
                          </Typography>
                        </Tooltip>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion variant="outlined">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Reward Points Terms & Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} p={3}>
                <RewardTerms />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default RewardsScreen;
