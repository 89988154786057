import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import {
  TRANSACTION_LIST_FAIL,
  TRANSACTION_LIST_REQUEST,
  TRANSACTION_LIST_SUCCESS,
} from '../constants/transactionConstants';
import { setAlert } from './alertActions';
import * as Sentry from '@sentry/react';

export const getTransactionList =
  (page = '1', page_size = '10') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TRANSACTION_LIST_REQUEST,
      });

      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      let { data } = await axios.get(
        `${API_URI}/transactions/?page=${page}&page_size=${page_size}`,
        config
      );

      dispatch({
        type: TRANSACTION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setAlert(error.response.data.detail, 'error'));

      dispatch({
        type: TRANSACTION_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data,
      });
    }
  };
