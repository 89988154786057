import {
  AVAILABLE_MASSEUSES_LIST_FAIL,
  AVAILABLE_MASSEUSES_LIST_REQUEST,
  AVAILABLE_MASSEUSES_LIST_SUCCESS,
  AVAILABLE_MASSEUSES_LIST_RESET,
  ALL_MASSEUSES_PUBLIC_LIST_FAIL,
  ALL_MASSEUSES_PUBLIC_LIST_REQUEST,
  ALL_MASSEUSES_PUBLIC_LIST_SUCCESS,
  ALL_MASSEUSES_PUBLIC_LIST_RESET,
  ALL_MASSEUSES_LIST_FAIL,
  ALL_MASSEUSES_LIST_REQUEST,
  ALL_MASSEUSES_LIST_SUCCESS,
  ALL_MASSEUSES_LIST_RESET,
  MASSEUSE_RATE_REQUEST,
  MASSEUSE_RATE_SUCCESS,
  MASSEUSE_RATE_FAIL,
  MASSEUSE_RATE_RESET,
  MASSEUSE_DETAILS_REQUEST,
  MASSEUSE_DETAILS_SUCCESS,
  MASSEUSE_DETAILS_FAIL,
  MASSEUSE_DETAILS_RESET,
} from '../constants/masseusesConstants';

export const availableMasseusesListReducer = (state = {}, action) => {
  switch (action.type) {
    case AVAILABLE_MASSEUSES_LIST_REQUEST:
      return { loading: true };
    case AVAILABLE_MASSEUSES_LIST_SUCCESS:
      return { loading: false, availableMasseuses: action.payload };
    case AVAILABLE_MASSEUSES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case AVAILABLE_MASSEUSES_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const allMasseusesPublicListReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_MASSEUSES_PUBLIC_LIST_REQUEST:
      return { loading: true };
    case ALL_MASSEUSES_PUBLIC_LIST_SUCCESS:
      return {
        loading: false,
        masseuses: action.payload.data,
        available: action.payload.available,
      };
    case ALL_MASSEUSES_PUBLIC_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ALL_MASSEUSES_PUBLIC_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const masseuseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case MASSEUSE_DETAILS_REQUEST:
      return { loading: true };
    case MASSEUSE_DETAILS_SUCCESS:
      return {
        loading: false,
        masseuse: action.payload,
      };
    case MASSEUSE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case MASSEUSE_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const allMasseusesListReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_MASSEUSES_LIST_REQUEST:
      return { loading: true };
    case ALL_MASSEUSES_LIST_SUCCESS:
      return {
        loading: false,
        first: action.payload.first,
        total: action.payload.total,
        last: action.payload.last,
        current: action.payload.current,
        masseuses: action.payload.results,
      };
    case ALL_MASSEUSES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ALL_MASSEUSES_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const masseuseRatingReducer = (state = {}, action) => {
  switch (action.type) {
    case MASSEUSE_RATE_REQUEST:
      return { loading: true, masseuse_id: action.payload };
    case MASSEUSE_RATE_SUCCESS:
      return { loading: false, rating: action.payload, success: true };
    case MASSEUSE_RATE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case MASSEUSE_RATE_RESET:
      return {};
    default:
      return state;
  }
};
