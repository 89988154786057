import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import MuiPhoneNumber from 'material-ui-phone-number';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// import LoginIllustration from '../img/Login_Artwork_1.svg';
import { Link, useNavigate } from 'react-router-dom';
import { login, resetPassword } from '../actions/userActions';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import getErrorsForProperty from '../utils/getErrorsForProperty';

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [values, setValues] = useState({
    email: '',
  });

  const [resetMedium, setResetMedium] = useState('email');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, userInfo } = userLogin;

  const userPasswordReset = useSelector((state) => state.userPasswordReset);
  const {
    loading: loadingReset,
    error,
    userInfo: userInfoReset,
    success,
  } = userPasswordReset;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [userInfo]);

  useEffect(() => {
    if (success && resetMedium === 'phone') {
      navigate(`/reset/verify?phone=${phone}`);
    }
  }, [success]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email, phone, resetMedium));
  };

  const handleResetMediumChange = (event) => {
    setResetMedium(event.target.value);
  };

  return (
    <div
      style={{
        background:
          'url(https://clubdynasty.ca/wp-content/uploads/2020/07/VIP-min-scaled-e1595532639186.jpg) center center no-repeat',
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Container
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} className="grid-center">
            <Card
              sx={{ minWidth: 275, background: 'rgba(255,255,255,0.9)' }}
              variant="outlined"
            >
              <CardContent style={{ padding: '30px' }}>
                <Typography variant="h2" gutterBottom textAlign={'center'}>
                  Reset Password
                </Typography>

                <form onSubmit={(e) => onSubmit(e)}>
                  <FormControl style={{ width: '100%', marginBottom: '20px' }}>
                    <FormLabel id="reset-medium-label">Reset using:</FormLabel>
                    <RadioGroup
                      aria-labelledby="reset-medium-label"
                      name="radio-buttons-group"
                      row
                      value={resetMedium}
                      onChange={handleResetMediumChange}
                    >
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email"
                      />
                      <FormControlLabel
                        value="phone"
                        control={<Radio />}
                        label="Phone"
                      />
                    </RadioGroup>
                  </FormControl>
                  {/* <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    style={{ width: '100%', marginBottom: '20px' }}
                    helperText="Enter the email address you used to register your account. We'll send you a password reset link."
                  /> */}

                  {resetMedium === 'email' ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      label="Email"
                      style={{ width: '100%', marginBottom: '20px' }}
                      error={error && error.hasOwnProperty('email')}
                      helperText={getErrorsForProperty('email', error)}
                    />
                  ) : (
                    <MuiPhoneNumber
                      variant="outlined"
                      size="small"
                      label="Phone"
                      style={{ width: '100%', marginBottom: '20px' }}
                      value={phone}
                      onChange={setPhone}
                      disableCountryCode={true}
                      disableAreaCodes={true}
                      disableDropdown={true}
                      defaultCountry="ca"
                      onlyCountries={['ca']}
                      error={error && error.hasOwnProperty('phone')}
                      helperText={getErrorsForProperty('phone', error)}
                    />
                  )}
                  <div style={{ width: '100%', marginBottom: '20px' }}>
                    <small>
                      Enter the email address or phone number used in your
                      account. We'll send you a password reset link/otp.
                    </small>
                  </div>

                  <Button
                    variant="contained"
                    disableElevation
                    // size="small"
                    style={{
                      width: '100%',
                      marginBottom: '20px',
                    }}
                    type="submit"
                  >
                    Reset{' '}
                    {loadingReset && (
                      <>
                        &nbsp;
                        <CircularProgress
                          color="white"
                          size={20}
                          thickness={5}
                        />
                      </>
                    )}
                  </Button>
                </form>
                <Typography variant="p">
                  Don't have an account?{' '}
                  <Link to="/register" style={{ textDecoration: 'underline' }}>
                    Register
                  </Link>{' '}
                  | Already have an account?{' '}
                  <Link to="/login" style={{ textDecoration: 'underline' }}>
                    Login
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {msg?.length > 0 && <Message />}
      </Container>
    </div>
  );
};

export default ResetPasswordScreen;
