import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// import LoginIllustration from '../img/Login_Artwork_1.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  login,
  resetPassword,
  resetPasswordVerify,
} from '../actions/userActions';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import getErrorsForProperty from '../utils/getErrorsForProperty';

const ResetPasswordScreen = ({ history }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, userInfo } = userLogin;

  const userPasswordResetVerify = useSelector(
    (state) => state.userPasswordResetVerify
  );
  const {
    loading: loadingReset,
    error,
    response,
    success,
  } = userPasswordResetVerify;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [history, userInfo]);

  useEffect(() => {
    if (success) {
      navigate(`/reset/confirm/?uid=${response.uid}&token=${response.token}`);
    }
  }, [success]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPasswordVerify(searchParams.get('phone'), otp));
  };

  return (
    <div
      style={{
        background:
          'url(https://clubdynasty.ca/wp-content/uploads/2020/07/VIP-min-scaled-e1595532639186.jpg) center center no-repeat',
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Container
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} className="grid-center">
            <Card
              sx={{ minWidth: 275, background: 'rgba(255,255,255,0.9)' }}
              variant="outlined"
            >
              <CardContent style={{ padding: '30px' }}>
                <Typography variant="h2" gutterBottom textAlign={'center'}>
                  Verify OTP
                </Typography>

                <form onSubmit={(e) => onSubmit(e)}>
                  <TextField
                    variant="outlined"
                    size="small"
                    type={'text'}
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    label="OTP"
                    style={{ width: '100%', marginBottom: '20px' }}
                    error={error && error.hasOwnProperty('phone')}
                    helperText={getErrorsForProperty('phone', error)}
                  />

                  <div style={{ width: '100%', marginBottom: '20px' }}>
                    <small>
                      Enter the otp code you've received in your phone:{' '}
                      {searchParams.get('phone')}
                    </small>
                  </div>

                  <Button
                    variant="contained"
                    disableElevation
                    // size="small"
                    style={{
                      width: '100%',
                      marginBottom: '20px',
                    }}
                    type="submit"
                  >
                    Verify{' '}
                    {loadingReset && (
                      <>
                        &nbsp;
                        <CircularProgress
                          color="white"
                          size={20}
                          thickness={5}
                        />
                      </>
                    )}
                  </Button>
                </form>
                <Typography variant="p">
                  Don't have an account?{' '}
                  <Link to="/register" style={{ textDecoration: 'underline' }}>
                    Register
                  </Link>{' '}
                  | Already have an account?{' '}
                  <Link to="/login" style={{ textDecoration: 'underline' }}>
                    Login
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {msg?.length > 0 && <Message />}
      </Container>
    </div>
  );
};

export default ResetPasswordScreen;
