import React, { useEffect, useMemo, useState } from 'react';
import RecommendedMasseuse from './RecommendedMasseuse';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';
import recommendMasseuses from '../utils/recommendMasseuses';

const RecommendedMasseuses = ({ sessions, masseuses }) => {
  // Memoizing the function call
  const recommendedMasseuses = useMemo(() => {
    return recommendMasseuses(sessions, masseuses);
  }, [sessions, masseuses]); // Dependencies array

  return (
    <Carousel
      variant="dark"
      interval={3000}
      controls={false}
      fade={true}
      style={{
        marginBottom: '-20px',
      }}
    >
      {recommendedMasseuses?.map((masseuse) => (
        <Carousel.Item
          key={masseuse.id}
          style={{
            padding: '0px 0px 50px 0px' /* top | right | bottom | left */,
          }}
        >
          <RecommendedMasseuse masseuse={masseuse} />
        </Carousel.Item>
      ))}
      {
        // If there are no recommended masseuses, display a message
        recommendedMasseuses.length === 0 && (
          <Carousel.Item
            style={{
              padding: '0px 0px 50px 0px' /* top | right | bottom | left */,
            }}
          >
            <h1>No recommended masseuses available</h1>
          </Carousel.Item>
        )
      }
    </Carousel>
  );
};

export default RecommendedMasseuses;
