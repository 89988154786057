import { parseISO, addYears, addMonths, setDate } from 'date-fns';

/**
 * Calculates the expiry date by adding one year and one month to the start date,
 * then setting the day of the month to 1.
 * @param {string|Date} startInput - The start date as an ISO string or Date object.
 * @returns {Date} The calculated expiry date.
 */
export default function calculateExpiryDate(startInput) {
  // Parse the start date if it's a string, otherwise use it directly if it's a Date
  const startDate =
    typeof startInput === 'string' ? parseISO(startInput) : startInput;

  // Add one year to the start date
  const oneYearAdded = addYears(startDate, 1);

  // Add one month to the date which is one year from the start date
  const oneMonthAdded = addMonths(oneYearAdded, 1);

  // Set the day of the month to 1
  const expiryDate = setDate(oneMonthAdded, 1);

  return expiryDate;
}
