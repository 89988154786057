import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  IconButton,
  Dialog,
  Skeleton,
  Stack,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { API_URI } from '../constants/appConstants';
import useMasseuseAvailability from '../hooks/useMasseuseAvailability';
import MasseuseFeedItem from './MasseuseFeedItem';

const FeedMasseuse = ({ masseuseId }) => {
  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const masseusesAvailability = useMasseuseAvailability();

  const config = {
    headers: {
      Authorization: userInfo.token,
    },
  };

  const {
    isLoading: isFeedsLoading,
    error: feedsError,
    data: feeds,
    fetchNextPage,
  } = useInfiniteQuery(
    ['feedsMasseuse', masseuseId],
    async ({ pageParam = 1 }) => {
      const response = await axios.get(
        `${API_URI}/feed/?masseuse=${masseuseId}&page=${pageParam}&page_size=5`,
        config
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.next ?? false,
    }
  );

  // console.log(feeds?.pages.slice(-1)[0].results);

  const handleAvatarDialogOpen = (image) => {
    setDialogImage(image);
    setAvatarDialogOpen(true);
  };

  const handleAvatarDialogClose = (value) => {
    setAvatarDialogOpen(false);
    setDialogImage(null);
  };

  const loader = (
    <Grid item xs={12} sm={12} md={12}>
      <Card variant="outlined" style={{ width: '100%' }}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circular"
              width={60}
              height={60}
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={20}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={20} width="40%" />}
        />
        <CardContent>
          <Stack spacing={1}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'100%'}
              height={30}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={'100%'}
              height={100}
            />
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <div
      style={{
        marginTop: '1rem',
      }}
    >
      <Dialog onClose={handleAvatarDialogClose} open={avatarDialogOpen}>
        <IconButton
          aria-label="close"
          onClick={handleAvatarDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <Close />
        </IconButton>

        <img src={dialogImage} alt="" />
      </Dialog>

      <Grid container>
        {!isFeedsLoading ? (
          <InfiniteScroll
            dataLength={feeds?.pages?.slice(-1)[0].last}
            next={fetchNextPage}
            // hasMore={sessions?.current === sessions?.total ? false : true}
            hasMore={
              feeds?.pages.slice(-1)[0].total ===
              feeds?.pages.slice(-1)[0].current
                ? false
                : true
            }
            loader={loader}
            endMessage={
              <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                You have reached the end of the feed
              </p>
            }
            // style={{ padding: '20px', width: '100%' }}
            style={{ width: '100%' }}
          >
            {feeds?.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.results.map((feed, i) => (
                  <MasseuseFeedItem
                    key={i}
                    feed={feed}
                    masseusesAvailability={masseusesAvailability}
                    handleAvatarDialogOpen={handleAvatarDialogOpen}
                    setDialogImage={setDialogImage}
                  />
                ))}
              </React.Fragment>
            ))}
          </InfiniteScroll>
        ) : (
          loader
        )}
      </Grid>
    </div>
  );
};

export default FeedMasseuse;
