import lodash from 'lodash';

export default function recommendMasseuses(sessions, masseuses) {
  let data = lodash.orderBy(masseuses, ['survey_average'], ['desc']);
  let masseusesToRemove = lodash.flatMap(sessions, 'masseuses');

  // Remove specified items
  data = lodash.reject(data, (profile) =>
    lodash.some(masseusesToRemove, { id: profile.id })
  );

  // Detect tie starting point before the 10th position
  let tieStartIndex = 9;
  while (
    tieStartIndex > 0 &&
    data[tieStartIndex].survey_average ===
      data[tieStartIndex - 1].survey_average
  ) {
    tieStartIndex -= 1;
  }

  // Determine how many from the tie group we need to include to make exactly 10
  const requiredSelections = 10 - tieStartIndex;
  const tieGroup = data
    .slice(tieStartIndex, data.length)
    .filter(
      (profile) => profile.survey_average === data[tieStartIndex].survey_average
    );
  const selectedFromTieGroup = lodash.sampleSize(tieGroup, requiredSelections);

  // Final top 10 including the randomly selected ties
  const top10 = data.slice(0, tieStartIndex).concat(selectedFromTieGroup);

  // Randomly pick 3 masseuses from the final top 10
  const selectedMasseuses = lodash.sampleSize(top10, 3);

  return selectedMasseuses;
}
