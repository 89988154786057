import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import {
  FEEDBACK_CREATE_FAIL,
  FEEDBACK_CREATE_REQUEST,
  FEEDBACK_CREATE_SUCCESS,
  FEEDBACK_DELETE_FAIL,
  FEEDBACK_DELETE_REQUEST,
  FEEDBACK_DELETE_SUCCESS,
  FEEDBACK_LIST_FAIL,
  FEEDBACK_LIST_REMOVE_ITEM,
  FEEDBACK_LIST_REQUEST,
  FEEDBACK_LIST_SUCCESS,
  FEEDBACK_UPDATE_FAIL,
  FEEDBACK_UPDATE_REQUEST,
  FEEDBACK_UPDATE_SUCCESS,
} from '../constants/feedbackConstants';
import formatError from '../utils/errors';
import { setAlert } from './alertActions';
import * as Sentry from '@sentry/react';

export const getFeedbackList =
  (page = '1', page_size = '10') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FEEDBACK_LIST_REQUEST,
      });

      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      let { data } = await axios.get(
        `${API_URI}/feedbacks/?page=${page}&page_size=${page_size}`,
        config
      );

      dispatch({
        type: FEEDBACK_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setAlert(error.response.data.detail, 'error'));

      dispatch({
        type: FEEDBACK_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data,
      });
    }
  };

export const createFeedback =
  (content, handleClose, setContent) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FEEDBACK_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
        feedbackList: { feedbacks },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.post(
        `${API_URI}/feedbacks/`,
        {
          content,
        },
        config
      );

      let newFeedbackResults = [data, ...feedbacks.results];

      feedbacks.results = newFeedbackResults;

      dispatch({
        type: FEEDBACK_CREATE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: FEEDBACK_LIST_SUCCESS,
        payload: feedbacks,
      });

      handleClose();
      setContent('');
      dispatch(setAlert('Feedback added', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: FEEDBACK_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateFeedback =
  (id, content, setEdit) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FEEDBACK_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'Application/json',
          Authorization: userInfo.token,
        },
      };

      const { data } = await axios.put(
        `${API_URI}/feedbacks/${id}/`,
        {
          content,
        },
        config
      );

      dispatch({
        type: FEEDBACK_UPDATE_SUCCESS,
        payload: data,
      });

      setEdit(false);

      dispatch(setAlert('Feedback updated', 'success'));
    } catch (error) {
      Sentry.captureException(error);
      const errorText = formatError(error.response.data);
      dispatch(setAlert(errorText, 'error'));

      dispatch({
        type: FEEDBACK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteFeedback = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FEEDBACK_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
      feedbackList: { feedbacks },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: userInfo.token,
      },
    };

    const { data } = await axios.delete(`${API_URI}/feedbacks/${id}/`, config);

    dispatch({
      type: FEEDBACK_LIST_REMOVE_ITEM,
      payload: id,
    });

    dispatch({
      type: FEEDBACK_DELETE_SUCCESS,
      payload: data,
    });

    dispatch(setAlert('Feedback deleted', 'success'));
  } catch (error) {
    Sentry.captureException(error);
    const errorText = formatError(error.response.data);
    dispatch(setAlert(errorText, 'error'));

    dispatch({
      type: FEEDBACK_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
