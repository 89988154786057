import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { setAlert } from '../actions/alertActions';
import { rateMasseuse } from '../actions/masseuseActions';
import Message from '../components/Message';
import StarRating from '../components/StarRating';
import useSurveyQuestions from '../hooks/useSurveyQuestions';
import logo_dynasty from '../img/logo_dynasty.png';

const EmailSurveyScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  let { id: sessionId } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const masseuse = queryParams.get('masseuse');
  const masseuse_id = queryParams.get('masseuse_id');
  const token = queryParams.get('token');

  const [answers, setAnswers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const masseuseRating = useSelector((state) => state.masseuseRating);
  const { success: successSurveySubmit, loading: loadingSurveySubmit } =
    masseuseRating;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!token) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    let timer = 1000;
    if (successSurveySubmit) {
      setTimeout(() => {
        navigate('/sessions/');
      }, timer);
    }
  }, [successSurveySubmit]);

  function handleAnswerChange(questionId, value) {
    // Check if the newStateValue already exists in the current state array
    const existingIndex = answers.findIndex(
      (obj) => obj.question === questionId
    );

    if (existingIndex !== -1) {
      // If the value already exists, update it
      const newArray = [...answers];
      newArray[existingIndex] = { question: questionId, value: value };
      setAnswers(newArray);
      // return newArray;
    } else {
      // If the value does not exist, add it to the end of the array
      setAnswers([...answers, { question: questionId, value: value }]);
      // return [...currentStateArray, newStateValue];
    }
  }

  const {
    surveyQuestions,
    isLoading: isSurveyQuestionsLoading,
    error: surveyQuestionsError,
  } = useSurveyQuestions(`Bearer ${token}`);

  const handleSubmit = () => {
    if (answers.length < 1) {
      dispatch(
        setAlert('Please rate all the masseuses before submitting', 'error')
      );
      return;
    }
    const missingAnswers = answers.filter(
      (answer) => answer.value === 0 || answer.value === null
    );
    if (missingAnswers.length > 0) {
      dispatch(
        setAlert('Please answer all questions before submitting', 'error')
      );
      return;
    } else {
      // dispatch(submitSurvey(answers, sessionId, masseuse_id));
      dispatch(
        rateMasseuse({
          session_id: sessionId,
          payload: {
            answers,
            masseuse: masseuse_id,
          },
        })
      );
    }
  };

  if (isSurveyQuestionsLoading) {
    return (
      <Container>
        <Typography variant="h1" align="center" mt={6}>
          <CircularProgress size={'3rem'} thickness={5} />
        </Typography>
      </Container>
    );
  }

  if (surveyQuestionsError) {
    return (
      <Container>
        <p>Error: {surveyQuestionsError.message}</p>
      </Container>
    );
  }

  const YesNoButtons = [
    <Button key="1">Yes</Button>,
    <Button key="2">No</Button>,
  ];

  function getValueByQuestion(question) {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].question === question) {
        return answers[i].value;
      }
    }
    return null; // Return null if the question is not found in the array
  }

  return (
    <Container
      sx={{
        backgroundColor: 'var(--primary-color)',
        height: '100%',
        paddingTop: '20px',
        paddingBottom: '200px',
      }}
    >
      <Card
        variant="outlined"
        style={{
          backgroundColor: `rgba(245, 243, 239, 0.9)`,
          padding: '1.5rem',
          border: 'none',
          marginBottom: '40px',
        }}
      >
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={logo_dynasty}
            alt=""
            style={{ width: '250px', marginBottom: '20px' }}
          />
          <Typography variant="body1">
            We hope you enjoyed your recent session at{' '}
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Club Dynasty
            </span>
            . Your feedback is incredibly important to us, and we’d love to hear
            about your experience with{' '}
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {masseuse}
            </span>{' '}
            and our spa. Please take a moment to complete this short survey, it
            helps us improve and ensures we continue to provide the best service
            possible. You are the reason we do what we do!
          </Typography>
        </CardContent>
      </Card>
      <Card
        variant="outlined"
        style={{
          backgroundColor: `rgba(245, 243, 239, 0.9)`,
          padding: '1.5rem',
          border: 'none',
          marginBottom: '40px',
        }}
      >
        <div style={{ margin: '10px' }}>
          <h2>
            {surveyQuestions[0].statement}{' '}
            <span
              style={{
                fontSize: '2rem',
                color: 'var(--primary-color)',
              }}
            >
              {masseuse.toUpperCase()}
            </span>
          </h2>
        </div>{' '}
        <StarRating
          handleAnswerChange={handleAnswerChange}
          questionId={surveyQuestions[0].id}
          masseuseId={masseuse_id}
        />
      </Card>

      <Accordion
        variant="outlined"
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          // className="glow"
        >
          Click here to share more about your experience 😊
        </AccordionSummary>
        <AccordionDetails>
          {surveyQuestions.map(
            (question) =>
              question.id !== 1 && (
                <Card
                  key={question.id}
                  variant="outlined"
                  style={{
                    backgroundColor: `rgba(245, 243, 239, 0.4)`,
                    padding: '1.5rem',
                    border: 'none',
                    marginBottom: '40px',
                  }}
                >
                  <div style={{ margin: '10px' }}>
                    <h2>{question.statement}</h2>
                  </div>{' '}
                  {/* Survey changes are done. Each question now has a 'kind' field that denotes the type of question. There are 5 types of questions allowed - rating 1-10, 3 choices 1-3, 5 choices: 1-5, Yes/No: 1-2, text: text. The wording for choices can be anything that the UI needs - only the value is stored (integer).  */}
                  {question.kind === 1 ? (
                    <StarRating
                      handleAnswerChange={handleAnswerChange}
                      questionId={question.id}
                    />
                  ) : question.kind === 2 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        '& > *': {
                          m: 1,
                        },
                      }}
                    >
                      <ButtonGroup size="large">
                        <Button
                          key="1"
                          onClick={() => handleAnswerChange(question.id, 1)}
                          variant={
                            getValueByQuestion(question.id) === 1
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          Yes
                        </Button>
                        <Button
                          key="2"
                          onClick={() => handleAnswerChange(question.id, 2)}
                          variant={
                            getValueByQuestion(question.id) === 2
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Box>
                  ) : question.kind === 3 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        '& > *': {
                          m: 1,
                        },
                      }}
                    >
                      <ButtonGroup size="large">
                        <Button
                          key="1"
                          onClick={() => handleAnswerChange(question.id, 1)}
                          variant={
                            getValueByQuestion(question.id) === 1
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          1-5 KM
                        </Button>
                        <Button
                          key="2"
                          onClick={() => handleAnswerChange(question.id, 2)}
                          variant={
                            getValueByQuestion(question.id) === 2
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          6-20 KM
                        </Button>
                        <Button
                          key="3"
                          onClick={() => handleAnswerChange(question.id, 3)}
                          variant={
                            getValueByQuestion(question.id) === 3
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          Over 20 KM
                        </Button>
                      </ButtonGroup>
                    </Box>
                  ) : question.kind === 4 ? (
                    <p>Kind 4</p>
                  ) : question.kind === 5 ? (
                    <p>Kind 5</p>
                  ) : null}
                </Card>
              )
          )}
        </AccordionDetails>
      </Accordion>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        {errorMessage && <h1>{errorMessage}</h1>}
        <Link to={'/sessions'}>
          <Button variant="outlined" size="large">
            Cancel
          </Button>
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          size="large"
          variant="contained"
          disableElevation
          style={{
            marginBottom: '20px',
          }}
          disabled={loadingSurveySubmit}
          onClick={handleSubmit}
          color="white"
        >
          {loadingSurveySubmit ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default EmailSurveyScreen;
