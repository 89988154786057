import { Avatar, Chip, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/ScheduleScreen.module.css';

const ScheduleMasseuseData = ({ masseuse, schedule }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/masseuse/${schedule.masseuse_id}?source=schedule`);
  };

  const handleDelete = () => {
    navigate(`/masseuse/${schedule.masseuse_id}?source=schedule`);
  };

  return (
    <tr>
      <td
        style={{
          paddingBottom: '1em',
        }}
      >
        <Tooltip
          title={
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                <img
                  src={
                    !schedule.shiatsu || schedule.name === 'Crystal'
                      ? schedule.image
                      : 'https://clubdynasty.ca/wp-content/uploads/2023/01/Female_Silouhette-scaled.jpg'
                  }
                  alt={schedule.name}
                  width={'280px'}
                />
              </li>
              <li style={{ marginBottom: '-5px' }}>
                <Typography
                  variant="h3"
                  component="div"
                  className="masseuse-name"
                  style={{
                    fontWeight: '800',
                    color: '#FFF',
                  }}
                >
                  {schedule.name.toUpperCase()}
                </Typography>

                {!schedule.shiatsu || schedule.name === 'Crystal' ? (
                  <>
                    <Typography
                      variant="h3"
                      component="div"
                      className="masseuse-name"
                      style={{
                        fontWeight: '800',
                      }}
                    >
                      {(!schedule.shiatsu || schedule.name === 'Crystal') &&
                        schedule.age}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: 'center',
                        fontSize: '1rem',
                      }}
                    >
                      {schedule.background.toUpperCase()} |{' '}
                      {schedule.measurements.toUpperCase()}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="p"
                    sx={{
                      textAlign: 'center',
                      color: '#FFF',
                      fontSize: '1rem',
                    }}
                  >
                    We do not offer pictures of our Shiatsu masseuses. They are
                    all fit, friendly, and between 30-45 years old.
                  </Typography>
                )}
              </li>
            </ul>
          }
        >
          <Chip
            style={{
              borderColor: schedule.shiatsu && schedule.color,
            }}
            variant={schedule.shiatsu ? 'outlined' : 'contained'}
            avatar={
              <Avatar
                alt={schedule.name}
                src={
                  !schedule.shiatsu || schedule.name === 'Crystal'
                    ? schedule.image
                    : 'https://clubdynasty.ca/wp-content/uploads/2024/07/Female_Silouhette-square.jpg'
                }
              />
            }
            label={
              <>
                <span
                  className={styles.scheduleName}
                  style={{
                    color: schedule.color,
                    fontWeight: 'bold',
                  }}
                >
                  {schedule.name}
                </span>{' '}
                {schedule.start_time}-{schedule.end_time}
              </>
            }
            onClick={handleClick}
            deleteIcon={
              <span>
                {schedule.is_new && (
                  <img
                    src="https://clubdynasty.ca/wp-content/uploads/2022/11/new_flashing_icon.gif"
                    alt="New"
                    width="20"
                    height="20"
                  ></img>
                )}
                {schedule.foot_massage && (
                  <img
                    src="https://clubdynasty.ca/wp-content/uploads/2022/11/foot_massage.gif"
                    alt="New"
                    width="20"
                    height="20"
                  ></img>
                )}
                {schedule.allow_lingam && (
                  <Tooltip title="Available for lingam massages">
                    <img
                      src="https://clubdynasty.ca/wp-content/uploads/2024/07/lingam_icon.gif"
                      width="30"
                      alt="Foot Massage"
                    ></img>
                  </Tooltip>
                )}
              </span>
            }
            onDelete={handleDelete}
          />
        </Tooltip>
      </td>
    </tr>
  );
};

export default ScheduleMasseuseData;
