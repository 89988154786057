import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import {
  POPUP_DETAILS_FAIL,
  POPUP_DETAILS_REQUEST,
  POPUP_DETAILS_SUCCESS,
} from '../constants/popupConstants';
import { setAlert } from './alertActions';
import * as Sentry from '@sentry/react';

export const getPopupDetails = (setModalOpen) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POPUP_DETAILS_REQUEST,
    });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: userInfo.token,
      },
    };

    let { data } = await axios.get(`${API_URI}/popups/`, config);

    dispatch({
      type: POPUP_DETAILS_SUCCESS,
      payload: data,
    });

    setModalOpen(true);
  } catch (error) {
    Sentry.captureException(error);
    console.log(error?.response);

    dispatch({
      type: POPUP_DETAILS_FAIL,
      payload: error?.response,
    });
  }
};
