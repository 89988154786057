import React from 'react';
import useMasseuseDetails from '../hooks/useMasseuseDetails';
import {
  Chip,
  Avatar,
  Tooltip,
  Typography,
  Button,
  Skeleton,
  TableRow,
  TableCell,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { format } from 'date-fns';
import CancelIcon from '@mui/icons-material/Cancel';

const BlockedMasseusesTable = ({ block, token, handleUnblockPopupOpen }) => {
  const { masseuseDetails, isLoading, error } = useMasseuseDetails(
    block.masseuse,
    token
  );

  const handleDelete = (masseuseDetails, block_id) => {
    handleUnblockPopupOpen(masseuseDetails, block_id);
  };

  return isLoading ? (
    <TableRow>
      <TableCell component="th" scope="row" colSpan={3}>
        <LinearProgress />
      </TableCell>
    </TableRow>
  ) : error ? (
    <TableRow>
      <TableCell component="th" scope="row" colSpan={3}>
        <p>Masseuse is no longer with Club Dynasty</p>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        <Tooltip
          leaveDelay={300}
          title={
            <ul
              style={{
                listStyleType: 'none',
                padding: 0,
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <li style={{ marginBottom: '10px' }}>
                <img
                  src={masseuseDetails?.image}
                  alt={masseuseDetails?.name}
                  width={'280px'}
                />
              </li>
              <li>
                <Typography
                  variant="h3"
                  component="div"
                  className="masseuse-name"
                  style={{
                    fontWeight: '800',
                  }}
                >
                  {masseuseDetails?.age}
                </Typography>
                <Typography
                  sx={{ typography: { xs: 'h5' }, textAlign: 'center' }}
                >
                  {masseuseDetails?.background.toUpperCase()}
                </Typography>
              </li>
              <li style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleDelete(masseuseDetails, block.id)}
                >
                  Unhide
                </Button>
              </li>
            </ul>
          }
        >
          <div>
            <Avatar alt={masseuseDetails?.name} src={masseuseDetails?.image} />
            {masseuseDetails?.name}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        {format(new Date(block.created), "MMMM d 'at' h:mm a")}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => handleDelete(masseuseDetails, block.id)}>
          <CancelIcon color={'primary'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default BlockedMasseusesTable;
