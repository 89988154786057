import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { USER_LOGIN_SUCCESS } from '../constants/userConstants';
import setAuthToken from '../utils/setAuthToken';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

const MorphScreen = () => {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = searchParams.get('token');

      if (token) {
        const url = 'https://mydynasty.ca/cd/api/v1/morph/';

        try {
          const response = await axios.post(
            url,
            { token: token },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          const data = response.data;

          const config = {
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${data.token}`,
            },
          };

          const userData = await axios.get(`${API_URI}/members/me/`, config);

          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: { user: userData.data, token: `Bearer ${data.token}` },
          });

          localStorage.setItem(
            'userInfo',
            JSON.stringify({
              user: userData.data,
              token: `Bearer ${data.token}`,
            })
          );

          localStorage.setItem('popUpTime', new Date());

          setAuthToken(`Bearer ${data.token}`);

          navigate('/');
        } catch (error) {
          console.error('Error:', error);
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };

    fetchData();
  }, [searchParams]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        background:
          "url('https://i.gifer.com/77uM.gif') center center / cover no-repeat",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1 className="text-white">MORPHING...</h1>
    </div>
  );
};

export default MorphScreen;
