import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  Switch,
  ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserPreferences,
  updateUserPreferences,
} from '../actions/userActions';

const NotificationPreferences = () => {
  const dispatch = useDispatch();

  const userPreference = useSelector((state) => state.userPreference);
  const { preference, loading: loadingPreference } = userPreference;

  const [glowClass, setGlowClass] = useState('');

  const [promotions_alert_sms, set_promotions_alert_sms] = useState(false);
  const [availability_alert_sms, set_availability_alert_sms] = useState(false);
  const [news_alert_sms, set_news_alert_sms] = useState(false);
  const [reminders_alert_sms, set_reminders_alert_sms] = useState(false);
  const [feed_alert_sms, set_feed_alert_sms] = useState(false);

  const [promotions_alert_email, set_promotions_alert_email] = useState(false);
  const [availability_alert_email, set_availability_alert_email] =
    useState(false);
  const [news_alert_email, set_news_alert_email] = useState(false);
  const [reminders_alert_email, set_reminders_alert_email] = useState(false);
  const [feed_alert_email, set_feed_alert_email] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const notification = queryParams.get('notification');
    if (notification === 'true') {
      setGlowClass('locker-and-time-card-glow');
    }

    // set time out to remove the glow class after 2 seconds
    setTimeout(() => {
      setGlowClass('');
    }, 3000);

    // run memory leak prvention cleanup
    return () => clearTimeout();
  }, []);

  useEffect(() => {
    if (!preference) {
      dispatch(getUserPreferences());
    } else {
      set_promotions_alert_sms(preference.promotions_alert_sms ?? false);
      set_availability_alert_sms(preference.availability_alert_sms ?? false);
      set_news_alert_sms(preference.news_alert_sms ?? false);
      set_reminders_alert_sms(preference.reminders_alert_sms ?? false);
      set_feed_alert_sms(preference.feed_alert_sms ?? false);
      set_promotions_alert_email(preference.promotions_alert_email ?? false);
      set_availability_alert_email(
        preference.availability_alert_email ?? false
      );
      set_news_alert_email(preference.news_alert_email ?? false);
      set_reminders_alert_email(preference.reminders_alert_email ?? false);
      set_feed_alert_email(preference.feed_alert_email ?? false);
    }
  }, [preference]);

  const updateAlertPreference = (preferenceType, e) => {
    // Determine if the preference is for SMS or Email
    const isSms = preferenceType.endsWith('sms');

    // Based on the preferenceType, update the corresponding state
    if (preferenceType === 'promotions_alert_sms')
      set_promotions_alert_sms(e.target.checked);
    else if (preferenceType === 'availability_alert_sms')
      set_availability_alert_sms(e.target.checked);
    else if (preferenceType === 'news_alert_sms')
      set_news_alert_sms(e.target.checked);
    else if (preferenceType === 'reminders_alert_sms')
      set_reminders_alert_sms(e.target.checked);
    else if (preferenceType === 'feed_alert_sms')
      set_feed_alert_sms(e.target.checked);
    else if (preferenceType === 'promotions_alert_email')
      set_promotions_alert_email(e.target.checked);
    else if (preferenceType === 'availability_alert_email')
      set_availability_alert_email(e.target.checked);
    else if (preferenceType === 'news_alert_email')
      set_news_alert_email(e.target.checked);
    else if (preferenceType === 'reminders_alert_email')
      set_reminders_alert_email(e.target.checked);
    else if (preferenceType === 'feed_alert_email')
      set_feed_alert_email(e.target.checked);

    // Dispatch the updateUserPreferences action with the updated preference
    dispatch(
      updateUserPreferences({
        promotions_alert_sms:
          isSms && preferenceType === 'promotions_alert_sms'
            ? e.target.checked
            : promotions_alert_sms,
        availability_alert_sms:
          isSms && preferenceType === 'availability_alert_sms'
            ? e.target.checked
            : availability_alert_sms,
        news_alert_sms:
          isSms && preferenceType === 'news_alert_sms'
            ? e.target.checked
            : news_alert_sms,
        reminders_alert_sms:
          isSms && preferenceType === 'reminders_alert_sms'
            ? e.target.checked
            : reminders_alert_sms,
        feed_alert_sms:
          isSms && preferenceType === 'feed_alert_sms'
            ? e.target.checked
            : feed_alert_sms,
        promotions_alert_email:
          !isSms && preferenceType === 'promotions_alert_email'
            ? e.target.checked
            : promotions_alert_email,
        availability_alert_email:
          !isSms && preferenceType === 'availability_alert_email'
            ? e.target.checked
            : availability_alert_email,
        news_alert_email:
          !isSms && preferenceType === 'news_alert_email'
            ? e.target.checked
            : news_alert_email,
        reminders_alert_email:
          !isSms && preferenceType === 'reminders_alert_email'
            ? e.target.checked
            : reminders_alert_email,
        feed_alert_email:
          !isSms && preferenceType === 'feed_alert_email'
            ? e.target.checked
            : feed_alert_email,
      })
    );
  };

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontFamily: 'poppins',
          fontWeight: 500,
          color: '#1c1c1c',
        }}
      >
        Notification Preferences
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Card
            variant="outlined"
            style={{
              border: '1px solid #c2872b66',
              borderRadius: '15px',
              padding: '20px',
              backgroundColor: '#f5f5f5',
            }}
            className={`profile-card-shadow ${glowClass}`}
          >
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: '#c2872b',
                fontFamily: 'barlow',
                fontWeight: 500,
                fontSize: '1.25rem',
              }}
            >
              SMS Alerts
            </Typography>
            <Divider color="#c2872b" />
            <List
              sx={{
                width: '100%',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <ListItem
                secondaryAction={
                  <Switch
                    checked={promotions_alert_sms}
                    onChange={(e) =>
                      updateAlertPreference('promotions_alert_sms', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(194,135,43,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Spa Promotions'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <Switch
                    checked={availability_alert_sms}
                    onChange={(e) =>
                      updateAlertPreference('availability_alert_sms', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(0,0,0,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Masseuse Availability'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <Switch
                    checked={news_alert_sms}
                    onChange={(e) => updateAlertPreference('news_alert_sms', e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(194,135,43,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Spa News'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <Switch
                    checked={reminders_alert_sms}
                    onChange={(e) =>
                      updateAlertPreference('reminders_alert_sms', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(0,0,0,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Appointment Reminders'} />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Switch
                    checked={feed_alert_sms}
                    onChange={(e) => updateAlertPreference('feed_alert_sms', e)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(194,135,43,.05)',
                  borderRadius: '6px',
                }}
              >
                <ListItemText primary={'Feed Alert'} />
              </ListItem>
            </List>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card
            variant="outlined"
            style={{
              border: '1px solid #c2872b66',
              borderRadius: '15px',
              padding: '20px',
            }}
            className={`profile-card-shadow ${glowClass}`}
          >
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: '#c2872b',
                fontFamily: 'barlow',
                fontWeight: 500,
                fontSize: '1.25rem',
              }}
            >
              Email Alerts
            </Typography>
            <Divider color="#c2872b" />
            <List
              sx={{
                width: '100%',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <ListItem
                secondaryAction={
                  <Switch
                    checked={promotions_alert_email}
                    onChange={(e) =>
                      updateAlertPreference('promotions_alert_email', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(194,135,43,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Spa Promotions'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <Switch
                    checked={availability_alert_email}
                    onChange={(e) =>
                      updateAlertPreference('availability_alert_email', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(0,0,0,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Masseuse Availability'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <Switch
                    checked={news_alert_email}
                    onChange={(e) =>
                      updateAlertPreference('news_alert_email', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(194,135,43,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Spa News'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <Switch
                    checked={reminders_alert_email}
                    onChange={(e) =>
                      updateAlertPreference('reminders_alert_email', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(0,0,0,.05)',
                  borderRadius: '6px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Appointment Reminders'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <Switch
                    checked={feed_alert_email}
                    onChange={(e) =>
                      updateAlertPreference('feed_alert_email', e)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(194,135,43,.05)',
                  borderRadius: '6px',
                }}
              >
                <ListItemText primary={'Feed Alert'} />
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationPreferences;
