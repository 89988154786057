import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

function useMasseuseAvailability() {
  const fetchAvailableMasseuses = async () => {
    const endpoint = `${API_URI}/masseuses/available/`;

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch available masseuses');
    }
  };

  const queryKey = ['availableMasseuses'];

  const { data, error, isLoading } = useQuery(
    queryKey,
    fetchAvailableMasseuses,
    {
      //   cache time and stale time of 5 minutes
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    availableMasseuses: data,
    isLoading,
    error,
  };
}

export default useMasseuseAvailability;
