import React from 'react';
import convertUrl from '../utils/convertUrl';
import { isMobile } from 'react-device-detect';

const FeedImage = ({
  imageRefs,
  file,
  id,
  handleAvatarDialogOpen,
  fromCarousel,
}) => {
  return (
    <img
      ref={(el) => imageRefs.current.push(el)}
      src={convertUrl(file)}
      alt={id}
      loading="lazy"
      style={{
        cursor: 'pointer',
        height: fromCarousel ? (isMobile ? '300px' : '500px') : 'auto',
        width: 'auto',
        maxWidth: '100%',
      }}
      onClick={(e) => handleAvatarDialogOpen(file)}
    />
  );
};

export default FeedImage;
