import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import {
  NOTIFICATION_LIST_FAIL,
  NOTIFICATION_LIST_MAKE_READ_REQUEST,
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_READ_FAIL,
  NOTIFICATION_READ_REQUEST,
  NOTIFICATION_READ_SUCCESS,
} from '../constants/notificationConstants';
import { setAlert } from './alertActions';
import * as Sentry from '@sentry/react';

export const getNotificationList =
  (page = '1', pageSize = '10') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: NOTIFICATION_LIST_REQUEST,
      });

      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      let { data } = await axios.get(
        `${API_URI}/notifications/?page=${page}&page_size=${pageSize}`,
        config
      );

      let unreadData = await axios.get(
        `${API_URI}/notifications/?page=1&page_size=1000`,
        config
      );

      let unreadCount = 0;
      unreadData.data.results.forEach((notification) => {
        if (!notification.read) {
          unreadCount++;
        }
      });

      data.unreadCount = unreadCount;

      dispatch({
        type: NOTIFICATION_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setAlert(error.response.data.detail, 'error'));

      dispatch({
        type: NOTIFICATION_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data,
      });
    }
  };

export const readNotification =
  (id, setNotificationRead) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NOTIFICATION_READ_REQUEST,
        payload: id,
      });

      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      let { data } = await axios.post(
        `${API_URI}/notifications/${id}/`,
        config
      );

      setNotificationRead((prev) => prev + 1);

      dispatch({
        type: NOTIFICATION_READ_SUCCESS,
        payload: data,
      });
      dispatch({
        type: NOTIFICATION_LIST_MAKE_READ_REQUEST,
        payload: id,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setAlert(error.response.data.detail, 'error'));

      dispatch({
        type: NOTIFICATION_READ_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.response.data,
      });
    }
  };
