import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingIndicatorBackdrop = () => {
  return (
    <Backdrop
      sx={{
        color: '#181818',
        zIndex: 999,
      }}
      open={true}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default LoadingIndicatorBackdrop;
