export const FEED_BLOCK_LIST_REQUEST = 'FEED_BLOCK_LIST_REQUEST';
export const FEED_BLOCK_LIST_SUCCESS = 'FEED_BLOCK_LIST_SUCCESS';
export const FEED_BLOCK_LIST_FAIL = 'FEED_BLOCK_LIST_FAIL';
export const FEED_BLOCK_LIST_REMOVE_ITEM = 'FEED_BLOCK_LIST_REMOVE_ITEM';

export const FEED_BLOCK_CREATE_REQUEST = 'FEED_BLOCK_CREATE_REQUEST';
export const FEED_BLOCK_CREATE_SUCCESS = 'FEED_BLOCK_CREATE_SUCCESS';
export const FEED_BLOCK_CREATE_FAIL = 'FEED_BLOCK_CREATE_FAIL';
export const FEED_BLOCK_CREATE_RESET = 'FEED_BLOCK_CREATE_RESET';

export const FEED_BLOCK_UPDATE_REQUEST = 'FEED_BLOCK_UPDATE_REQUEST';
export const FEED_BLOCK_UPDATE_SUCCESS = 'FEED_BLOCK_UPDATE_SUCCESS';
export const FEED_BLOCK_UPDATE_FAIL = 'FEED_BLOCK_UPDATE_FAIL';
export const FEED_BLOCK_UPDATE_RESET = 'FEED_BLOCK_UPDATE_RESET';

export const FEED_BLOCK_DELETE_REQUEST = 'FEED_BLOCK_DELETE_REQUEST';
export const FEED_BLOCK_DELETE_SUCCESS = 'FEED_BLOCK_DELETE_SUCCESS';
export const FEED_BLOCK_DELETE_FAIL = 'FEED_BLOCK_DELETE_FAIL';
export const FEED_BLOCK_DELETE_RESET = 'FEED_BLOCK_DELETE_RESET';

export const FEED_BLOCK_DETAILS_REQUEST = 'FEED_BLOCK_DETAILS_REQUEST';
export const FEED_BLOCK_DETAILS_SUCCESS = 'FEED_BLOCK_DETAILS_SUCCESS';
export const FEED_BLOCK_DETAILS_FAIL = 'FEED_BLOCK_DETAILS_FAIL';
export const FEED_BLOCK_DETAILS_RESET = 'FEED_BLOCK_DETAILS_RESET';

export const FEED_FAVORITE_LIST_REQUEST = 'FEED_FAVORITE_LIST_REQUEST';
export const FEED_FAVORITE_LIST_SUCCESS = 'FEED_FAVORITE_LIST_SUCCESS';
export const FEED_FAVORITE_LIST_FAIL = 'FEED_FAVORITE_LIST_FAIL';
export const FEED_FAVORITE_LIST_REMOVE_ITEM = 'FEED_FAVORITE_LIST_REMOVE_ITEM';

export const FEED_FAVORITE_CREATE_REQUEST = 'FEED_FAVORITE_CREATE_REQUEST';
export const FEED_FAVORITE_CREATE_SUCCESS = 'FEED_FAVORITE_CREATE_SUCCESS';
export const FEED_FAVORITE_CREATE_FAIL = 'FEED_FAVORITE_CREATE_FAIL';
export const FEED_FAVORITE_CREATE_RESET = 'FEED_FAVORITE_CREATE_RESET';

export const FEED_FAVORITE_DELETE_REQUEST = 'FEED_FAVORITE_DELETE_REQUEST';
export const FEED_FAVORITE_DELETE_SUCCESS = 'FEED_FAVORITE_DELETE_SUCCESS';
export const FEED_FAVORITE_DELETE_FAIL = 'FEED_FAVORITE_DELETE_FAIL';
export const FEED_FAVORITE_DELETE_RESET = 'FEED_FAVORITE_DELETE_RESET';

export const FEED_FAVORITE_DETAILS_REQUEST = 'FEED_FAVORITE_DETAILS_REQUEST';
export const FEED_FAVORITE_DETAILS_SUCCESS = 'FEED_FAVORITE_DETAILS_SUCCESS';
export const FEED_FAVORITE_DETAILS_FAIL = 'FEED_FAVORITE_DETAILS_FAIL';
export const FEED_FAVORITE_DETAILS_RESET = 'FEED_FAVORITE_DETAILS_RESET';
