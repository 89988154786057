import axios from 'axios';
import { useEffect } from 'react';
import { API_URI } from '../constants/appConstants';
import { useQuery } from 'react-query';

const useMasseuseSchedule = (token) => {
  const cancelSource = axios.CancelToken.source();

  useEffect(() => {
    // This function will be called when the component unmounts
    return () => {
      // Cancel the Axios request when the component unmounts
      cancelSource.cancel('Request canceled due to component unmount');
    };
  }, []);

  const fetchMasseuseSchedule = async () => {
    let { data } = await axios.get(`${API_URI}/schedule/`, {
      cancelToken: cancelSource.token,
      headers: {
        Authorization: token,
      },
    });

    function groupScheduleByDays(schedule) {
      // Group schedules by start date
      const groupedByDate = schedule.reduce((acc, item) => {
        const startDate = item.start.split('T')[0]; // Extract the date part
        if (!acc[startDate]) {
          acc[startDate] = [];
        }
        acc[startDate].push(item);
        return acc;
      }, {});

      // Sort each group by color, placing #000 first
      for (const date in groupedByDate) {
        groupedByDate[date].sort((a, b) => {
          if (a.color === '#000' && b.color !== '#000') {
            return -1;
          } else if (a.color !== '#000' && b.color === '#000') {
            return 1;
          }
          return 0;
        });
      }

      // Convert the grouped object back to an array of days, each day containing its schedules
      const result = Object.keys(groupedByDate).map((date) => ({
        date: date,
        schedules: groupedByDate[date],
      }));

      return result;
    }

    // Example usage with your `schedule` array
    const groupedSchedule = groupScheduleByDays(data);

    return groupedSchedule;
  };

  const {
    data: groupedSchedule,
    isLoading: groupedScheduleLoading,
    error,
  } = useQuery('masseuseSchedule', fetchMasseuseSchedule, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return { groupedSchedule, groupedScheduleLoading, error };
};

export default useMasseuseSchedule;
