import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import { addDays } from 'date-fns';

function useAppointments(token) {
  const fetchAppointments = async () => {
    const today = new Date().toISOString().split('T')[0];
    const tomorrow = addDays(new Date(), 1).toISOString().split('T')[0];

    const endpointToday = `${API_URI}/appointments/?page=1&page_size=10000&date=${today}`;
    const endpointTomorrow = `${API_URI}/appointments/?page=1&page_size=10000&date=${tomorrow}`;
    const headers = {
      Authorization: token,
    };

    try {
      const responseToday = await axios.get(endpointToday, { headers });
      const responseTomorrow = await axios.get(endpointTomorrow, { headers });

      let res = [
        ...responseToday.data.results,
        ...responseTomorrow.data.results,
      ];

      if (res.length === 0)
        return [
          {
            start_time: '2024-05-26T17:00:00-04:00',

            session_type: 54,

            working_date: '2024-05-26',

            masseuses: [{ name: 'Alice', id: 8059 }],

            time: '05:00 PM',

            end_time: '2024-05-26T17:45:00-04:00',

            id: 105215,

            editable: true,
          },

          {
            start_time: '2024-05-26T18:00:00-04:00',

            session_type: 54,

            working_date: '2024-05-26',

            masseuses: [{ name: 'Cara', id: 974 }],

            time: '06:00 PM',

            end_time: '2024-05-26T18:45:00-04:00',

            id: 105232,

            editable: true,
          },
        ];

      return res;
    } catch (error) {
      throw new Error('Failed to appointment details');
    }
  };

  const queryKey = ['appointments'];

  const { data, error, isLoading } = useQuery(queryKey, fetchAppointments, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    appointments: data,
    isLoading,
    error,
  };
}

export default useAppointments;
