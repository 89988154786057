export const AVAILABLE_MASSEUSES_LIST_REQUEST =
  'AVAILABLE_MASSEUSES_LIST_REQUEST';
export const AVAILABLE_MASSEUSES_LIST_SUCCESS =
  'AVAILABLE_MASSEUSES_LIST_SUCCESS';
export const AVAILABLE_MASSEUSES_LIST_FAIL = 'AVAILABLE_MASSEUSES_LIST_FAIL';
export const AVAILABLE_MASSEUSES_LIST_RESET = 'AVAILABLE_MASSEUSES_LIST_RESET';

export const ALL_MASSEUSES_LIST_REQUEST = 'ALL_MASSEUSES_LIST_REQUEST';
export const ALL_MASSEUSES_LIST_SUCCESS = 'ALL_MASSEUSES_LIST_SUCCESS';
export const ALL_MASSEUSES_LIST_FAIL = 'ALL_MASSEUSES_LIST_FAIL';
export const ALL_MASSEUSES_LIST_RESET = 'ALL_MASSEUSES_LIST_RESET';

export const ALL_MASSEUSES_PUBLIC_LIST_REQUEST =
  'ALL_MASSEUSES_PUBLIC_LIST_REQUEST';
export const ALL_MASSEUSES_PUBLIC_LIST_SUCCESS =
  'ALL_MASSEUSES_PUBLIC_LIST_SUCCESS';
export const ALL_MASSEUSES_PUBLIC_LIST_FAIL = 'ALL_MASSEUSES_PUBLIC_LIST_FAIL';
export const ALL_MASSEUSES_PUBLIC_LIST_RESET =
  'ALL_MASSEUSES_PUBLIC_LIST_RESET';

export const MASSEUSE_RATE_REQUEST = 'MASSEUSE_RATE_REQUEST';
export const MASSEUSE_RATE_SUCCESS = 'MASSEUSE_RATE_SUCCESS';
export const MASSEUSE_RATE_FAIL = 'MASSEUSE_RATE_FAIL';
export const MASSEUSE_RATE_RESET = 'MASSEUSE_RATE_RESET';

export const MASSEUSE_DETAILS_REQUEST = 'MASSEUSE_DETAILS_REQUEST';
export const MASSEUSE_DETAILS_SUCCESS = 'MASSEUSE_DETAILS_SUCCESS';
export const MASSEUSE_DETAILS_FAIL = 'MASSEUSE_DETAILS_FAIL';
export const MASSEUSE_DETAILS_RESET = 'MASSEUSE_DETAILS_RESET';
