import { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import convertUrl from '../utils/convertUrl';
import GalleryLoaderSkeleton from './GalleryLoaderSkeleton';

const Gallery = ({ images, thumbnails }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  let gallery = [];

  if (images && images.length > 0) {
    images.forEach((image, index) => {
      gallery.push({
        original: convertUrl(image),
        thumbnail: convertUrl(thumbnails[index]),
        loading: 'lazy',
      });
    });
  }
  return (
    <>
      {!imageLoaded && <GalleryLoaderSkeleton />}
      <ImageGallery
        items={gallery}
        thumbnailPosition="right"
        onImageLoad={() => setImageLoaded(true)}
        lazyLoad={true}
        showNav={imageLoaded ? true : false}
        showFullscreenButton={imageLoaded ? true : false}
        showPlayButton={imageLoaded ? true : false}
        onErrorImageURL="https://clubdynasty.ca/wp-content/uploads/2020/09/Untitled.jpg"
      />
    </>
  );
};

export default Gallery;
