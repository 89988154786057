import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserDetails,
  updateUserProfilePreferences,
} from '../actions/userActions';
import {
  LIGHTING_CHOICES,
  LOCKER_CHOICES,
  MUSIC_CHOICES,
  PRESSURE_CHOICES,
  ROBE_CHOICES,
  SHOE_CHOICES,
  SHORTS_CHOICES,
} from '../constants/appConstants';

import Message from '../components/Message';

const SpaPreferences = () => {
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const [locker, setLocker] = useState('');
  const [shoe_size, setShoe_size] = useState('');
  const [robe_size, setRobe_size] = useState('');
  const [shorts_size, setShorts_size] = useState('');

  const [music, setMusic] = useState('m');
  const [lighting, setLighting] = useState('l');
  const [pressure, setPressure] = useState('p');

  useEffect(() => {
    if (!user) {
      dispatch(getUserDetails());
    } else {
      setLocker(user.locker ?? '');
      setShoe_size(user.shoe_size ?? '');
      setRobe_size(user.robe_size ?? '');
      setShorts_size(user.shorts_size ?? '');
      setMusic(user.music ?? 'm');
      setLighting(user.lighting ?? 'l');
      setPressure(user.pressure ?? 'p');
    }
  }, [user, dispatch]);

  const onSpaSubmit = () => {
    dispatch(
      updateUserProfilePreferences({
        name: user.name,
        locker: locker,
        shoe_size: shoe_size,
        shorts_size: shorts_size,
        robe_size: robe_size,
        music: music === 'm' ? user.music : music,
        lighting: lighting === 'l' ? user.lighting : lighting,
        pressure: pressure === 'p' ? user.pressure : pressure,
      })
    );
  };

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontFamily: 'poppins',
          fontWeight: 500,
          color: '#1c1c1c',
        }}
      >
        Spa Preferences
      </Typography>
      <Card
        variant="outlined"
        style={{
          border: '1px solid #c2872b66',
          borderRadius: '15px',
          padding: '20px',
        }}
        className="profile-card-shadow"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                color: '#c2872b',
                fontFamily: 'barlow',
                fontWeight: 500,
                fontSize: '1.25rem',
              }}
            >
              Choose Spa Preferences
            </Typography>
            <Divider color="#c2872b" />
          </Grid>
          <Grid item xs={12} mt={1}>
            <List>
              <ListItem
                secondaryAction={
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(0,0,0,.05)',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  >
                    <Select
                      labelId="select-locker-type"
                      value={locker.toString()}
                      label="Locker"
                      onChange={(e) => setLocker(e.target.value)}
                      fullWidth
                      disableUnderline
                      variant="standard"
                      size="small"
                    >
                      <MenuItem size="small" value={''}>
                        Locker Choices
                      </MenuItem>
                      {Object.keys(LOCKER_CHOICES).map((l) => (
                        <MenuItem size="small" value={l} key={l}>
                          {LOCKER_CHOICES[l]}
                        </MenuItem>
                      ))}
                    </Select>{' '}
                  </FormControl>
                }
                sx={{
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Locker'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(194,135,43,.05)',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  >
                    <Select
                      labelId="select-shoe-size"
                      value={shoe_size.toString()}
                      label="Shoe Size"
                      onChange={(e) => setShoe_size(e.target.value)}
                      fullWidth
                      disableUnderline
                      variant="standard"
                      size="small"
                    >
                      {Object.keys(SHOE_CHOICES).map((l) => (
                        <MenuItem size="small" value={l} key={l}>
                          {SHOE_CHOICES[l]}
                        </MenuItem>
                      ))}
                    </Select>{' '}
                  </FormControl>
                }
                sx={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Shoe Size'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(0,0,0,.05)',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  >
                    <Select
                      labelId="select-shorts-size"
                      value={shorts_size.toString()}
                      label="Shorts Size"
                      onChange={(e) => setShorts_size(e.target.value)}
                      fullWidth
                      disableUnderline
                      variant="standard"
                      size="small"
                    >
                      <MenuItem size="small" value={''}>
                        Shorts Choices
                      </MenuItem>
                      {Object.keys(SHORTS_CHOICES).map((l) => (
                        <MenuItem size="small" value={l} key={l}>
                          {SHORTS_CHOICES[l]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                sx={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Shorts Choices'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(194,135,43,.05)',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  >
                    <Select
                      labelId="select-robe-size"
                      value={robe_size.toString()}
                      label="Robe Size"
                      onChange={(e) => setRobe_size(e.target.value)}
                      fullWidth
                      disableUnderline
                      variant="standard"
                      size="small"
                    >
                      <MenuItem size="small" value={''}>
                        Robe Choices
                      </MenuItem>
                      {Object.keys(ROBE_CHOICES).map((l) => (
                        <MenuItem size="small" value={l} key={l}>
                          {ROBE_CHOICES[l]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                sx={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Robe Choices'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(194,135,43,.05)',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  >
                    <Select
                      labelId="select-music-volume"
                      value={music}
                      label="Music Volume"
                      onChange={(e) => setMusic(e.target.value)}
                      fullWidth
                      disableUnderline
                      variant="standard"
                      size="small"
                    >
                      <MenuItem size="small" value={'m'} disabled>
                        Select Music Volume
                      </MenuItem>
                      {MUSIC_CHOICES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                sx={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Music Volume'} />
              </ListItem>{' '}
              <Divider />
              <ListItem
                secondaryAction={
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(194,135,43,.05)',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  >
                    <Select
                      labelId="select-lighting"
                      value={lighting}
                      label="Lighting"
                      onChange={(e) => setLighting(e.target.value)}
                      fullWidth
                      disableUnderline
                      variant="standard"
                      size="small"
                    >
                      <MenuItem size="small" value={'l'} disabled>
                        Select Lighting
                      </MenuItem>
                      {LIGHTING_CHOICES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                sx={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Lighting'} />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(194,135,43,.05)',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  >
                    <Select
                      labelId="select-masseuse-pressure"
                      value={pressure}
                      label="Masseuse Pressure"
                      onChange={(e) => setPressure(e.target.value)}
                      fullWidth
                      disableUnderline
                      variant="standard"
                      size="small"
                    >
                      <MenuItem size="small" value={'p'} disabled>
                        Select Masseuse Pressure
                      </MenuItem>
                      {PRESSURE_CHOICES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                sx={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                <ListItemText primary={'Masseuse Pressure'} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <CardActions>
          <Box sx={{ marginLeft: 'auto' }}>
            <Button
              onClick={(e) => onSpaSubmit()}
              variant="contained"
              disableElevation
            >
              Update
            </Button>
          </Box>
        </CardActions>
      </Card>

      {msg?.length > 0 && <Message />}
    </>
  );
};

export default SpaPreferences;
