import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { API_URI } from '../constants/appConstants';

function useTransactions(token) {
  const fetchTransactions = async ({ pageParam = 1 }) => {
    const endpoint = `${API_URI}/transactions/?page=${pageParam}&page_size=10`;

    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch transaction list');
    }
  };

  return useInfiniteQuery(['transactions'], fetchTransactions, {
    // Function to get the next page number
    getNextPageParam: (lastPage) => lastPage.next ?? false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });
}

export default useTransactions;
