export function convertPackageData(packages, sessionTypes) {
  if (
    !packages.total ||
    !packages.used ||
    Object.keys(packages.total).length === 0
  ) {
    return [];
  }
  const packageIds = Object.keys(packages.total);
  const formattedPackages = packageIds.map((id) => ({
    id,
    name: sessionTypes.find((st) => st.id === parseInt(id))?.name,
    total: packages.total[id],
    used: packages.used[id],
  }));

  return formattedPackages;
}
