export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = getRotatedSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate and rotate canvas context to prepare for drawing
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw the image
  ctx.drawImage(image, 0, 0);

  // create a second canvas to extract the cropped area
  const canvas2 = document.createElement('canvas');
  const ctx2 = canvas2.getContext('2d');
  canvas2.width = pixelCrop.width;
  canvas2.height = pixelCrop.height;

  // use the first canvas to extract the crop
  ctx2.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // return base64 string
  //   return new Promise((resolve) => {
  //     canvas2.toBlob((blob) => {
  //       resolve(blob);
  //     }, 'image/jpeg');
  //   });

  return new Promise((resolve) => {
    canvas2.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      resolve({ blob, url }); // Returning an object containing both Blob and URL
    }, 'image/jpeg');
  });
}

function getRotatedSize(width, height, rotation) {
  const radians = Math.abs(rotation) * (Math.PI / 180);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  return {
    width: Math.abs(width * cos) + Math.abs(height * sin),
    height: Math.abs(width * sin) + Math.abs(height * cos),
  };
}
