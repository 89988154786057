const errorsToArrayString = (errors) => {
  let isErrorArray = false;
  let errorText = null;
  // console.log(typeof errors);
  // console.log(errors);

  if (errors && Array.isArray(errors)) {
    isErrorArray = true;
    errorText = errors.join(', ');
  }

  if (typeof errors === 'object' && !Array.isArray(errors) && errors !== null) {
    // executeSomeCode();
  }

  return { isErrorArray, errorText };
};

export default errorsToArrayString;
