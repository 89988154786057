import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

function useMasseuseDetails(masseuseId, token) {
  const fetchMasseuseDetails = async () => {
    const endpoint = `${API_URI}/masseuses/${masseuseId}/`;
    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Masseuse is no longer with Club Dynasty');
    }
  };

  const queryKey = ['masseuseDetails', masseuseId];

  const { data, error, isLoading } = useQuery(queryKey, fetchMasseuseDetails, {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: (failureCount, error) => {
      return failureCount < 1;
    },
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    masseuseDetails: data,
    isLoading,
    error,
  };
}

export default useMasseuseDetails;
