import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import logo_dynasty from '../img/logo_dynasty.png';
import PinDropIcon from '@mui/icons-material/PinDrop';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer
      className="footer"
      style={{ backgroundColor: '#454545', marginTop: '50px' }}
    >
      <Container style={{ padding: '30px' }}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={3}>
            <img src={logo_dynasty} alt="" style={{ width: '250px' }} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="h2"
              sx={{
                fontSize: '1.8rem',
              }}
            >
              Quick Links
            </Typography>
            <List color="white">
              <ListItem>
                <Link to="/masseuses" className="footer-links">
                  <ListItemText primary="Masseuses" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/feed" className="footer-links">
                  <ListItemText primary="Live Feed" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/sessions" className="footer-links">
                  <ListItemText primary="My Sessions" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/" className="footer-links">
                  <ListItemText primary="My Rewards Points" />
                </Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="h2"
              sx={{
                fontSize: '1.8rem',
              }}
            >
              Quick Links
            </Typography>
            <List color="white">
              <ListItem>
                <Link to="/profile" className="footer-links">
                  <ListItemText primary="My Profile" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/notifications" className="footer-links">
                  <ListItemText primary="Notifications" />
                </Link>
              </ListItem>{' '}
              <ListItem>
                <Link to="/feedbacks" className="footer-links">
                  <ListItemText primary="Give Us Feedback" />
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/schedule" className="footer-links">
                  <ListItemText primary="Schedule" />
                </Link>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Typography
              variant="h2"
              sx={{
                fontSize: '1.8rem',
              }}
            >
              Contact Info
            </Typography>
            <List color="white">
              <ListItem>
                <a
                  href="https://g.page/ClubDynasty?share"
                  className="footer-links"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <span style={{ display: 'flex' }}>
                    <PinDropIcon />
                    &nbsp;
                    <span style={{ fontWeight: '700' }}>Address:</span>&nbsp;
                    7850 Woodbine Avenue, Markham, Ontario{' '}
                  </span>
                </a>
              </ListItem>

              <ListItem>
                <a href="tel:905-477-0099" className="footer-links">
                  <span style={{ display: 'flex' }}>
                    <HeadphonesIcon />
                    &nbsp;
                    <span style={{ fontWeight: '700' }}>Phone:</span>&nbsp;
                    905-477-0099
                  </span>
                </a>
              </ListItem>
              <ListItem>
                <a
                  href="mailto:Reception@ClubDynasty.ca"
                  className="footer-links"
                >
                  <span style={{ display: 'flex' }}>
                    <MailOutlineIcon />
                    &nbsp;
                    <span style={{ fontWeight: '700' }}>Email:</span>&nbsp;
                    Reception@ClubDynasty.ca
                  </span>
                </a>
              </ListItem>
              <ListItem>
                <a href="https://clubdynasty.ca/" className="footer-links">
                  <span style={{ display: 'flex' }}>
                    <LanguageIcon />
                    &nbsp;
                    <span style={{ fontWeight: '700' }}>Website:</span>&nbsp;
                    www.ClubDynasty.ca
                  </span>
                </a>
              </ListItem>
              <ListItem>
                <a
                  href="https://www.instagram.com/ClubDynastyTO/"
                  className="footer-links"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      justifyItems: 'center',
                    }}
                  >
                    <img
                      src="https://clubdynasty.ca/wp-content/uploads/2019/11/ig_logo.svg"
                      alt=""
                      width={50}
                    />{' '}
                    &nbsp;
                    <span style={{ fontWeight: '700' }}>@ClubDynastyTO</span>
                  </div>
                </a>
              </ListItem>
              <ListItem>
                <a
                  href="https://www.tiktok.com/@clubdynasty"
                  className="footer-links"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      justifyItems: 'center',
                    }}
                  >
                    <img
                      src="https://clubdynasty.ca/wp-content/uploads/2019/11/TikTok_Icon_Design.svg"
                      alt=""
                      width={50}
                    />
                    &nbsp;
                    <span style={{ fontWeight: '700' }}>@ClubDynasty</span>
                  </div>
                </a>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
