export function hasActivePackages(packages) {
  const { total, used } = packages;

  // Check if both total and used are empty
  if (Object.keys(total).length === 0 && Object.keys(used).length === 0) {
    return false;
  }

  for (let key in total) {
    if (total.hasOwnProperty(key)) {
      // If used does not have the key or used value is less than total
      if (!used.hasOwnProperty(key) || used[key] < total[key]) {
        return true;
      }
      // If used value is greater than total, which is not supposed to happen, we'll assume data error and return false
      if (used[key] > total[key]) {
        return false;
      }
    }
  }

  // If none of the used values are less than total, return false
  return false;
}
