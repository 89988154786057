import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAppointmentBooking } from '../actions/appointmentActions';
import { format, parse } from 'date-fns';
import useAppointmentSlots from '../hooks/useAppointmentSlots';
import useAppointmentSessionTypes from '../hooks/useAppointmentSessionTypes';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AppointmentBookingAccordion = ({ masseuseId, token }) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState('false');
  const [appointmentStartTime, setAppointmentStartTime] = useState('');
  const [appointmentSessionType, setAppointmentSessionType] = useState('');

  const today = new Date().toISOString().split('T')[0];

  const {
    appointmentSlots,
    isLoading: isAppointmentSlotsLoading,
    error: appointmentSlotsError,
  } = useAppointmentSlots(token, masseuseId, today);

  const {
    appointmentSessionTypes,
    isLoading: isAppointmentSessionTypesLoading,
    error: appointmentSessionTypesError,
  } = useAppointmentSessionTypes(token, masseuseId);

  const handleExpandClick = () => {
    setExpanded(!expanded.toString());
  };

  const appointmentBookingCreate = () => {
    dispatch(
      createAppointmentBooking(
        appointmentSessionType,
        today,
        masseuseId,
        format(parse(appointmentStartTime, 'HH:mm:ss', new Date()), 'hh:mm a')
      )
    );
  };

  return (
    <>
      <CardActions disableSpacing>
        <Button
          color="primary"
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label=" Book Appointment"
        >
          Book Appointment
        </Button>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label=" Book Appointment"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse
        in={expanded === 'true' ? true : false}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <TableContainer component={Paper} variant="outlined">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Choose start time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      borderTop: 0,
                      borderBottom: 0,
                      borderLeft: 0,
                    },
                  }}
                >
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    sx={{
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    <Grid container spacing={1}>
                      {appointmentSlots?.map((row, index) => (
                        <Grid item xs={3} key={index}>
                          <Button
                            disableElevation
                            variant={
                              appointmentStartTime === row.start_time
                                ? 'contained'
                                : 'outlined'
                            }
                            onClick={() => {
                              setAppointmentStartTime(row.start_time);
                            }}
                            key={index}
                            sx={{ width: '100%' }}
                          >
                            {format(
                              parse(row.start_time, 'HH:mm:ss', new Date()),
                              'hh:mm a'
                            )}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="center">
                    Choose the massage you want
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      borderTop: 0,
                      borderBottom: 0,
                      borderLeft: 0,
                    },
                  }}
                >
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    sx={{
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    <Grid container spacing={2}>
                      {appointmentSessionTypes?.map((row, index) => (
                        <Grid item xs={4} key={index}>
                          <Button
                            disableElevation
                            variant={
                              appointmentSessionType === row.id
                                ? 'contained'
                                : 'outlined'
                            }
                            onClick={() => {
                              setAppointmentSessionType(row.id);
                            }}
                            key={index}
                            sx={{ width: '100%' }}
                            disabled={row.id !== 54}
                          >
                            {row.name}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    colSpan={2}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      disabled={
                        appointmentStartTime.length === 0 ||
                        appointmentSessionType.length === 0
                          ? true
                          : false
                      }
                      onClick={() => appointmentBookingCreate()}
                    >
                      Book
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
    </>
  );
};

export default AppointmentBookingAccordion;
