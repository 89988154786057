import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  IconButton,
  Input,
  InputLabel,
  TextField,
  Typography,
  Slider,
  Stack,
  DialogContent,
  DialogActions,
  DialogTitle,
  Badge,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Message from '../components/Message';
import MuiPhoneNumber from 'material-ui-phone-number';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import UserAvatar from '../components/UserAvatar';
import useImageSrc from '../hooks/useImageSrc';
import getErrorsForProperty from '../utils/getErrorsForProperty';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropUtils'; // Assume you have this utility (we'll define it later)

import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import { isMobile } from 'react-device-detect';

const ProfileUpdateScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails } = userDetails;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { loading: loadingUpdate, success, error } = userUpdateProfile;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState(null);

  const [fileInputState, setFileInputState] = useState('');
  const [previewSource, setPreviewSource] = useState('');
  const [avatar, setAvatar] = useState('');

  const [fresh_login, setFresh_login] = useState(null);

  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [avatarCropDialogOpen, setAvatarCropDialogOpen] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const imageSrc = useImageSrc(userInfo?.token, user?.avatar);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Reset crop, zoom, and rotation
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setRotation(0);
      previewFile(file);
      setAvatar(file);
      setFileInputState(e.target.value);
      setAvatarCropDialogOpen(true);
    } else {
      setAvatar('');
      setPreviewSource('');
      setFileInputState('');
    }
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  useEffect(() => {
    if (searchParams.get('fresh_login') === 'true') {
      setFresh_login(true);
    } else setFresh_login(false);

    if (!user) {
      dispatch(getUserDetails());
    } else {
      user.name && setName(user.name);
      user.email && setEmail(user.email);
      user.phone && setPhone(user.phone.split('+1')[1]);
      user.dob && setDob(new Date(user.dob));
    }
  }, [user, dispatch, searchParams]);

  useEffect(() => {
    if (success && fresh_login) {
      setTimeout(() => {
        navigate('/profile/change-password?fresh_login=true');
      }, 3000);
    } else if (success && !fresh_login) {
      setTimeout(() => {
        navigate('/profile');
      }, 3000);
    }
  }, [success, fresh_login, navigate]);

  const handleDobChange = (newValue) => {
    setDob(newValue);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (avatar) {
      dispatch(
        updateUserProfile(
          {
            name,
            email,
            phone,
            dob,
            avatar,
            fresh_login,
          },
          fileInputState.split('\\').pop()
        )
      );
    } else {
      dispatch(
        updateUserProfile({
          name,
          email,
          phone,
          dob,
          fresh_login,
        })
      );
    }
  };

  const handleAvatarDialogOpen = () => {
    setAvatarDialogOpen(true);
  };

  const handleAvatarDialogClose = (value) => {
    setAvatarDialogOpen(false);
  };

  const onCropComplete = async () => {
    const croppedImage = await getCroppedImg(
      previewSource,
      croppedAreaPixels,
      rotation
    );
    // Here you would set the cropped image as the new previewSource
    setPreviewSource(croppedImage.url);
    setAvatar(croppedImage.blob);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setAvatarCropDialogOpen(false);
  };

  return (
    <Container>
      {/* <Button onClick={() => setAvatarCropDialogOpen(true)}>Crop</Button> */}
      <Dialog open={avatarCropDialogOpen} fullWidth={true} maxWidth={'xl'}>
        <DialogTitle>Crop and Rotate your avatar</DialogTitle>
        {previewSource && (
          <DialogContent>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: 400,
                background: '#333',
              }}
            >
              <Cropper
                image={previewSource}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1} // 1/1 aspect ratio
                onCropChange={setCrop}
                onCropComplete={(croppedArea, croppedAreaPixels) =>
                  setCroppedAreaPixels(croppedAreaPixels)
                }
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>

            <div
              style={{
                padding: isMobile ? 0 : 16,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'stretch',
                gap: isMobile ? 0 : 30,
                width: '100%',
              }}
            >
              <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1, width: '100%' }}
                alignItems="center"
              >
                <IconButton
                  onClick={() =>
                    setZoom((prev) => (prev === 1 ? 1 : prev - 0.1))
                  }
                >
                  <ZoomOutIcon />
                </IconButton>

                <Slider
                  value={zoom}
                  min={1}
                  max={5}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
                <IconButton
                  onClick={() =>
                    setZoom((prev) => (prev === 5 ? 5 : prev + 0.1))
                  }
                >
                  <ZoomInIcon />
                </IconButton>
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                sx={{ mb: 1, width: '100%' }}
                alignItems="center"
              >
                <IconButton
                  onClick={() =>
                    setRotation((prev) => (prev === 0 ? 0 : prev - 10))
                  }
                >
                  <RotateLeftIcon />
                </IconButton>

                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={5}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                />

                <IconButton
                  onClick={() =>
                    setRotation((prev) => (prev === 360 ? 0 : prev + 10))
                  }
                >
                  <RotateRightIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    setRotation((prev) =>
                      prev === 360 ? 0 : prev % 90 === 0 ? prev + 90 : 0
                    )
                  }
                >
                  <Rotate90DegreesCwIcon />
                </IconButton>
              </Stack>
            </div>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setAvatarCropDialogOpen(false)}>Cancel</Button>
          <Button onClick={onCropComplete}>Crop</Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleAvatarDialogClose} open={avatarDialogOpen}>
        <IconButton
          aria-label="close"
          onClick={handleAvatarDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={previewSource ? previewSource : imageSrc} alt="" />
      </Dialog>
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={3} mt={8}>
          <Grid item xs={12}>
            <Typography variant="h2">
              {fresh_login ? 'Confirm' : 'Update'} profile info
            </Typography>
            {fresh_login && (
              <div
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  background: '#E1E1E1',
                  margin: '15px 0px 10px 0px',
                }}
              >
                <Typography variant="p">
                  New member onboarding <strong>Step 1 of 2</strong>
                </Typography>{' '}
                <br />
                <Typography variant="p">
                  You can update your profile information here if you want
                </Typography>
              </div>
            )}
          </Grid>
          {loadingDetails ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: '-10px' }}>
                  <Skeleton variant="circular" width={100} height={100} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: '-7px' }}>
                  <Skeleton variant="text" width={'100%'} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: '-7px' }}>
                  <Skeleton variant="text" width={'100%'} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: '-7px' }}>
                  <Skeleton variant="text" width={'100%'} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: '-7px' }}>
                  <Skeleton variant="text" width={'100%'} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: '-7px' }}>
                  <Skeleton variant="text" width={'100%'} />
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                {loadingDetails ? (
                  <Typography variant="h3" style={{ marginBottom: '-20px' }}>
                    <Skeleton variant="text" width={'100%'} />
                  </Typography>
                ) : previewSource ? (
                  <Avatar
                    alt="chosen"
                    src={previewSource}
                    sx={{ width: 200, height: 200, cursor: 'pointer' }}
                    onClick={handleAvatarDialogOpen}
                  />
                ) : (
                  <div
                    onClick={handleAvatarDialogOpen}
                    style={{ cursor: 'pointer' }}
                  >
                    <UserAvatar
                      token={userInfo.token}
                      imageUrl={user?.avatar}
                      alt={user?.name}
                      width={200}
                      height={200}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Change Avatar Picture</InputLabel>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Input
                    id="avatarFile"
                    type="file"
                    name="avatar"
                    value={fileInputState}
                    inputProps={{
                      accept: 'image/png, image/jpg, image/jpeg,image/webp',
                    }}
                    onChange={handleFileInputChange}
                    fullWidth
                  ></Input>
                  {previewSource && (
                    <IconButton
                      onClick={(e) => {
                        document.getElementById('avatarFile').value = null;
                        setFileInputState('');
                        setPreviewSource('');
                        setAvatar('');
                      }}
                      color="warning"
                    >
                      <ClearIcon style={{ fontSize: '16px' }} color="warning" />
                    </IconButton>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  label="Name"
                  style={{ width: '100%' }}
                  size="small"
                  error={error && error.hasOwnProperty('name')}
                  helperText={getErrorsForProperty('name', error)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  placeholder={email}
                  style={{ width: '100%' }}
                  size="small"
                  error={error && error.hasOwnProperty('email')}
                  helperText={getErrorsForProperty('email', error)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  variant="outlined"
                  size="small"
                  label="Phone"
                  style={{ width: '100%' }}
                  value={phone}
                  onChange={setPhone}
                  disableCountryCode={true}
                  disableAreaCodes={true}
                  disableDropdown={true}
                  defaultCountry="ca"
                  onlyCountries={['ca']}
                  error={error && error.hasOwnProperty('phone')}
                  helperText={getErrorsForProperty('phone', error)}
                />
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    label="Date of Birth"
                    value={dob}
                    onChange={handleDobChange}
                    disableFuture
                    error
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        sx: {
                          marginBottom: '20px',
                        },
                        error: error && error.hasOwnProperty('dob'),
                        helperText: getErrorsForProperty('dob', error),
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {!fresh_login && (
              <Link to={'/profile'}>
                <Button variant="outlined" disableElevation>
                  Back
                </Button>
              </Link>
            )}{' '}
            <Button variant="contained" disableElevation type="submit">
              {fresh_login ? 'Next' : 'Update'}
              {loadingUpdate && (
                <>
                  &nbsp;
                  <CircularProgress color="white" size={20} thickness={5} />
                </>
              )}
            </Button>
          </Grid>
        </Grid>{' '}
      </form>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default ProfileUpdateScreen;
