import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// import LoginIllustration from '../img/Login_Artwork_1.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  login,
  resetPassword,
  resetPasswordConfirm,
} from '../actions/userActions';

import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { setAlert } from '../actions/alertActions';
import getErrorsForProperty from '../utils/getErrorsForProperty';

const ResetPasswordConfirmScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userPasswordResetConfirm = useSelector(
    (state) => state.userPasswordResetConfirm
  );
  const {
    loading: loadingResetConfirm,
    success,
    error,
  } = userPasswordResetConfirm;

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [userInfo]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  }, [success]);

  useEffect(() => {
    if (!searchParams.get('token') || !searchParams.get('uid')) {
      navigate('/login');
    }
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (values.password !== values.confirmPassword) {
      dispatch(setAlert('Passwords do not match', 'warning'));
    } else {
      dispatch(
        resetPasswordConfirm(
          values.password,
          values.confirmPassword,
          searchParams.get('uid'),
          searchParams.get('token')
        )
      );
    }
  };

  return (
    <div
      style={{
        background:
          'url(https://clubdynasty.ca/wp-content/uploads/2020/07/VIP-min-scaled-e1595532639186.jpg) center center no-repeat',
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Container
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} className="grid-center">
            <Card
              sx={{ minWidth: 275, background: 'rgba(255,255,255,0.9)' }}
              variant="outlined"
            >
              <CardContent style={{ textAlign: 'center', padding: '30px' }}>
                <Typography variant="h2" gutterBottom>
                  Reset Password
                </Typography>

                <form onSubmit={(e) => onSubmit(e)}>
                  <TextField
                    required
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Password"
                    error={
                      error &&
                      (error.hasOwnProperty('new_password1') ||
                        error.hasOwnProperty('new_password2'))
                    }
                  />
                  <TextField
                    required
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type={values.showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label="Confirm Password"
                    error={error && error.hasOwnProperty('new_password2')}
                    helperText={getErrorsForProperty('new_password2', error)}
                  />

                  <Button
                    variant="contained"
                    disableElevation
                    // size="small"
                    style={{ width: '100%', marginBottom: '20px' }}
                    type="submit"
                  >
                    Reset{' '}
                    {loadingResetConfirm && (
                      <>
                        &nbsp;
                        <CircularProgress
                          color="white"
                          size={20}
                          thickness={5}
                        />
                      </>
                    )}
                  </Button>
                </form>
                <Typography variant="p">
                  Don't have an account?{' '}
                  <Link to="/register" style={{ textDecoration: 'underline' }}>
                    Register
                  </Link>{' '}
                  | Already have an account?{' '}
                  <Link to="/login" style={{ textDecoration: 'underline' }}>
                    Login
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {msg?.length > 0 && <Message />}
      </Container>
    </div>
  );
};

export default ResetPasswordConfirmScreen;
