import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

function useAppointmentSlots(token, masseuseId, date) {
  const fetchAppointmentSlots = async () => {
    const endpoint = `${API_URI}/appointments/slots/${masseuseId}/?date=${date}`;
    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch masseuse appointment slots');
    }
  };

  const queryKey = ['appointmentSlots', date, masseuseId];

  const { data, error, isLoading } = useQuery(queryKey, fetchAppointmentSlots, {
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    appointmentSlots: data,
    isLoading,
    error,
  };
}

export default useAppointmentSlots;
