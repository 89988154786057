import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Box } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserDetails,
  getUserPreferences,
  verifyUserEmail,
  verifyUserPhone,
} from '../actions/userActions';
import { Link, useNavigate } from 'react-router-dom';
import {
  USER_EMAIL_VERIFY_CONFIRM_RESET,
  USER_EMAIL_VERIFY_RESET,
  USER_PASSWORD_CHANGE_RESET,
  USER_PHONE_VERIFY_CONFIRM_RESET,
  USER_PHONE_VERIFY_RESET,
  USER_UPDATE_PROFILE_RESET,
} from '../constants/userConstants';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import CakeIcon from '@mui/icons-material/Cake';
import CloseIcon from '@mui/icons-material/Close';
import Message from '../components/Message';
import { REMOVE_ALERT } from '../constants/alertConstants';
import Meta from '../components/Meta';
import UserAvatar from '../components/UserAvatar';

import { parsePhoneNumber } from 'libphonenumber-js';
import useImageSrc from '../hooks/useImageSrc';
import PreferencesScreen from './PreferencesScreen';
import { deleteFeedBlock, getFeedBlockList } from '../actions/feedActions';
import BlockedMasseuse from '../components/BlockedMasseuse';

const ProfileScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);

  const [unblockPopupOpen, setUnblockPopupOpen] = useState(false);
  const [masseuseToUnblock, setMasseuseToUnblock] = useState(null);
  const [blockId, setBlockId] = useState(null);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userVerifyEmail = useSelector((state) => state.userVerifyEmail);
  const {
    loading: loadingVerifyEmail,
    success: successVerifyEmail,
    token: tokenVerifyEmail,
  } = userVerifyEmail;

  const userPasswordChange = useSelector((state) => state.userPasswordChange);
  const { success: successPasswordChange } = userPasswordChange;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success: successUpdateProfile } = userUpdateProfile;

  const userVerifyEmailConfirm = useSelector(
    (state) => state.userVerifyEmailConfirm
  );
  const { success: successVerifyEmailConfirm } = userVerifyEmailConfirm;

  const userVerifyPhone = useSelector((state) => state.userVerifyPhone);
  const {
    loading: loadingVerifyPhone,
    success: successVerifyPhone,
    token: tokenVerifyPhone,
  } = userVerifyPhone;

  const userVerifyPhoneConfirm = useSelector(
    (state) => state.userVerifyPhoneConfirm
  );
  const { success: successVerifyPhoneConfirm } = userVerifyPhoneConfirm;

  const userPreference = useSelector((state) => state.userPreference);
  const { preference, loading: loadingPreference } = userPreference;

  const feedBlockList = useSelector((state) => state.feedBlockList);
  const { feedBlocks, loading: loadingFeedBlocks } = feedBlockList;

  const imageSrc = useImageSrc(userInfo?.token ?? '', user?.avatar ?? '');

  useEffect(() => {
    dispatch(getUserPreferences());

    if (successPasswordChange) {
      dispatch({ type: USER_PASSWORD_CHANGE_RESET });
    }

    if (successUpdateProfile) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
    }

    if (successVerifyEmailConfirm) {
      dispatch({ type: USER_EMAIL_VERIFY_CONFIRM_RESET });
    }

    if (successVerifyPhoneConfirm) {
      dispatch({ type: USER_PHONE_VERIFY_CONFIRM_RESET });
    }

    if (msg) {
      dispatch({ type: REMOVE_ALERT });
    }

    dispatch({ type: USER_EMAIL_VERIFY_RESET });
    dispatch({ type: USER_PHONE_VERIFY_RESET });

    // if (!user) {
    dispatch(getUserDetails());
    dispatch(getFeedBlockList());
    // }
  }, []);

  useEffect(() => {
    if (successVerifyEmail) {
      navigate(`/profile/email_confirm?token=${tokenVerifyEmail}`);
    }
  }, [successVerifyEmail]);

  useEffect(() => {
    if (successVerifyPhone) {
      navigate(`/profile/phone_confirm?token=${tokenVerifyPhone}`);
    }
  }, [successVerifyPhone]);

  const onEmailVerify = () => {
    dispatch(verifyUserEmail());
  };

  const onPhoneVerify = () => {
    dispatch(verifyUserPhone());
  };

  const formatPhoneNumber = (phoneNumber) => {
    const parsedPhoneNumber = new parsePhoneNumber(phoneNumber, 'CA');
    return parsedPhoneNumber.formatNational();
  };

  const handleAvatarDialogOpen = () => {
    setAvatarDialogOpen(true);
  };

  const handleAvatarDialogClose = (value) => {
    setAvatarDialogOpen(false);
  };

  const unblockMasseuseFromFeed = (masseuse, block_id) => {
    dispatch(deleteFeedBlock(masseuse, block_id, handleUnblockPopupClose));
  };

  const handleUnblockPopupOpen = (masseuse, block_id) => {
    setUnblockPopupOpen(true);
    setMasseuseToUnblock(masseuse);
    setBlockId(block_id);
  };

  const handleUnblockPopupClose = () => {
    setUnblockPopupOpen(false);
    setMasseuseToUnblock(null);
  };

  return (
    <Container>
      <Meta
        title={'Profile'}
        description={
          'VIP member profile Club Dynasty Executive Health Spa & Exotic Massage'
        }
        keywords={
          'profile, club dynasty member profile, club dynasty members area, vip members'
        }
      />
      <Dialog
        open={unblockPopupOpen}
        onClose={handleUnblockPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Unhide ${masseuseToUnblock?.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`If you unhide ${masseuseToUnblock?.name}, you will be able to see posts from her in the feed.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnblockPopupClose}>Cancel</Button>
          <Button
            onClick={() => unblockMasseuseFromFeed(masseuseToUnblock, blockId)}
            autoFocus
          >
            Unhide
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ minWidth: '100%' }}>
            {loadingDetails ? (
              <Card variant="outlined" style={{ padding: '25px' }}>
                <Box>
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    style={{ marginBottom: '10px' }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={100}
                    style={{ marginBottom: '10px' }}
                  />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Card>
            ) : (
              user && (
                <Card variant="outlined" style={{ padding: '10px' }}>
                  <CardContent>
                    <Grid container spacing={3} mb={1}>
                      <Grid item xs={4} sm={1}>
                        <div
                          onClick={handleAvatarDialogOpen}
                          style={{ cursor: 'pointer' }}
                        >
                          <UserAvatar
                            token={userInfo.token}
                            imageUrl={user.avatar}
                            alt={user.name}
                            width={80}
                            height={80}
                            onClick={user.avatar && handleAvatarDialogOpen}
                          />
                        </div>

                        <Dialog
                          onClose={handleAvatarDialogClose}
                          open={avatarDialogOpen}
                        >
                          <IconButton
                            aria-label="close"
                            onClick={handleAvatarDialogClose}
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.primary.main,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <img src={imageSrc} alt="" />
                        </Dialog>
                      </Grid>
                      <Grid item xs={8}>
                        <Link to="/rewards">
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="primary"
                            gutterBottom
                          >
                            {user.points} reward points
                          </Typography>
                        </Link>

                        <Typography variant="h5" component="div">
                          {user.name}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          VIP Member
                        </Typography>
                      </Grid>
                    </Grid>
                    <Chip
                      icon={<CakeIcon />}
                      label={`${
                        user.dob === null
                          ? 'Date of birth not provided'
                          : user.dob
                      }`}
                      style={{ marginTop: '10px' }}
                    />{' '}
                    &nbsp;
                    <Chip
                      icon={<EmailIcon />}
                      label={`${user.email}`}
                      style={{ marginTop: '10px' }}
                    />
                    &nbsp;
                    {!user.email_verified && (
                      <span
                        style={{
                          marginTop: '10px',
                          display: 'block',
                        }}
                      >
                        <Alert
                          severity="warning"
                          action={
                            <Button
                              color="inherit"
                              size="small"
                              onClick={onEmailVerify}
                            >
                              Verify
                            </Button>
                          }
                        >
                          Email not verified
                        </Alert>
                        {loadingVerifyEmail && (
                          <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                          </Box>
                        )}
                      </span>
                    )}
                    <Chip
                      icon={<PhoneIcon />}
                      label={`${
                        user.phone === null
                          ? 'Phone not provided'
                          : formatPhoneNumber(user.phone)
                      }`}
                      style={{ marginTop: '10px' }}
                    />
                    &nbsp;
                    {user.phone !== null && !user.phone_verified && (
                      <span
                        style={{
                          marginTop: '10px',
                          display: 'block',
                        }}
                      >
                        <Alert
                          severity="warning"
                          action={
                            <Button
                              color="inherit"
                              size="small"
                              onClick={() => onPhoneVerify()}
                            >
                              Verify
                            </Button>
                          }
                        >
                          Phone not verified
                        </Alert>
                        {loadingVerifyPhone && (
                          <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                          </Box>
                        )}{' '}
                      </span>
                    )}
                  </CardContent>
                  <CardActions>
                    <Link to="/profile/update">
                      <Button size="small">Update Info</Button>
                    </Link>
                    <Link to="/profile/change-password">
                      <Button size="small">Change Password</Button>
                    </Link>
                  </CardActions>
                </Card>
              )
            )}
          </Box>
        </Grid>
        {feedBlocks?.results?.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Card variant="outlined" style={{ padding: '10px' }}>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  Hidden Masseuses
                </Typography>

                <Typography variant="p" gutterBottom display={'block'} mb={2}>
                  You will no longer see posts from these masseuses in the feed.
                </Typography>
                {loadingFeedBlocks ? (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                ) : (
                  feedBlocks.results.map((block, i) => (
                    <BlockedMasseuse
                      key={i}
                      block={block}
                      token={userInfo.token}
                      handleUnblockPopupOpen={handleUnblockPopupOpen}
                    />
                  ))
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <PreferencesScreen />

      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default ProfileScreen;
