export function shouldShowPopup(lastPopUpTime) {
  if (lastPopUpTime) {
    const currentTime = new Date();
    const lastTime = new Date(lastPopUpTime);
    const timeDiff = currentTime - lastTime;
    const sevenDays = 7 * 24 * 60 * 60 * 1000; // Time difference in milliseconds for one week

    return timeDiff >= sevenDays;
  }
  // If there is no recorded last popup time, return true to show the popup
  return true;
}
